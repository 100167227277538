import styled, { css, keyframes } from 'styled-components';
import {
  branco,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi19,
  cinzaKuppi1,
  cinzaKuppi3,
  cinzaKuppi4,
  preto,
  preto2,
  vermelhoKuppi1,
} from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

const colorFadeIn = keyframes`
  0% {
  background-color: ${cinzaKuppi4};
  transform: scale(1.01);
}
  100% {
    background-color: ${verdeKuppi2};
    transform: initial;
  }
`;

const fadeIn = keyframes`
to {
opacity: 1;
}

`;

export const MenuBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${branco};
  margin-left: ${px2vw(5)};
  border-radius: ${px2vw(20)} ${px2vw(20)} ${px2vw(20)} ${px2vw(20)};
  min-height: ${px2vw(300)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  opacity: 0;
  animation: ${fadeIn} 0.4s forwards;
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ml-auto {
    margin-left: auto;
  }
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.margL ? props.margL : px2vw(10))};
`;

export const Tab = styled.button`
  height: ${px2vw(35)};
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.48px;
  border: none;
  border-bottom: ${px2vw(5)} solid transparent;
  background-color: transparent;
  line-height: 1.5;
  font-weight: 700;

  ${(props) =>
    props.selected &&
    `
    border-bottom: ${px2vw(5)} solid ${verdeKuppi1};

  `};
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(95)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const BalanceBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${px2vw(40)};
  width: ${px2vw(260)};
  border-radius: ${px2vw(6)};
  margin-left: ${px2vw(11)};
  margin-right: ${px2vw(20)};
  background: ${cinzaKuppi1};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${cinzaKuppi3};
  font-size: ${px2vw(13)};
  ${(props) =>
    props.balance &&
    `
  background:${verdeKuppi19};
  color:${preto};
  `}
`;

export const Box = styled.div`
  display: flex;
  height: ${props => props.height || px2vw(125)};
  width: ${px2vw(490)};
  font-weight: 700;
  background: ${branco};
  color: ${cinzaKuppi3};
  position: relative;
  border-radius: ${px2vw(10)};
  padding: ${props => props.pad || px2vw(6)};

  ${(props) =>
    props.border &&
    ` &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: ${px2vw(10)};
    padding: ${px2vw(3)}; /* control the border thickness */
    background: linear-gradient(45deg,#f8474166,#e34ca652,#f2800561,#0012fa70);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    pointer-events: none;
  }`}
`;

export const Text = styled.div`

  ${(props) =>
    props.fadeIn &&
    css`
    opacity: 0;
      animation: ${fadeIn} 1s forwards;
    `}
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.margbottom && `margin-bottom: ${props.margbottom};`}
  ${(props) => props.margtop && `margin-top: ${props.margtop};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.txtOverFlow && `text-overflow: ellipsis;`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
  font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
  font-weight: normal;`}
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'initial')};
  ${(props) => props.alignCenter && `text-align: center;`}
  ${(props) => props.weight && `font-weight: ${props.weight};`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}
  ${(props) =>
    props.wrap &&
    `
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}
  ${(props) =>
    props.fitLetter &&
    `
      font-style: normal;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
  `}
  ${(props) =>
    props.roboto &&
    `
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.05em;
    `}

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  width: ${(props) => props.width || 'auto'};
`;

export const BalanceIcon = styled.img`
  width: ${(props) => (props.width ? props.width : px2vw(33))};
  height: ${(props) => (props.height ? props.height : px2vw(33))};
  margin-right: ${px2vw(10)};
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;