import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import { preto } from '../../../styles/colors';
import { Row } from '../../../styles/grid';
import { Title } from '../styles';
import { LoadingBox } from '../../../styles/common';
import { ContentPage } from '../../_layouts/styles/common';
import { SearchButton, SearchInput, WhiteBox } from '../DataClients/styles';

import {
  clearSuccess,
  deleteOrdersRequest,
  getOrdersRequest,
} from '../../../store/modules/clients/actions';

import OrdersTable from '../../../components/Tables/OrdersTable';
import DeleteOrdersModal from '../../../components/DeleteOrdersModal';

import px2vw from '../../../utils/responsiveness/px2vw';

export default function Orders() {
  const orders = useSelector((state) => state.clients.orders);
  const { ordersLoading, deleteOrdersSuccess } = useSelector((state) => state.clients);
  const [typedOrdersIds, setTypedOrdersIds] = useState('');
  const [selectAllOrders, setSelectAllOrders] = useState(false);
  const [orderId, setOrderId] = useState();
  const [ordersIds, setOrdersIds] = useState([]);
  const [ordersToDelete, setOrdersToDelete] = useState([]);
  const [deleteOrdersModalStatus, setDeleteOrdersModalStatus] = useState(false);
  const dispatch = useDispatch();

  function handleOrdersToDeleteList(id) {
    if (ordersToDelete.includes(id)) {
      setOrdersToDelete(ordersToDelete.filter((value) => value !== id));
    } else {
      setOrdersToDelete([...ordersToDelete, id]);
    }
    setSelectAllOrders(false);
  }

  function handleDeleteOrdersSubmit() {
    dispatch(deleteOrdersRequest(ordersToDelete));
  }

  function handleDeleteOrdersModal() {
    if(ordersToDelete.length === 0){
      toast.error('Por gentileza, selecione um ou mais pedidos para serem excluídos!');
    } else {
      setDeleteOrdersModalStatus(true);
    }
  }
  
  useEffect(() => {
    setOrdersIds(typedOrdersIds.split(','));
  }, [typedOrdersIds]);

  useEffect(() => {
    if(deleteOrdersSuccess){
      setDeleteOrdersModalStatus(false);
      setSelectAllOrders(false);
      setOrdersIds([]);
      setOrdersToDelete([]);
      setTypedOrdersIds('');
      dispatch(clearSuccess());
    }
  },[deleteOrdersSuccess])

  useEffect(() => {
    setDeleteOrdersModalStatus(false);
    setSelectAllOrders(false);
    setOrdersIds([]);
    setOrdersToDelete([]);
    setTypedOrdersIds('');
    dispatch(clearSuccess());
  }, [])

  const handler = {
    selectAllOrders: selectAllOrders,
    orderId: orderId,
    ordersToDelete: ordersToDelete,
    deleteOrdersModalStatus: deleteOrdersModalStatus,
    setSelectAllOrders: () => {
      if (!selectAllOrders) {
        let allOrdersList = [];
        orders.map((order) => {
          allOrdersList.push(order.order_id);
        });
        setOrdersToDelete(allOrdersList);
        setSelectAllOrders(true);
      } else {
        setOrdersToDelete([]);
        setSelectAllOrders(false);
      }
    },
    setOrderId: () => setOrderId(),
    handleOrdersToDeleteList: (id) => handleOrdersToDeleteList(id),
    handleDeleteOrdersSubmit:() => handleDeleteOrdersSubmit(),
    handleDeleteOrdersModal: () => handleDeleteOrdersModal(),
    setDeleteOrdersModalStatus: (v) => setDeleteOrdersModalStatus(v)
  };

  return (
    <ContentPage>
      <Row height="auto" alignStart justifyStart>
        <Title>Pedidos</Title>
      </Row>
      <WhiteBox justifyStart height="auto" margtop={px2vw(30)}>
        <Title boxTitle width={px2vw(300)}>
          Excluir
        </Title>
        <Row height="auto" margtop={px2vw(10)} spaceBetween>
          <SearchInput
            name="orderNumber"
            type="text"
            placeholder="Digite aqui o(s) número(s) do(s) pedido(s) que deseja excluir, separados por virgula"
            border={`2px solid ${preto}`}
            borderRadius={px2vw(5)}
            height={px2vw(30)}
            width={px2vw(800)}
            value={typedOrdersIds}
            onChange={(e) => setTypedOrdersIds(e.target.value.replace(/[^\d,]/gi,''))}
          />
          <SearchButton
            orders
            onClick={() => dispatch(getOrdersRequest(ordersIds))}
          >
            {ordersLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Pesquisar'
            )}
          </SearchButton>
        </Row>

        <Row>
          <OrdersTable data={orders} handler={handler} />
        </Row>
      </WhiteBox>
      {deleteOrdersModalStatus && <DeleteOrdersModal handler={handler} />}
    </ContentPage>
  );
}
