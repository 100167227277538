import React from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import px2vw from '../../utils/responsiveness/px2vw';
import Modal from '../Modal';
import { useSelector } from 'react-redux';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import closeButtonImg from '../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../styles/grid';
import {
  CancelButton,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';
import { preto } from '../../styles/colors';
import { LoadingBox } from '../../styles/common';

export default function EditStatusDataModal({ handler }) {
  const { storeStatusLoading } = useSelector(
    (state) => state.clients
  );

  const clientData = useSelector((state) => state.clients.clientData);

  function handleCloseModal() {
    handler.setEditStatusData(false);
    handler.setBlockDate(
      moment(clientData.store_data.status.date).format('YYYY-MM-DD')
    );
  };

  return (
    <Modal
      isVisible={handler.editStatusData}
      width={px2vw(250)}
      height="auto"
      pad={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Alterar status</ModalTitleText>
        <CloseButton onClick={handleCloseModal}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handleStoreStatusSubmit}>
        <Row margtop={px2vw(10)}>
          <Col>
            <InputTitle>Data do Bloqueio</InputTitle>
            <InputData
              name="data"
              type="date"
              placeholder="01/01/2022"
              width={px2vw(200)}
              value={handler.blockDate}
              onChange={(e) => handler.setBlockDate(e.target.value)}
            />
          </Col>
        </Row>

        <Col margtop={px2vw(20)}>
          <ConfirmButton type="submit">
            {storeStatusLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Confirmar'
            )}
          </ConfirmButton>
          <Row margtop={px2vw(10)} width="auto" height="auto">
            <CancelButton onClick={handleCloseModal}>Cancelar</CancelButton>
          </Row>
        </Col>
      </FormBox>
    </Modal>
  );
}
