import React from 'react';

import { Text } from '../../styles';
import { Row } from '../../../../styles/grid';
import { ValueBox } from './styles';

import px2vw from "../../../../utils/responsiveness/px2vw";

export default function AdsStats({ title, value }) {
  return (
    <Row 
      height="auto" 
      width="auto"
      margbottom={px2vw(5)} 
      spaceBetween
      alignCenter
    >
      <Text size={px2vw(14)} regular>{title}:</Text>
      <ValueBox>
        <Text size={px2vw(14)}>{value}</Text>
      </ValueBox>
    </Row>
  );
}
