import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import {
  InputTitle,
  SearchButton,
  SearchInput,
  WhiteBox,
} from '../DataClients/styles';
import { SelectInput, Title, FormBox } from '../styles';
import { preto } from '../../../styles/colors';
import { Col, Row } from '../../../styles/grid';
import { ContentPage } from '../../_layouts/styles/common';
import { LoadingBox } from '../../../styles/common';

import DistrictsTable from '../../../components/Tables/DistrictsTable';

import {
  addDistrictRequest,
  districtClearSuccess,
  getDistrictsRequest,
  getLocalitiesRequest,
} from '../../../store/modules/clients/actions';

import px2vw from '../../../utils/responsiveness/px2vw';
import { statesOptions } from '../../../utils/commonObjects';
import AccessValidate from '../../../utils/validations/AccessValidate';

export default function Districts() {  
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(access, 'admin_operator_edit', 'operational_edit', null, false);
  const localities = useSelector((state) => state.clients.localities);
  const districts = useSelector((state) => state.clients.districts);

  const {
    localitiesSuccess,
    districtsSuccess,
    localitiesLoading,
    addDistrictLoading,
    addDistrictSuccess,
  } = useSelector((state) => state.clients);

  const [newStateSelected, setNewStateSelected] = useState(null);
  const [newCitySelected, setNewCitySelected] = useState(null);
  const [newcitySelectedId, setNewCitySelectedId] = useState(null);
  const [newCitySelectedObj, setNewCitySelectedObj] = useState(null);
  const [localitiesSelect, setLocalitiesSelect] = useState([]);
  const [districtsSelect, setDistrictsSelect] = useState([]);
  const [newDistrictName, setNewDistrictName] = useState('');
  const [stateCity, setStateCity] = useState('');
  const [tableList, setTableList] = useState([]);

  const dispatch = useDispatch();

  function handleAddNewDistrictSubmit() {
    if (newDistrictName === '') {
      toast.error('Por gentilza, preencha o nome do bairro a ser incluído!');
    } else {
      dispatch(addDistrictRequest(newCitySelectedObj.id, newDistrictName));
    }
  }

  // Get localities data (state, city)
  useEffect(() => {
    if (newStateSelected !== null) {
      dispatch(getLocalitiesRequest(newStateSelected));
      setDistrictsSelect([]);
      setTableList([]);
    }
  }, [newStateSelected]);

  // Set localities at select input
  useEffect(() => {
    if (localitiesSuccess) {
      dispatch(districtClearSuccess());
      localitiesSelect.length = 0;
      localitiesSelect.push({
        id: '',
        title: 'Selecione a cidade',
      });
      localities.map((locality, idx) =>
        localitiesSelect.push({
          id: idx,
          title: `${locality.name}`,
        })
      );
    }
  }, [localitiesSuccess]);

  // Load districts for selected locality
  useEffect(() => {
    if (districtsSuccess) {
      dispatch(districtClearSuccess());
      districtsSelect.length = 0;
      tableList.length = 0;

      districts.map((district, idx) =>
        districtsSelect.push({
          id: idx,
          title: `${district.name}`,
        })
      );

      districts.map((district) => {
        tableList.push({
          stateCity: stateCity,
          district: `${district.name}`,
        });
      });
    }
  }, [districtsSuccess]);

  // Set city and state to one string
  useEffect(() => {
    if (newCitySelected !== null && newStateSelected !== null) {
      setStateCity(`${newStateSelected}/ ${localities[newCitySelected].name}`);
    }
  }, [newCitySelected, newStateSelected]);

  // Redo request when add new district successfully
  useEffect(() => {    
    if (localitiesSuccess && addDistrictSuccess) {
      dispatch(getDistrictsRequest(newcitySelectedId));
      dispatch(districtClearSuccess());
      return
    }

    if (addDistrictSuccess) {
      setNewDistrictName('');
      dispatch(getLocalitiesRequest(newStateSelected));  
    }
   
  }, [addDistrictSuccess, localitiesSuccess]);

  return (
    <ContentPage>
      <Row height="auto" alignStart justifyStart>
        <Title>Bairros</Title>
      </Row>
      <WhiteBox justifyStart height="auto" margtop={px2vw(30)}>
        <Row height="auto" spaceBetween>
          <Title boxTitle width={px2vw(300)}>
            Inclusão
          </Title>
        </Row>
        <FormBox onSubmit={handleAddNewDistrictSubmit}>
          <Row margtop={px2vw(20)} justifyStart spaceBetween={operationalEditAccess}>
            <Col width="auto" height="auto">
              <InputTitle>Estado</InputTitle>
              <SelectInput
                padright={px2vw(10)}
                padleft={px2vw(10)}
                height={px2vw(30)}
                width={px2vw(200)}
                border={`2px solid ${preto}`}
                name="stateSelect"
                placeholder="Selecione o Estado"
                options={statesOptions}
                value={newStateSelected}
                onChange={(t) => {
                  // Set the current state selected
                  setNewStateSelected(t.target.value);
                  // Clear the localities select
                  localitiesSelect.length = 0;
                  localitiesSelect.push({
                    id: '',
                    title: 'Selecione a cidade',
                  });
                  setNewCitySelected(null);
                  // Clear the current locality selected
                  setNewCitySelectedObj(null);
                  // Clear the districts select
                  districtsSelect.length = 0;
                  // Get all localities from the current state
                  dispatch(getLocalitiesRequest(t.target.value));
                }}
                disabled={localitiesLoading}
              />
            </Col>
            <Col width="auto" margleft={operationalEditAccess ? 0 : px2vw(49)} height="auto">
              <InputTitle>Cidade</InputTitle>
              <SelectInput
                padright={px2vw(10)}
                padleft={px2vw(10)}
                height={px2vw(30)}
                width={px2vw(200)}
                border={`2px solid ${preto}`}
                name="citySelect"
                placeholder={
                  localitiesSelect.length === 1 &&
                  localitiesSelect[0].title === 'Selecione a cidade'
                    ? 'Sem cidades disponíveis'
                    : 'Selecione a cidade'
                }
                options={localitiesSelect}
                onChange={(t) => {
                  // Set the current city selected
                  setNewCitySelected(t.target.value);
                  // Set the current locality selected
                  setNewCitySelectedObj(
                    t.target.value !== '' ? localities[t.target.value] : null
                  );
                  // Clear the districts select
                  districtsSelect.length = 0;
                  if (t.target.value !== '') {
                    // Get all districts from the current locality
                    dispatch(
                      getDistrictsRequest(localities[t.target.value].id)
                    );
                    // Set the id to redo the request when new district added
                    setNewCitySelectedId(localities[t.target.value].id);
                  }
                }}
                disabled={
                  localitiesLoading ||
                  (localitiesSelect.length === 1 &&
                    localitiesSelect[0].title === 'Selecione a cidade')
                }
              />
            </Col>
            {operationalEditAccess && (
              <>
                <Col width="auto" height="auto">
                  <InputTitle>Cadastrar novo bairro</InputTitle>
                  <SearchInput
                    name="Add District"
                    type="text"
                    placeholder="Digite o nome do Bairro"
                    border={`2px solid ${preto}`}
                    borderRadius={px2vw(5)}
                    height={px2vw(30)}
                    width={px2vw(350)}
                    value={newDistrictName}
                    onChange={(e) => {
                      setNewDistrictName(e.target.value.trim());
                    }}
                    disabled={newCitySelectedObj?.id ? false : true}
                  />
                </Col>
                <Col width="auto" height="auto" margtop={px2vw(23)}>
                  <SearchButton type="submit" district>
                    {addDistrictLoading ? (
                      <LoadingBox>
                        <Spinner
                          style={{
                            width: '1.2rem',
                            height: '1.2rem',
                            color: preto,
                          }}
                        />{' '}
                      </LoadingBox>
                    ) : (
                      'Incluir'
                    )}
                  </SearchButton>
                </Col>
              </>
            )}
            
          </Row>
        </FormBox>

        <Row width="auto">
          <DistrictsTable data={tableList} />
        </Row>
      </WhiteBox>
    </ContentPage>
  );
}
