export function getMonthlyPaymentMetricsRequest(previousMonth) {
  return {
    type: '@monthlyPaymentMetrics/GET_MONTHLY_PAYMENT_METRICS_REQUEST',
    payload: { previousMonth },
  };
}

export function getMonthlyPaymentMetricsSuccess(monthlyPaymentMetrics) {
  return {
    type: '@monthlyPaymentMetrics/GET_MONTHLY_PAYMENT_METRICS_SUCCESS',
    payload: { monthlyPaymentMetrics },
  };
}

export function getMonthlyPaymentMetricsFailure() {
  return {
    type: '@monthlyPaymentMetrics/GET_MONTHLY_PAYMENT_METRICS_FAILURE',
  };
}
