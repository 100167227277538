import styled from 'styled-components';
import { Button } from '../../styles/common';
import {
  branco,
  verdeKuppi1,
  cinzaKuppi4,
  cinzaKuppi9,
} from '../../styles/colors';

import { Form, Select } from '@rocketseat/unform';

import px2vw from '../../utils/responsiveness/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;
`;

export const TableArea = styled.div`
  background: ${branco};
  padding: 12px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const TextBox = styled.div`
  margin-left: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;
`;

export const Title = styled.div`
  font-size:  ${px2vw(24)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-style: normal;

  ${(props) => props.boxTitle && `
    font-size:  ${px2vw(18)};
  `}

  ${(props) => `
    width: ${props.width}; 
    font-size: ${props.fontsize};
  `}
`;

export const ContentPrimary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 70px;
  width: 100%;
  height: 100%;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;
`;

export const ProfileImageBox = styled.div`
  position: relative;
  max-width: 65px;
  max-height: 65px;
  width: 65px;
  height: 65px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;
`;

export const ProfileImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;
`;

export const SwitchTableButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;

  .selected {
    margin-top: 5px;
    width: 100%;
    height: 4px;
    background: transparent;
    border-radius: 10px;
  }

  ${(props) =>
    props.selected &&
    `
    .selected {
      background: ${verdeKuppi1};
    }
  `}
`;

export const FormBox = styled(Form)`
  width: auto;
  height: auto;
`;

export const SelectInput = styled(Select)`
  height: ${px2vw(35)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  border: 1px solid ${cinzaKuppi4};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    background-color: ${branco};
    border: ${props.border} !important;
    border-radius: ${px2vw(5)}
  `};
`;

/** *************************************** */

export const ContentDefault = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: ${branco};
  width: 100%;
  padding: ${px2vw(20)};
  border-radius: 20px;
  margin-bottom: 30px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;

  ${(props) => props.flexStart && `justify-content: flex-start;`}

  ${(props) =>
    props.groupTop &&
    `
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 2px;
  `}

  ${(props) =>
    props.groupBottom &&
    `
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 30px;
  `}

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiTableCell-root {
    font-family: AvenirNextLTPro;
    font-weight: normal;
    font-style: normal;
  }
  button.MuiButtonBase-root {
    :focus{
      background: transparent;
    }
  }

  .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: transparent;
  }

  .MuiTableRow-head {
    th {
      font-size: ${px2vw(11)};
      font-family: AvenirNextLTPro;
      font-weight: 700;
      font-style: normal;
    }
  }
`;

export const Field = styled.div`
  padding: 15px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;

  ${(props) => props.mlAuto && `margin-left: auto`}
  ${(props) => props.mrAuto && `border-right: auto`}
`;

export const TextField = styled.div`
  font-size: 15px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;
`;

export const AddButton = styled.button`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;
  background: ${verdeKuppi1} !important;
  color: ${branco} !important;
  border-radius: 10px;
  border: none;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;

  :active {
    filter: brightness(95%);
  }
`;

export const SearchInput = styled.input`
  width: 250px !important;
  height: 38px !important;
  margin: 0 !important;
  padding-left: 10px !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  ::placeholder {
    color: ${cinzaKuppi9};
  }
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 0 15px;
  background: ${branco};
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: 10px !important;
`;


