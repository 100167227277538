import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { store } from '../store';

import Signed from '../pages/_layouts/signed';
import NoLayout from '../pages/_layouts/noLayout';

import { dashboard, login } from './routeMap';

export default function RouteWrapper({
  isPrivate,
  option,
  permissionRole,
  userRole,
  component: Component,
  ...rest
}) {
  const redux = store.getState();
  const { signed } = redux.auth;
  const { access } = redux.user;

  if (!rest.path.includes('/admin/dashboard')  && rest.path.includes('/admin/') && access && access.roles && (access.roles.findIndex((r) => r.slug === 'admin_reports') !== -1)) {
    return <Redirect to={dashboard} />;
  }

  if (!signed && isPrivate) {
    return <Redirect to={login} />;
  }

  if (signed && rest.path === login) {
    return <Redirect to={dashboard} />;
  }

  const Layout = rest.noLayout ? NoLayout : Signed;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout option={option}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  option: PropTypes.string,
  isPrivate: PropTypes.bool,
  admin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  admin: false,
};
