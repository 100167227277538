import styled from 'styled-components';
import { Form, Input } from '@rocketseat/unform';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
  verdeKuppi16,
  verdeKuppi5
} from '../../styles/colors';

export const LoginLeft = styled.div`
  float: left;
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(217.6deg, ${verdeKuppi16} 13.83%, rgba(47, 254, 95, 0) 43.43%), ${verdeKuppi5};
`;

export const LoginRight = styled.div`
  float: right;
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormLogin = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoImageBox = styled.div`
  position: relative;
  width: ${px2vw(253.6)};
  height: ${px2vw(73.6)};
`;

export const LogoImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const LogoLeftImage = styled.img`
  width: ${px2vw(300)};
  height: ${px2vw(350)};
`;

export const LoginInputBox = styled.div`
  width: ${px2vw(600)};
  height: ${px2vw(80)};
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `};
`;

export const LoginInputLabel = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-bottom: ${px2vw(2)};
`;

export const LoginInput = styled(Input)`
  padding: 10px 10px !important;
  margin: 0 !important;
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: 5px !important;
  font-size: ${px2vw(16)} !important;
  font-family: font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: 100%;
  height: auto;

  ::placeholder {
    color: ${cinzaKuppi3} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
  `};
`;

export const SeePasswordButton = styled.button`
  position: absolute;
  margin-left: 630px;
  margin-top: 23px;
  width: 20px;
  height: 20px;
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }
`;

export const SeePasswordImage = styled.img`
  width: 25px;
  height: 25px;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;

export const ForgotPasswordButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.36px;
  color: ${verdeKuppi1};

  :focus {
    background-color: transparent !important;
  }
`;

export const LoginButton = styled.button`
  background-color: ${verdeKuppi1};
  width: 100%;
  height: 55px;
  border: 1px solid ${verdeKuppi1};
  border-radius: 5px;
  padding: 0.3em;
  margin-top: 60px;

  :focus {
    background-color: ${verdeKuppi1} !important;
  }
`;

export const LoginButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const ForgotEmailPasswordModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputsBox = styled.div``;
