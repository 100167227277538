import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import px2vw from '../../../../../../utils/responsiveness/px2vw';
import Modal from '../../../../../../components/Modal';
import { CloseButton, ModalHeader, ModalTitleText } from '../../../../../../components/Modal/styles';
import closeButtonImg from '../../../../../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../../../../../styles/grid';
import {
  CancelButton,
  ChoiceButton,
  ChoiceButtonSelected,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
} from '../../../../../../components/EditResponsibleDataModal/styles';
import { preto } from '../../../../../../styles/colors';
import { LoadingBox } from '../../../../../../styles/common';
import { amountMask } from '../../../../../../utils/masks';
import { editMassMessageClientRequest } from '../../../../../../store/modules/clients/actions';
import { onlyNumbersAmount } from '../../../../../../utils/utils';
import { toast } from 'react-toastify';

export default function EditMassMessageModal({ onClose }) {
  const { editMassMessageClientLoading, clientData } = useSelector((state) => state.clients);
  
  const massMessageData = useSelector((state) => state.clients.massMessageData);
  const [unlimitedPackage, setUnlimitedPackage] = useState(massMessageData?.configs?.is_using_package || false);
  const [newAmount, setNewAmount] = useState(amountMask(`${massMessageData?.configs?.per_contact_amount?.toFixed(2)}`));
  const dispatch = useDispatch();

  function handleEditMassMessageSubmit() {
    if (unlimitedPackage && newAmount.length < 3) {
      toast.error('Por gentileza inserir um valor válido! Exemplo: (5.00)');
    } else {
      dispatch(editMassMessageClientRequest(clientData.id, unlimitedPackage, onlyNumbersAmount(newAmount)));
    }
  }

  return (
    <Modal
      isVisible={true}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Alterar Valor do Disparo / Pacote Ilimitado</ModalTitleText>
        <CloseButton onClick={onClose} disabled={editMassMessageClientLoading}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handleEditMassMessageSubmit}>
        <Row
          width="auto"
          margtop={px2vw(20)}
          margleft={px2vw(10)}
          justifyStart
          cursor="pointer"
          onClick={() => {
            setNewAmount(amountMask(`${unlimitedPackage ? '0.10' : '0.00'}`));
            setUnlimitedPackage(!unlimitedPackage);
          }}
        >
          <Row
            width="auto"
            height="auto"
            margright={px2vw(5)}
            cursor="pointer"
          >
            <ChoiceButton />
            <ChoiceButtonSelected
              isChecked={unlimitedPackage}
            />
          </Row>
          <InputTitle
            fontweight="normal"
            cursor="pointer"
            fontSize={px2vw(13)}
          >
            Pacote Ilimitado
          </InputTitle>
        </Row>
        <Col margtop={px2vw(20)} margleft={px2vw(10)}>
          <InputTitle>Valor por contato</InputTitle>
          <InputData
            name="newAmount"
            type="text"
            maxLength="7"
            placeholder="R$ 0,10"
            width={px2vw(100)}
            value={newAmount}
            disabled={unlimitedPackage}
            onChange={(e) => setNewAmount(amountMask(e.target.value))}
          />
        </Col>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton onClick={onClose} disabled={editMassMessageClientLoading}>Cancelar</CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit" disabled={editMassMessageClientLoading}>
              {editMassMessageClientLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
}
