import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { dddPhoneMask, currencyMask } from '../../../utils/masks';

import { apiAdmin } from '../../../services/api';

import {
  setOnboardingAndActivation,
  setPreImplementation,
  setMenuAndCoupons,
  setOrders,
  setKuppiBoostOnboardingPending,
  setSubscriptions,
  setKuppidoSubscriptions,
  formatMenuAndCoupons as formatMenuAndCouponsAction,
  formatOrders as formatOrdersAction,
  formatSubscriptions as formatSubscriptionsAction,
  formatKuppidoSubscriptions as formatKuppidoSubscriptionsAction,
  formatOnboardingAndActivation as formatOnboardingAndActivationAction,
  formatKuppiBoostOnboardingPending as formatKuppiBoostOnboardingPendingAction,
  checkKuppiBoostPermissionsResponse,
  requestFailure,
  setKuppidoReports as setKuppidoReportsAction,
  setFormatInitialProspecting,
  setFormatEngagement,
  setFormatConversion,
  formatInitialProspecting as formatInitialProspectingAction,
  formatConversion as formatConversionAction,
  formatEngagement as formatEngagementAction,
  formatPreImplementation as formatPreImplementationAction,
} from './actions';
import { logout } from '../auth/actions';

export function* formatMenuAndCoupons({ payload }) {
  const { data } = payload;

  function* getPayable() {
    yield* data;
  }

  const sequence = getPayable();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      let loyalty_cards = '0';
      if (value.loyaltyCard) {
        loyalty_cards = value.loyaltyCard.__meta__.userLoyaltyCard_count;
      }
      const phones = {
        phone_1: dddPhoneMask(value.user.address[0].phone_1),
        phone_2: dddPhoneMask(value.user.address[0].phone_2),
        phone_whatsapp: dddPhoneMask(value.user.address[0].phone_whatsapp),
      };

      const currentData = {
        name: value.name,
        email: value.user.email,
        phones,
        coupons: value.__meta__.coupon_count,
        products: value.__meta__.product_count,
        loyalty_cards,
      };

      results.push(currentData);
    } else {
      hasIterable = false;
    }
  }
  yield put(setMenuAndCoupons(results));
}

export function* formatPreImplementation({ payload }) {
  const { data } = payload;

  function* getPayable() {
    yield* data;
  }

  const sequence = getPayable();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const currentData = {
        id: value.id,
        name: value.name,
        email: value.email,
        phone_1: dddPhoneMask(value.phone_1),
        phone_2: dddPhoneMask(value.phone_2),
        phone_whatsapp: dddPhoneMask(value.phone_whatsapp),
        phone_whatsapp_representative: dddPhoneMask(value.phone_whatsapp_representative),
        last_login_at: value.last_login_at
          ? format(new Date(value.last_login_at), "dd/MM/yyyy' às 'HH:mm")
          : '-',
        is_finished_first_steps: value.is_finished_first_steps ? 'Sim' : 'Não',
      };

      results.push(currentData);
    } else {
      hasIterable = false;
    }
  }
  yield put(setPreImplementation(results));
}

export function* formatOrders({ payload }) {
  const { data } = payload;

  function* getPayable() {
    yield* data;
  }

  const sequence = getPayable();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const newOrder = value.order.length;
      let preparingOrder = 0;
      let finishedOrder = 0;
      const phones = {
        phone_1: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_1)
          : '-',
        phone_2: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_2)
          : '-',
        phone_whatsapp: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_whatsapp)
          : '-',
      };

      value.order.forEach((item) => {
        if (item.orderStatus.preparing_at) {
          preparingOrder += 1;
        }
        if (item.orderStatus.finished_at) {
          finishedOrder += 1;
        }
      });

      const currentData = {
        name: value.name,
        email: value.user.email,
        phones,
        newOrder,
        preparingOrder,
        finishedOrder,
      };

      results.push(currentData);
    } else {
      hasIterable = false;
    }
  }
  yield put(setOrders(results));
}

export function* formatSubscriptions({ payload }) {
  const { data } = payload;

  function* destructData() {
    yield* data;
  }

  const sequence = destructData();
  let hasIterable = true;
  const formattedSubscriptions = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const formattedSubscription = {
        name: value.name,
        email: value.user.email,
        phone: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_1)
          : '-',
        plan: value.advertiserSubscription[0].subscription.name,
        amount: currencyMask(value.advertiserSubscription[0].amount),
        start_validity_at: value.advertiserSubscription[0].start_validity_at,
        finish_validity_at: value.advertiserSubscription[0].finish_validity_at,
        renovations: value.advertiserSubscription.length - 1,
      };

      formattedSubscriptions.push(formattedSubscription);
    } else {
      hasIterable = false;
    }
  }
  yield put(setSubscriptions(formattedSubscriptions));
}

export function* formatKuppidoSubscriptions({ payload }) {
  const { data } = payload;

  function* destructData() {
    yield* data;
  }

  const sequence = destructData();
  let hasIterable = true;
  const formattedKuppidoSubscriptions = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const {
        kuppidoInfo,
        kuppidoMasterInfo,
        advertiserInfo,
        created_at,
      } = value;

      if (kuppidoInfo) {
        const formattedKuppidoSubscription = {
          name: kuppidoInfo.name,
          email: kuppidoInfo.user.email,
          advertiser_name: advertiserInfo.name,
          plan: advertiserInfo.advertiserSubscription
            ? advertiserInfo.advertiserSubscription[0].subscription.name
            : '-',
          subscribed_date: format(new Date(created_at), 'dd/MM/yyyy'),
          amount: advertiserInfo.advertiserSubscription
            ? currencyMask(advertiserInfo.advertiserSubscription[0].amount)
            : '-',
        };

        formattedKuppidoSubscriptions.push(formattedKuppidoSubscription);
      } else if (kuppidoMasterInfo) {
        const formattedKuppidoMasterSubscription = {
          name: kuppidoMasterInfo.name,
          email: kuppidoMasterInfo.user.email,
          advertiser_name: advertiserInfo.name,
          plan: advertiserInfo.advertiserSubscription
            ? advertiserInfo.advertiserSubscription[0].subscription.name
            : '-',
          subscribed_date: created_at
            ? format(new Date(created_at), 'dd/MM/yyyy')
            : '-',
          amount: advertiserInfo.advertiserSubscription
            ? currencyMask(advertiserInfo.advertiserSubscription[0].amount)
            : '-',
        };
        formattedKuppidoSubscriptions.push(formattedKuppidoMasterSubscription);
      }
    } else {
      hasIterable = false;
    }
  }
  yield put(setKuppidoSubscriptions(formattedKuppidoSubscriptions));
}

export function* formatInitialProspecting({ payload }) {
  const { data } = payload;

  function* getData() {
    yield* data;
  }

  const sequence = getData();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const registeredLeads = value.kuppidoLead.length;
      let leadsWithActivity = 0;
      let contractInMonth = 0;
      const phones = {
        phone_1: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_1)
          : '-',
        phone_2: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_2)
          : '-',
        phone_whatsapp: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_whatsapp)
          : '-',
      };

      value.kuppidoLead.forEach((item) => {
        if (item.kuppidoLeadActivity) {
          if (item.kuppidoLeadActivity.length > 0) {
            leadsWithActivity += 1;
          }
          if (item.funnel_status === 'ganho' && item.situation === 'ganho') {
            contractInMonth += 1;
          }
        }
      });

      const currentData = {
        name: value.name,
        email: value.user.email,
        phones,
        registeredLeads,
        leadsWithActivity,
        contractInMonth,
      };

      results.push(currentData);
    } else {
      hasIterable = false;
    }
  }
  yield put(setFormatInitialProspecting(results));
}

export function* formatConversion({ payload }) {
  const { data } = payload;

  function* getData() {
    yield* data;
  }

  const sequence = getData();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const registeredLeads = value.kuppidoLead.length;
      let contractInMonth = 0;
      let conversionRate = '0%';
      const phones = {
        phone_1: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_1)
          : '-',
        phone_2: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_2)
          : '-',
        phone_whatsapp: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_whatsapp)
          : '-',
      };

      value.kuppidoLead.forEach((item, index, arr) => {
        if (item.kuppidoLeadActivity) {
          if (item.funnel_status === 'ganho' && item.situation === 'ganho') {
            contractInMonth += 1;
            conversionRate = `${((contractInMonth / arr.length) * 100).toFixed(
              0
            )}%`;
          }
        }
      });

      const currentData = {
        name: value.name,
        email: value.user.email,
        phones,
        registeredLeads,
        contractInMonth,
        conversionRate,
      };

      results.push(currentData);
    } else {
      hasIterable = false;
    }
  }
  yield put(setFormatConversion(results));
}

export function* formatEngagement({ payload }) {
  const { data } = payload;

  function* getData() {
    yield* data;
  }

  const sequence = getData();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const lastLogin = format(
        new Date(value.user.last_login_at),
        'dd/MM/yyyy HH:mm'
      );
      let lastDataLead = null;
      const phones = {
        phone_1: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_1)
          : '-',
        phone_2: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_2)
          : '-',
        phone_whatsapp: value.user.address
          ? dddPhoneMask(value.user.address[0].phone_whatsapp)
          : '-',
      };

      value.kuppidoLead.forEach((item) => {
        if (item.kuppidoLeadActivity) {
          item.kuppidoLeadActivity.forEach((i) => {
            if (lastDataLead === null) {
              lastDataLead = format(
                new Date(i.activity_at),
                'dd/MM/yyyy HH:mm'
              );
            }
            if (new Date(lastDataLead) < new Date(i.activity_at)) {
              lastDataLead = format(
                new Date(i.activity_at),
                'dd/MM/yyyy HH:mm'
              );
            }
          });
        }
      });

      const currentData = {
        name: value.name,
        email: value.user.email,
        phones,
        lastLogin,
        lastDataLead,
      };

      results.push(currentData);
    } else {
      hasIterable = false;
    }
  }
  yield put(setFormatEngagement(results));
}

export function* formatOnboardingAndActivation({ payload }) {
  const { data } = payload;

  function* destructData() {
    yield* data;
  }

  const sequence = destructData();
  let hasIterable = true;
  const formattedData = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const hasAtLeastOneAvailability = Object.keys(value).some((key) => {
        switch (key) {
          case 'sunday':
          case 'monday':
          case 'tuesday':
          case 'wednesday':
          case 'thursday':
          case 'friday':
          case 'saturday': {
            return value[key].is_active;
          }
          default:
            break;
        }
      });

      const hasAtLeastOneProduct = value.products_count > 0;
      const hasProfilePicture = value.profile_file_id;
      const hasSelectedPaymentMethods = value.payments_active_count > 0;
      const isDeliveryConfigured =
        value.delivery_range_area && value.delivery_fee >= 0;

      const formattedPreImplementation = {
        name: value.name,
        email: value.email,
        phone: dddPhoneMask(value.phone_1),
        last_login_at: value.last_login_at
          ? format(new Date(value.last_login_at), "dd/MM/yyyy' às 'HH:mm")
          : '-',
        isStoreLaunched:
          hasAtLeastOneProduct &&
          hasAtLeastOneAvailability &&
          hasProfilePicture &&
          hasSelectedPaymentMethods &&
          isDeliveryConfigured
            ? 'Sim'
            : 'Não',
        isStoreLaunchedDetails: {
          hasAtLeastOneProduct,
          hasAtLeastOneAvailability,
          hasProfilePicture,
          hasSelectedPaymentMethods,
          isDeliveryConfigured,
        },
      };

      formattedData.push(formattedPreImplementation);
    } else {
      hasIterable = false;
    }
  }
  yield put(setOnboardingAndActivation(formattedData));
}

export function* formatKuppiBoostOnboardingPending({ payload }) {
  const { data } = payload;

  function* destructData() {
    yield* data;
  }

  const sequence = destructData();
  let hasIterable = true;
  const formattedData = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      let formattedStatus;
      switch (value.status) {
        case 'pending_approval':
          formattedStatus = 'Pendente de Validação';
          break;
        case 'bad_configuration':
          formattedStatus = 'Erro na Configuração';
          break;
        default: 
          formattedStatus = 'Status Inválido';
          break;
      }

      const currFormattedData = {
        advertiser_info_id: value.advertiser_info_id,
        name: value.advertiserInfo.name,
        status: formattedStatus,
        phone_whatsapp_representative: dddPhoneMask(value.advertiserInfo.phone_whatsapp_representative),
        facebook_page_id: value.facebook_page_id,
        instagram_page_id: value.instagram_page_id,
      };

      formattedData.push(currFormattedData);
    } else {
      hasIterable = false;
    }
  }
  yield put(setKuppiBoostOnboardingPending(formattedData));
}

/** Rota no backend com nomenclatura incorreta, substituir posteriormente. */
export function* getOnboardingAndActivation() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/reports/anunciante/onboarding-and-activation`
    );

    yield put(formatOnboardingAndActivationAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

/** Rota no backend com nomenclatura incorreta, substituir posteriormente. */
export function* getPreImplementation() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/reports/anunciante/pre-implementation`
    );

    yield put(formatPreImplementationAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* getMenuAndCoupons() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/reports/anunciante/menu-and-coupons`
    );
    yield put(formatMenuAndCouponsAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* getOrders() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/reports/anunciante/orders`
    );
    yield put(formatOrdersAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* getKuppiBoostOnboardingPending() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/reports/anunciante/kuppi-boost-onboarding-pending`
    );

    yield put(formatKuppiBoostOnboardingPendingAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* checkKuppiBoostPermissionsRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/user-info/${payload.advertiserInfoId}/kuppi-boost/check-permissions`
    );

    if (response?.data?.error?.message) {
      toast.error(response?.data?.error?.message, {
        autoClose: false,
      });
    } else {
      toast.success('Permissões concedidas corretamente, usuário liberado para utilizar o Kuppi Boost', { 
        autoClose: 6000
      })
    }

    yield put(checkKuppiBoostPermissionsResponse());
    yield put(formatKuppiBoostOnboardingPendingAction(response?.data?.pendingBoostConfigs));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(checkKuppiBoostPermissionsResponse());

      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 8000,
        });
      } else {
        toast.error(
          'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
          { autoClose: 8000 }
        );
      }
    }
  }
}

export function* getSubscriptions() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/reports/anunciante/subscriptions`
    );
    yield put(formatSubscriptionsAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* getKuppidoSubscriptions() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/reports/kuppido/subscriptions`
    );
    yield put(formatKuppidoSubscriptionsAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* setKuppidoReports({ payload }) {
  const { data } = payload;

  try {
    yield put(formatInitialProspectingAction(data));
    yield put(formatConversionAction(data));
    yield put(formatEngagementAction(data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* getKuppidoReports({ payload }) {
  try {
    const { filter } = payload;

    const response = yield call(
      apiAdmin.get,
      `/reports/kuppido/${filter}`
    );
    yield put(setKuppidoReportsAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export default all([
  takeLatest(
    '@reports/GET_ONBOARDING_AND_ACTIVATION',
    getOnboardingAndActivation
  ),
  takeLatest('@reports/GET_PRE_IMPLEMENTATION', getPreImplementation),
  takeLatest('@reports/GET_MENU_AND_COUPONS', getMenuAndCoupons),
  takeLatest('@reports/GET_ORDERS', getOrders),
  takeLatest(
    '@reports/GET_KUPPI_BOOST_ONBOARDING_PENDING',
    getKuppiBoostOnboardingPending
  ),
  takeLatest(
    '@reports/CHECK_KUPPI_BOOST_PERMISSIONS_REQUEST',
    checkKuppiBoostPermissionsRequest
  ),
  takeLatest('@reports/GET_SUBSCRIPTIONS', getSubscriptions),
  takeLatest('@reports/GET_KUPPIDO_SUBSCRIPTIONS', getKuppidoSubscriptions),
  takeLatest('@reports/FORMAT_MENU_AND_COUPONS', formatMenuAndCoupons),
  takeLatest('@reports/FORMAT_ORDERS', formatOrders),
  takeLatest('@reports/FORMAT_KUPPI_BOOST_ONBOARDING_PENDING', formatKuppiBoostOnboardingPending),
  takeLatest('@reports/FORMAT_SUBSCRIPTIONS', formatSubscriptions),
  takeLatest(
    '@reports/FORMAT_KUPPIDO_SUBSCRIPTIONS',
    formatKuppidoSubscriptions
  ),
  takeLatest(
    '@reports/FORMAT_ONBOARDING_AND_ACTIVATION',
    formatOnboardingAndActivation
  ),
  takeLatest('@reports/GET_KUPPIDO_REPORTS', getKuppidoReports),
  takeLatest('@reports/SET_KUPPIDO_REPORTS', setKuppidoReports),
  takeLatest('@reports/FORMAT_INITIAL_PROPSPECTING', formatInitialProspecting),
  takeLatest('@reports/FORMAT_ENGAGEMENT', formatEngagement),
  takeLatest('@reports/FORMAT_CONVERSION', formatConversion),
  takeLatest('@reports/FORMAT_PRE_IMPLEMENTATION', formatPreImplementation),
]);
