import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAdmin } from '../../../services/api';

import {
  getMassMessageGeneralSuccess,
  getMassMessageGeneralFailure,
  updateMassMessageGeneralAmountSuccess,
  updateMassMessageGeneralAmountFailure,
} from './actions';
import { logout } from '../auth/actions';

export function* getMassMessageGeneralRequest() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/mass-message/clients/general-data`
    );
    yield put(getMassMessageGeneralSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar gerais de disparo em massa. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getMassMessageGeneralFailure());
    }
  }
}

export function* updateMassMessageGeneralAmountRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.put,
      `/mass-message/clients/per-contact-amount/update`,
      {
        per_contact_amount: payload.newAmount,
      }
    );
    yield put(updateMassMessageGeneralAmountSuccess(response.data));
    toast.success('Valor do disparo em massa atualizado!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao atualizar o valor por contato do disparo em massa. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(updateMassMessageGeneralAmountFailure());
    }
  }
}

export default all([
  takeLatest('@massMessageGeneral/GET_MASS_MESSAGE_GENERAL_REQUEST', getMassMessageGeneralRequest),
  takeLatest('@massMessageGeneral/UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_REQUEST', updateMassMessageGeneralAmountRequest),
]);
