import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cinzaKuppi4, preto } from '../../styles/colors';
import { Spinner } from 'reactstrap';
import { Col, Row } from '../../styles/grid';

import { BalanceIcon, MenuBox, Tab, TabBox, TabsBox, Text } from './styles';
import px2vw from '../../utils/responsiveness/px2vw';
import { LoadingBox } from '../../styles/common';
import AdsTable from './AdsTable';
import PaymentTable from './PaymentHistoricTable';
import ConfirmPaymentReversalModal from '../ConfirmPaymentReversalModal';
import { paymentReversalRequest } from '../../store/modules/clients/actions';
import AccessValidate from '../../utils/validations/AccessValidate';

export default function KuppiBoostClientsData() {
  const { access } = useSelector((state) => state.user);
  const financialAccess = AccessValidate(
    access,
    'admin_financial',
    'financial',
    null,
    false
  );
  const { campaignsDataLoading } = useSelector((state) => state.clients);
  const kuppiBoostData = useSelector((state) => state.clients.kuppiBoostData);
  const campaigns = useSelector((state) => state.clients.campaignsData);
  const paymentsData = useSelector((state) => state.clients.paymentsData);
  const [selectedTab, setSelectedTab] = useState('tabAdvertisement');
  const [confirmPaymentReversal, setConfirmPaymentReversal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);

  const dispatch = useDispatch();

  const handleTabSelected = (newTab) => {
    setSelectedTab(newTab);
  };

  function handlePaymentReversalSubmit() {
    dispatch(paymentReversalRequest(kuppiBoostData.advertiser_info_id, campaignId))
  }

  const handler = {
    confirmPaymentReversal: confirmPaymentReversal,
    campaignId: campaignId,
    setConfirmPaymentReversal: () =>
      setConfirmPaymentReversal(!confirmPaymentReversal),
    setCampaignId: (id) => setCampaignId(id),
    handlePaymentReversalSubmit: () => handlePaymentReversalSubmit(),
  };

  return (
    <>
      <MenuBox>
        <Row
          height="auto"
          width="100%"
          className="pt-3 pb-4 pr-3"
          justifyStart
          alignCenter
          borderbottom={`2px solid ${cinzaKuppi4}`}
        >
          <TabsBox>
            <TabBox margL={px2vw(35)}>
              <Tab
                selected={selectedTab === 'tabAdvertisement'}
                onClick={() => handleTabSelected('tabAdvertisement')}
              >
                Todos os anúncios
              </Tab>
            </TabBox>
            <TabBox margL={px2vw(35)}>
              <Tab
                selected={selectedTab === 'tabHistoric'}
                onClick={() => handleTabSelected('tabHistoric')}
              >
                Histórico de pagamento
              </Tab>
            </TabBox>
          </TabsBox>
        </Row>
        <Col
          className={
            selectedTab === 'tabHistoric' ? 'mb-auto pb-3' : 'my-auto pl-3 pb-3'
          }
          alignCenter
        >
          {selectedTab === 'tabHistoric' && (
            <PaymentTable data={paymentsData} />
          )}

          {selectedTab === 'tabAdvertisement' ? (
            campaignsDataLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : campaigns.length > 0 && !campaignsDataLoading ? (
              <AdsTable data={campaigns} handler={handler} />
            ) : (
              <>
                <Row alignCenter>
                  <BalanceIcon
                    src={require(`../../assets/images/kuppi_boost/${
                      kuppiBoostData.facebook_page_id === null &&
                      kuppiBoostData.instagram_page_id === null
                        ? 'sad_id'
                        : kuppiBoostData.balance > 0
                        ? 'kuppiboost_happy'
                        : 'kuppiboost_sad'
                    }.png`)}
                  />
                  <Text size={px2vw(13)} color={preto} bold>
                    {!kuppiBoostData.facebook_page_id &&
                    !kuppiBoostData.instagram_page_id
                      ? 'Não é possível impulsionar sem um ID vinculado'
                      : kuppiBoostData.balance
                      ? 'Cliente possui saldo e pode utilizar o Kuppi Boost'
                      : 'Cliente não possui saldo para utilizar o Kuppi Boost'}
                  </Text>
                </Row>
              </>
            )
          ) : (
            ''
          )}
        </Col>
      </MenuBox>
      {financialAccess ? 
      confirmPaymentReversal && (
        <ConfirmPaymentReversalModal handler={handler} />
      ) : ('')
    }
      
    </>
  );
}
