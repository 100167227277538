import styled, { css } from 'styled-components';
import px2vw from '../../../../utils/responsiveness/px2vw';

import { verdeKuppi1, branco, verdeKuppi4 } from '../../../../styles/colors';

export const ValidateButton = styled.button`
  background-color: ${verdeKuppi1};
  border: 1px solid ${verdeKuppi1};
  color: ${branco};
  border-radius: ${(props) => px2vw(props.borderRadius || 10)};
  height: ${(props) => px2vw(props.height || 30)};
  min-width: ${px2vw(70)};
  line-height: ${(props) => px2vw(props.height || 10)};
  font-size: ${(props) => px2vw(props.fontSize || 13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding: ${px2vw(10)};
  cursor: pointer;
  margin-right: ${px2vw(4)};
}
  ${(props) =>
    props.margright &&
    css`
      margin-right: ${props.margright};
    `}

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;
