import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from '../../../../../styles/grid';
import { EditButton, Hr, Text, WhiteBox } from '../../styles';

import editImg from '../../../../../assets/images/clients/edit@2x.png';
import EditResponsibleDataModal from '../../../../../components/EditResponsibleDataModal';
import EditStoreDataModal from '../../../../../components/EditStoreDataModal';

import {
  getClientCategoriesRequest,
  getClientDataRequest,
  updateRepresentativeDataRequest,
  updateStoreDataRequest,
} from '../../../../../store/modules/clients/actions';

import { clearFields, recoverPasswordRequest } from '../../../../../store/modules/auth/actions';

import {
  cepMask,
  cpfCnpjMask,
  dateMask,
  dddPhoneMask,
} from '../../../../../utils/masks';
import { getWebMenuURL, onlyNumbers } from '../../../../../utils/utils';
import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';
import CpfCnpjValidator from '../../../../../utils/validations/CpfCnpjValidator';

export default function TabData() {
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );
  const clientData = useSelector((state) => state.clients.clientData);
  const clientCategories = useSelector(
    (state) => state.clients.clientCategories
  );
  const {
    updateRepresentativeDataSuccess,
    updateStoreDataSuccess,
    generateNewPasswordSuccess,
  } = useSelector((state) => state.clients);
  const {
    recoverPasswordSuccess
  } = useSelector((state) => state.auth);
  const [editResponsibleData, setEditResponsibleData] = useState(false);
  const [editStoreData, setEditStoreData] = useState(false);
  const [representativeEmail, setRepresentativeEmail] = useState(
    clientData.store_representative_data.email
  );
  const [representativeName, setRepresentativeName] = useState(
    clientData.store_representative_data.name
  );
  const [representativeWhatsapp, setRepresentativeWhatsapp] = useState(
    dddPhoneMask(clientData.store_representative_data.whatsapp)
  );
  const [storeName, setStoreName] = useState(clientData.store_data.name);
  const [storeSlug, setStoreSlug] = useState(clientData.store_data.slug);
  const [storeCpfCnpj, setStoreCpfCnpj] = useState(
    cpfCnpjMask(clientData.store_data.cpf_cnpj)
  );
  const [storeCategoryId, setStoreCategoryId] = useState(
    clientData.store_data.category
  );
  const [categoryName, setCategoryName] = useState('');
  const [storeLatitude, setStoreLatitude] = useState(
    clientData.store_data.address.latitude
  );
  const [storeLongitude, setStoreLongitude] = useState(
    clientData.store_data.address.longitude
  );
  const [generateNewPassword, setGenerateNewPassword] = useState(false);
  const [linkForgotPassword, setLinkForgotPassword] = useState(null);
  const [forgotPasswordButtonText, setForgotPasswordButtonText] = useState('');
  const [addressVisibleText, setAddressVisibleText] = useState('');
  const [addressVisibleCheck, setAddressVisibleCheck] = useState(clientData.store_data.address.is_visible_on_web_menu);

  const dispatch = useDispatch();

  function handleEditRepresentativeDataSubmit() {
    if (
      representativeEmail === '' ||
      representativeName === '' ||
      representativeWhatsapp === ''
    ) {
      toast.error('Por gentileza não deixar nenhum campo em branco!');
    } else {
      dispatch(
        updateRepresentativeDataRequest(
          clientData.id,
          representativeEmail,
          representativeName,
          onlyNumbers(`55${representativeWhatsapp}`)
        )
      );
    }
  }

  function handleEditStoreDataSubmit() {
    if (
      storeName === '' ||
      storeCpfCnpj === '' ||
      storeLatitude === '' ||
      storeLongitude === ''
    ) {
      toast.error('Por gentileza não deixar nenhum campo em branco!');
    } else if (!CpfCnpjValidator(storeCpfCnpj)) {
      toast.error('Por gentileza preencher um N° de CPF/ CNPJ válido!');
    } else {
      dispatch(
        updateStoreDataRequest(
          clientData.id,
          storeName,
          onlyNumbers(storeCpfCnpj),
          storeCategoryId,
          storeLatitude,
          storeLongitude,
          addressVisibleCheck
        )
      );
    }
  }

  function handleCategoryName(arr) {
    arr.forEach((e) => {
      if (e.id === storeCategoryId) {
        setCategoryName(e.title);
      }
    });
  }

  function copyForgotPasswordLink() {
    const copyArea = document.createElement('textarea');
    document.body.appendChild(copyArea);
    copyArea.value = linkForgotPassword;
    copyArea.select();
    document.execCommand('copy');
    document.body.removeChild(copyArea);

    toast.info('Copiado para a área de transferência!');
  }

  function handleSendForgotPasswordLink() {
    dispatch(recoverPasswordRequest(representativeEmail));
    toast.info(
      'E-mail com link para redefinição de senha enviado para o cliente!'
    );
  }

  useEffect(() => {
    setRepresentativeEmail(clientData.store_representative_data.email);
    setRepresentativeName(clientData.store_representative_data.name);
    setRepresentativeWhatsapp(
      dddPhoneMask(clientData.store_representative_data.whatsapp)
    );
    setStoreName(clientData.store_data.name);
    setStoreSlug(clientData.store_data.slug);
    setStoreCpfCnpj(clientData.store_data.cpf_cnpj);
    setStoreCategoryId(clientData.store_data.category);
    setStoreLatitude(clientData.store_data.address.latitude);
    setStoreLongitude(clientData.store_data.address.longitude);
    handleCategoryName(clientCategories);
    setLinkForgotPassword(clientData.link);
    setAddressVisibleCheck(clientData.store_data.address.is_visible_on_web_menu)
  }, [clientData]);

  useEffect(() => {
    if (linkForgotPassword === null) {
      setForgotPasswordButtonText('Gerar link p/ redefinir senha');
    } else {
      setForgotPasswordButtonText('Copiar link p/ redefinição de senha');
    }
  }, [linkForgotPassword]);

  useEffect(() => {
    if(recoverPasswordSuccess){
      dispatch(clearFields())
      dispatch(getClientDataRequest(clientData.id));
      dispatch(getClientCategoriesRequest());
    }
  }, [recoverPasswordSuccess])

  useEffect(() => {
    if(!addressVisibleCheck) {
      setAddressVisibleText('Endereço do restaurante invisível na notinha e no cardapio online')
    } else {
      setAddressVisibleText('Endereço do restaurante visível na notinha e no cardapio online')
    }
  },[addressVisibleCheck])

  // close representative modal when update data success
  useEffect(() => {
    if (updateRepresentativeDataSuccess) {
      setEditResponsibleData(false);
    }
  }, [updateRepresentativeDataSuccess]);

  // close store modal when update data success
  useEffect(() => {
    if (updateStoreDataSuccess) {
      setEditStoreData(false);
    }
  }, [updateStoreDataSuccess]);

  // close generate new password modal on success
  useEffect(() => {
    if (generateNewPasswordSuccess) {
      setGenerateNewPassword(false);
    }
  }, [generateNewPasswordSuccess]);

  const handler = {
    advertiserInfoId: clientData.id,
    editResponsibleData: editResponsibleData,
    editStoreData: editStoreData,
    representativeEmail: representativeEmail,
    representativeName: representativeName,
    representativeWhatsapp: representativeWhatsapp,
    storeName: storeName,
    storeSlug: storeSlug,
    storeCpfCnpj: storeCpfCnpj,
    storeCategoryId: storeCategoryId,
    storeLatitude: storeLatitude,
    storeLongitude: storeLongitude,
    clientCategories: clientCategories,
    generateNewPassword: generateNewPassword,
    forgotPasswordButtonText: forgotPasswordButtonText,
    linkForgotPassword: linkForgotPassword,
    addressVisibleText: addressVisibleText,
    addressVisibleCheck: addressVisibleCheck,
    setEditResponsibleData: () => setEditResponsibleData(),
    setEditStoreData: () => setEditStoreData(),
    setRepresentativeEmail: (e) => setRepresentativeEmail(e),
    setRepresentativeName: (e) => setRepresentativeName(e),
    setRepresentativeWhatsapp: (e) =>
      setRepresentativeWhatsapp(dddPhoneMask(e)),
    handleEditRepresentativeDataSubmit: () =>
      handleEditRepresentativeDataSubmit(),
    handleEditStoreDataSubmit: () => handleEditStoreDataSubmit(),
    setStoreName: (e) => setStoreName(e),
    setStoreCpfCnpj: (e) => setStoreCpfCnpj(cpfCnpjMask(e)),
    setStoreCategoryId: (e) => setStoreCategoryId(e),
    setStoreLatitude: (e) => setStoreLatitude(e),
    setStoreLongitude: (e) => setStoreLongitude(e),
    setGenerateNewPassword: () => setGenerateNewPassword(!generateNewPassword),
    copyForgotPasswordLink: () => copyForgotPasswordLink(),
    handleSendForgotPasswordLink: () => handleSendForgotPasswordLink(),
    setAddressVisibleCheck: () => setAddressVisibleCheck(!addressVisibleCheck),
  };

  return (
    <>
      <Col>
        <Row>
          <Col>
            <Row justifyStart alignCenter>
              <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(15)}>
                Dados do responsável
              </Text>
              {operationalEditAccess && (
                <EditButton onClick={() => setEditResponsibleData(true)}>
                  <img
                    src={editImg}
                    alt="Edit Icon"
                    width={px2vw(175)}
                    height={px2vw(175)}
                  />
                  Editar
                </EditButton>
              )}
            </Row>
            <WhiteBox margtop={px2vw(10)} width={px2vw(475)}>
              <Row spaceBetween margbottom={px2vw(10)}>
                <Col>
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Nome do Responsável
                  </Text>
                  <Text name title={clientData.store_representative_data.name}>
                    {clientData.store_representative_data.name !== null
                      ? clientData.store_representative_data.name
                      : '-'}
                  </Text>
                </Col>
                <Col>
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Whatsapp do Responsável
                  </Text>
                  <Text>
                    {clientData.store_representative_data.whatsapp !== null
                      ? dddPhoneMask(
                          clientData.store_representative_data.whatsapp
                        )
                      : '-'}
                  </Text>
                </Col>
              </Row>
              <Hr />
              <Row spaceBetween margtop={px2vw(10)}>
                <Col>
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Email de acesso
                  </Text>
                  <Text>
                    {clientData.store_representative_data.email !== null
                      ? clientData.store_representative_data.email
                      : '-'}
                  </Text>
                </Col>
              </Row>
            </WhiteBox>
          </Col>

          {/* Responsible Data */}
          <WhiteBox>
            <Row justifyStart margbottom={px2vw(20)}>
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Responsável pela venda
              </Text>
            </Row>
            <Row spaceBetween margbottom={px2vw(10)}>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Nome do Vendedor
                </Text>
                <Text>
                  {clientData.sales_representative_data.name !== null
                    ? clientData.sales_representative_data.name
                    : '-'}
                </Text>
              </Col>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  CPF/CNPJ
                </Text>
                <Text>
                  {clientData.sales_representative_data.cpf_cnpj !== null
                    ? cpfCnpjMask(clientData.sales_representative_data.cpf_cnpj)
                    : '-'}
                </Text>
              </Col>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Tipo
                </Text>
                <Text>
                  {clientData.sales_representative_data.type !== null
                    ? clientData.sales_representative_data.type
                    : '-'}
                </Text>
              </Col>
            </Row>
            <Hr />
            <Row spaceBetween margtop={px2vw(10)}>
              <Col>
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Telefone
                </Text>
                <Text>
                  {clientData.sales_representative_data.whatsapp !== null
                    ? dddPhoneMask(
                        clientData.sales_representative_data.whatsapp
                      )
                    : '-'}
                </Text>
              </Col>
              <Col>
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Email
                </Text>
                <Text>
                  {clientData.sales_representative_data.email !== null
                    ? clientData.sales_representative_data.email
                    : '-'}
                </Text>
              </Col>
            </Row>
          </WhiteBox>
        </Row>

        {/* Store Data */}
        <Row margtop={px2vw(20)} justifyStart alignCenter>
          <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(15)}>
            Dados da loja
          </Text>
          {operationalEditAccess && (
            <EditButton onClick={() => setEditStoreData(true)}>
              <img
                src={editImg}
                alt="Edit Icon"
                width={px2vw(175)}
                height={px2vw(175)}
              />{' '}
              Editar
            </EditButton>
          )}
        </Row>
        <WhiteBox margtop={px2vw(10)}>
          <Row spaceBetween margbottom={px2vw(10)}>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Nome fantasia da loja
              </Text>
              <Text>
                {clientData.store_data.name !== null
                  ? clientData.store_data.name
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Categoria
              </Text>
              <Text>{categoryName}</Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Slug da loja
              </Text>
              <Text
                title="Clique para abrir o cardápio em uma nova aba"
                slug
                onClick={() => window.open(`https://${clientData.store_data.slug}${getWebMenuURL()}`, '_blank')}
              >
                {clientData.store_data.slug !== null
                  ? clientData.store_data.slug
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                CPF/CNPJ
              </Text>
              <Text>
                {clientData.store_data.cpf_cnpj !== null
                  ? cpfCnpjMask(clientData.store_data.cpf_cnpj)
                  : '-'}
              </Text>
            </Col>
          </Row>
          <Hr />
          <Row spaceBetween margtop={px2vw(10)} margbottom={px2vw(10)}>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                CEP
              </Text>
              <Text>
                {clientData.store_data.address.zipcode !== null
                  ? cepMask(clientData.store_data.address.zipcode)
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Endereço
              </Text>
              <Text>
                {clientData.store_data.address.street !== null
                  ? clientData.store_data.address.street
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                N°
              </Text>
              <Text>
                {clientData.store_data.address.number !== null
                  ? clientData.store_data.address.number
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Complemento
              </Text>
              <Text>
                {clientData.store_data.address.complement !== null
                  ? clientData.store_data.address.complement
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Bairro
              </Text>
              <Text>
                {clientData.store_data.address.neighborhood !== null
                  ? clientData.store_data.address.neighborhood
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Cidade
              </Text>
              <Text>
                {clientData.store_data.address.city !== null
                  ? clientData.store_data.address.city
                  : '-'}
              </Text>
            </Col>
          </Row>
          <Hr />
          <Row spaceBetween margtop={px2vw(10)} margbottom={px2vw(10)}>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Telefone 1
              </Text>
              <Text>
                {clientData.store_data.address.phone_1 !== null
                  ? dddPhoneMask(clientData.store_data.address.phone_1)
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Telefone 2
              </Text>
              <Text>
                {clientData.store_data.address.phone_2 !== null
                  ? dddPhoneMask(clientData.store_data.address.phone_2)
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Whatsapp
              </Text>
              <Text>
                {clientData.store_data.address.phone_whatsapp !== null
                  ? dddPhoneMask(clientData.store_data.address.phone_whatsapp)
                  : '-'}
              </Text>
            </Col>
            <Col width="auto">
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Data de início
              </Text>
              <Text>{dateMask(clientData.store_data.start_date)}</Text>
            </Col>
          </Row>
        </WhiteBox>
      </Col>
      {editResponsibleData && <EditResponsibleDataModal handler={handler} />}
      {editStoreData && <EditStoreDataModal handler={handler} />}
    </>
  );
}
