import moment from 'moment';
import { onlyNumbers } from './utils';

const numberFormart = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function currencyMask(value) {
  if (typeof value !== 'number') return value;
  return numberFormart.format(value);
}

export function amountMask(value) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);
    // 999,99
    return amount.replace(/(\d{1,3})(\d{2})/, 'R$ $1.$2');
  }
  return '';
}

export function dateMask(value) {
  let valueSliced = '-';

  if (value === null || value === undefined){
    return valueSliced;
  } else if (value.length > 10 && value !== null && value !== undefined) {
    valueSliced = value.slice(0, 10);

    const date = moment(valueSliced, 'YYYY-MM-DD', true).format('DD/MM/YYYY');
    return date;
  } 
}

export function dateTimeMask(value) {
  if (value !== null && value !== undefined) {
    const dateTime = onlyNumbers(value);

    // DD/MM/YYYY HH:MM
    return dateTime
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{2})(\d)/, '$1 $2:$3')
      .replace(/(:\d{2})\d+?$/, '$1');
  }
  return '';
}

export function timeMask(value) {
  if (value !== null && value !== undefined) {
    const time = onlyNumbers(value);
    // HH:MM
    return time.replace(/(\d{2})(\d)/, '$1:$2').replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cnpjMask(value) {
  if (value !== null && value !== undefined) {
    const cnpj = onlyNumbers(value);

    // 00.000.000/0000-00
    return cnpj
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cpfMask(value) {
  if (value !== null && value !== undefined) {
    const cpf = onlyNumbers(value);

    // 000.000.000-00
    return cpf
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cpfCnpjMask(value) {
  if (value !== null && value !== undefined) {
    const cpfCnpj = onlyNumbers(value);

    if (cpfCnpj.length === 11) {
      // 000.000.000-00
      return cpfCnpj
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    }
    // 00.000.000/0000-00
    return cpfCnpj
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cepMask(value) {
  if (value !== null && value !== undefined) {
    const cep = onlyNumbers(value);

    // 00.000-000
    return cep
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  }
  return '';
}

export function dddPhoneMask(value) {
  if (value !== null && value !== undefined) {
    let phone = onlyNumbers(value);

    if (phone.length >= 12) {
      phone = phone.substring(2, phone.length);
    }

    // 00 0000-0000
    if (phone.length === 10) {
      return phone
        .replace(/(\d{2})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    // 00 00000-0000
    return phone
      .replace(/(\d{2})(\d)/, '$1 $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function cardValidityMask(value) {
  if (value !== null && value !== undefined) {
    const cardValidity = onlyNumbers(value);

    // 00/00
    return cardValidity
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{2})\d+?$/, '$1');
  }
  return '';
}

export function phoneMask(value) {
  if (value !== null && value !== undefined) {
    let phone = onlyNumbers(value);

    if (phone.length >= 12) {
      phone = phone.substring(2, phone.length);
    }

    // 00 0000-0000
    if (phone.length <= 10) {
      return phone
        .replace(/(\d{2})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    return phone
      .replace(/(\d{2})(\d)/, '$1 $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function screenAmountMask(value) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);

    if (amount.length === 6) {
      // 9.999,99
      return amount.replace(/(\d{1,1})(\d{1,3})(\d{2})/, 'R$ $1.$2,$3');
    } else {
      // 999,99
      return amount.replace(/(\d{1,3})(\d{2})/, 'R$ $1,$2');
    }
  }
  return '';
}
