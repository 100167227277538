import { combineReducers } from 'redux';
// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session';

import auth from './auth/reducer';
import user from './user/reducer';
import reports from './reports/reducer';
import monthlyPaymentMetrics from './monthlyPaymentMetrics/reducer';
import clients from './clients/reducer';
import kuppiBoostValidation from './kuppiBoostValidation/reducer';
import massMessageGeneral from './massMessageGeneral/reducer';

const appReducer = combineReducers({
  auth,
  user,
  reports,
  monthlyPaymentMetrics,
  clients,
  kuppiBoostValidation,
  massMessageGeneral
});

const rootReducer = (state, action) => {
  if (action.type === '@auth/LOG_OUT') {
    storage.removeItem('persist:root');
    storage.removeItem('persist:kuppi-admin');

    state = undefined;
  }

  return appReducer(state, action);
};

export { rootReducer };