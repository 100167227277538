import styled from 'styled-components';
import px2vw from '../../utils/responsiveness/px2vw';

export const IconBox = styled.div`
  ${(props) => props.sorted && `transform: rotate(180deg);`};
  transition: transform 0.2s ease-in-out;

  position: relative;
  width: ${px2vw(15)};
  height: ${px2vw(15)};
  margin-left: 8px;
`;

export const ArrowIcon = styled.img.attrs({
  src: require('../../assets/images/arrow.svg')
})`
  position: absolute;
  width: 100%;
  height: 100%;
`;