import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  onboardingAndActivation: [],
  preImplementation: [],
  menuAndCoupons: [],
  orders: [],
  kuppiBoostOnboardingPending: [],
  kuppiBoostValidateLoading: false,
  subscriptions: [],
  kuppidoReport: [],
  kuppidoSubscriptions: [],
  initialProspecting: [],
  conversion: [],
  engagement: [],
};

export default function reports(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@reports/GET_ONBOARDING_AND_ACTIVATION': {
        draft.loading = true;
        break;
      }
      case '@reports/GET_PRE_IMPLEMENTATION': {
        draft.loading = true;
        break;
      }
      case '@reports/SET_PRE_IMPLEMENTATION': {
        draft.loading = false;
        draft.preImplementation = action.payload.data;
        break;
      }
      case '@reports/SET_ONBOARDING_AND_ACTIVATION': {
        draft.loading = false;
        draft.onboardingAndActivation = action.payload.data;
        break;
      }
      case '@reports/GET_MENU_AND_COUPONS': {
        draft.loading = true;
        break;
      }
      case '@reports/SET_MENU_AND_COUPONS': {
        draft.loading = false;
        draft.menuAndCoupons = action.payload.data;
        break;
      }
      case '@reports/GET_ORDERS': {
        draft.loading = true;
        break;
      }
      case '@reports/SET_ORDERS': {
        draft.loading = false;
        draft.orders = action.payload.data;
        break;
      }
      case '@reports/GET_KUPPI_BOOST_ONBOARDING_PENDING': {
        draft.loading = true;
        break;
      }
      case '@reports/SET_KUPPI_BOOST_ONBOARDING_PENDING': {
        draft.loading = false;
        draft.kuppiBoostOnboardingPending = action.payload.data;
        break;
      }
      case '@reports/CHECK_KUPPI_BOOST_PERMISSIONS_REQUEST': {
        draft.kuppiBoostValidateLoading = true;
        break;
      }
      case '@reports/CHECK_KUPPI_BOOST_PERMISSIONS_RESPONSE': {
        draft.kuppiBoostValidateLoading = false;
        break;
      }
      case '@reports/GET_SUBSCRIPTIONS': {
        draft.loading = true;
        break;
      }
      case '@reports/SET_SUBSCRIPTIONS': {
        draft.loading = false;
        draft.subscriptions = action.payload.data;
        break;
      }
      case '@reports/GET_KUPPIDO_SUBSCRIPTIONS': {
        draft.loading = true;
        break;
      }
      case '@reports/SET_KUPPIDO_SUBSCRIPTIONS': {
        draft.loading = false;
        draft.kuppidoSubscriptions = action.payload.data;
        break;
      }
      case '@reports/REQUEST_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@reports/GET_KUPPIDO_REPORTS': {
        draft.loading = true;
        break;
      }
      case '@reports/SET_KUPPIDO_REPORTS': {
        draft.loading = false;
        draft.kuppidoReport = action.payload.data;
        break;
      }
      case '@reports/SET_FORMAT_INITIAL_PROPSPECTING': {
        draft.loading = false;
        draft.initialProspecting = action.payload.data;
        break;
      }
      case '@reports/SET_FORMAT_CONVERSION': {
        draft.loading = false;
        draft.conversion = action.payload.data;
        break;
      }
      case '@reports/SET_FORMAT_ENGAGEMENT': {
        draft.loading = false;
        draft.engagement = action.payload.data;
        break;
      }
      case '@reports/END_ACTION': {
        draft.loading = false;
        draft.onboardingAndActivation = [];
        draft.menuAndCoupons = [];
        draft.orders = [];
        break;
      }
      default:
    }
  });
}
