import React from 'react';
import { useSelector } from 'react-redux';
import px2vw from '../../utils/responsiveness/px2vw';
import Modal from '../Modal';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import closeButtonImg from '../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../styles/grid';
import {
  CancelButton,
  CheckButton,
  ConfirmButton,
  FormBox,
  ImageContainer,
  InputData,
  InputTitle,
  SelectInput,
} from '../EditResponsibleDataModal/styles';
import { preto } from '../../styles/colors';
import { cpfCnpjMask } from '../../utils/masks';
import { LoadingBox } from '../../styles/common';
import { Spinner } from 'reactstrap';

export default function EditStoreDataModal({ handler }) {
  const { updateStoreDataLoading } = useSelector(
    (state) => state.clients
  );
  const clientData = useSelector((state) => state.clients.clientData);

  function handleCloseModal() {
    handler.setEditStoreData(false);
    handler.setStoreName(clientData.store_data.name);
    handler.setStoreCpfCnpj(clientData.store_data.cpf_cnpj);
    handler.setStoreCategoryId(clientData.store_data.category);
    handler.setStoreLatitude(clientData.store_data.address.latitude);
    handler.setStoreLongitude(clientData.store_data.address.longitude);
    handler.setAddressVisibleCheck(clientData.store_data.address.is_visible_on_web_menu);
  }

  return (
    <Modal
      isVisible={handler.editStoreData}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Alterar dados da Loja</ModalTitleText>
        <CloseButton onClick={handleCloseModal}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handleEditStoreDataSubmit}>
        <Col margtop={px2vw(20)}>
          <InputTitle>Nome Fantasia da loja</InputTitle>
          <InputData
            name="name"
            type="text"
            placeholder="Digite aqui o nome da loja"
            value={handler.storeName}
            onChange={(e) => handler.setStoreName(e.target.value)}
          />
        </Col>
        <Col margtop={px2vw(10)}>
          <InputTitle>Slug da loja</InputTitle>
          <InputData
            disabled
            name="slug"
            type="text"
            value={handler.storeSlug}
          />
        </Col>
        <Row>
          <Col margtop={px2vw(10)}>
            <InputTitle>CPF/CNPJ do estabelecimento</InputTitle>
            <InputData
              name="cpf_cnpj"
              type="text"
              placeholder="Digite aqui CPF ou CNPJ"
              width={px2vw(200)}
              value={cpfCnpjMask(handler.storeCpfCnpj)}
              onChange={(e) => handler.setStoreCpfCnpj(e.target.value)}
            />
          </Col>
          <Col margtop={px2vw(10)} margleft={px2vw(44)}>
            <InputTitle>Categoria</InputTitle>
            <SelectInput
              padright={px2vw(10)}
              padleft={px2vw(10)}
              height={px2vw(30)}
              width={px2vw(200)}
              name="categorySelect"
              placeholder="Defina a categoria"
              options={handler.clientCategories}
              value={handler.storeCategoryId}
              onChange={(e) => {
                handler.setStoreCategoryId(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col margtop={px2vw(10)}>
            <InputTitle>Latitude</InputTitle>
            <InputData
              name="latitude"
              type="text"
              placeholder="Digite aqui a latitude"
              width={px2vw(200)}
              value={handler.storeLatitude}
              onChange={(e) => handler.setStoreLatitude(e.target.value)}
            />
          </Col>
          <Col margtop={px2vw(10)} margleft={px2vw(44)}>
            <InputTitle>Longitude</InputTitle>
            <InputData
              name="longitude"
              type="text"
              placeholder="Digite aqui a longitude"
              width={px2vw(200)}
              value={handler.storeLongitude}
              onChange={(e) => handler.setStoreLongitude(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col margtop={px2vw(10)}>
            <InputTitle>Visibilidade do Endereço</InputTitle>
            <Row
              width="auto"
              height="auto"
              margtop={px2vw(8)}
              onClick={() => handler.setAddressVisibleCheck()}
            >
              <CheckButton margright={px2vw(5)}>
                <ImageContainer isChecked={handler.addressVisibleCheck}>
                  <img
                    src={require('../../assets/images/clients/checkbox@2x.png')}
                    width="100%"
                    height="100%"
                  />
                </ImageContainer>
              </CheckButton>
                {handler.addressVisibleText}
            </Row>
          </Col>
        </Row>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton onClick={handleCloseModal}>Cancelar</CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit">
              {updateStoreDataLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
}
