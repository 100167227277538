import styled from 'styled-components';
import px2vw from '../../utils/responsiveness/px2vw';
import {
  branco,
  preto,
  cinzaKuppi15,
  cinzaKuppi9,
  verdeKuppi2,
} from '../../styles/colors';

export const CardBox = styled.div`
  border: 1px solid ${cinzaKuppi15};
  cursor: pointer;
  display: flex;
  width: ${px2vw(276)};
  height: ${px2vw(115)};
  border-radius: 8px;
  margin-right: ${px2vw(15)};
`;

export const AdvertiserLogoImage = styled.img`
  border: solid 1px ${cinzaKuppi9};
  width: ${px2vw(55)};
  height: ${px2vw(55)};
  border-radius: ${px2vw(35)};
`;

export const Divider = styled.div`
  background-color: ${cinzaKuppi9};
  width: ${px2vw(1.3)};
  height: ${px2vw(80)};
  margin-left: ${px2vw(15)};
  margin-right: ${px2vw(15)};
  margin-top: ${px2vw(15)};
`;

export const AdvertiserNameText = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: ${preto};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 19ch;
  font-size: ${px2vw(13)};
`;

export const AdvertiserCategoryText = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 500;
  letter-spacing: 0.24px;
  color: ${preto};
  font-size: ${px2vw(9)};
`;

export const AdvertiserTimesText = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 400;
  letter-spacing: 0.24px;
  color: ${preto};
  font-size: ${px2vw(8)};
`;

export const ButtonGoToWebMenu = styled.div`
  background-color: ${verdeKuppi2};
  border-radius: 20px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.24px;
  text-align: center;
  color: ${branco};
  width: ${px2vw(147)};
  height: ${px2vw(20)};
  margin-top: ${px2vw(4)};
  padding-top: ${px2vw(2)};
  font-size: ${px2vw(10)};
`;
