import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../../../../../styles/common';
import { preto } from '../../../../../../styles/colors';
import { Col, Row } from '../../../../../../styles/grid';
import { CloseButton, ModalTitleText } from '../../../../../../components/Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../../../../../../components/EditResponsibleDataModal/styles';

import closeButtonImg from '../../../../../../assets/images/close_button/close_button.png';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import Modal from '../../../../../../components/Modal';

import { updatePixelsListRequest } from '../../../../../../store/modules/clients/actions';

export default function RemovePixelModal({ removePixel, onClose }) {
  const { updatePixelsListLoading, clientData, pixelOwnDomainData } = useSelector(
    (state) => state.clients
  );
  const [removeTextConfirmation, setRemoveTextConfirmation] = useState('');
  const dispatch = useDispatch();

  function handleRemovePixel() {
    const newPixelsList = [...pixelOwnDomainData?.facebook_pixels]
    newPixelsList.splice(removePixel?.idx, 1);
    dispatch(updatePixelsListRequest(clientData?.id, newPixelsList));
  }

  return (
    <Modal
      isVisible
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={onClose}
          disabled={updatePixelsListLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Remover Pixel</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          {`Tem certeza que deseja remover o Pixel: ${removePixel?.pixel} ?`}
        </InputTitle>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite 'remover pixel' para removê-lo
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="remove"
          type="text"
          placeholder="remover pixel"
          width={px2vw(400)}
          value={removeTextConfirmation}
          onChange={(e) => setRemoveTextConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (removeTextConfirmation === 'remover pixel' && removePixel?.pixel) {
                handleRemovePixel();                
              }
            }} 
            disabled={removeTextConfirmation !== 'remover pixel' || updatePixelsListLoading || !removePixel?.pixel}
          >
            {updatePixelsListLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : ('Remover')}
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={onClose}
            disabled={updatePixelsListLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
