import styled, { css, keyframes } from 'styled-components';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Row } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';
import {
  cinzaKuppi6,
  cinzaKuppi2,
  cinzaKuppi7,
  preto,
  branco,
  cinzaKuppi4,
  verdeKuppi1,
  verdeKuppi2
} from '../../../styles/colors';

const colorFadeIn = keyframes`
  0% {
  background-color: ${cinzaKuppi4};
  transform: scale(1.01);
}
  100% {
    background-color: ${verdeKuppi2};
    transform: initial;
  }
`;

export const Container = styled.div``;

export const Header = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 0;
  padding: ${(props) => props.padding};
  color: ${cinzaKuppi2};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(12)};
`;

export const HeaderButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: ${cinzaKuppi6};
  font-family: AvenirNextLTPro;
  font-weight: 700;

  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  ${(props) =>
    props.column && `flex-direction: column; align-items: flex-start;`}
  ${(props) => props.width && `width: ${props.width}`}
`;

export const SecundaryButton = styled.button`
  background-color: ${branco};
  width: ${px2vw(2180)};
  height: ${px2vw(35)};
  border: 2px solid ${preto};
  border-radius: ${px2vw(5)};
  padding: 0.3em;
  margin-left: ${(props) => props.mlAuto && 'auto'};

  :focus {
    background-color: ${branco} !important;
  }

  :hover {
    background-color: ${cinzaKuppi2};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};

  ${(props) =>
    props.disabled &&
    `
  border: 2px solid ${cinzaKuppi4};
  .button{
    color:${cinzaKuppi4};
  }

  `};
`;

export const SecundaryButtonText = styled.div`
  font-size: ${(props) => (props.size ? props.size : px2vw(17))};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const Content = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  ${(props) => props.width && `width: ${props.width}`}
  padding: 10px 0;
  padding: ${(props) => props.padding && props.padding};

  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-size: ${px2vw(12)};

  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 20ch;
  }
`;

export const TableRow = styled(Row)`
  :hover {
    background: ${cinzaKuppi2};
  }
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi2};
  height: 1px;
  width: 100%;
`;

export const TagType = styled.div`
  border-radius: ${px2vw(9)};
  background: ${(props) => (props.type === 'range' ? '#D0F4FF' : '#CFFECE')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${px2vw(10)};
  height: ${px2vw(14)};
  width: ${px2vw(65)};
  margin-bottom: ${px2vw(7)};
`;

export const PagButton = styled.button`
  width: ${px2vw(40)};
  height: ${px2vw(40)};
  border-radius: ${px2vw(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  :hover {
    ${(props) =>
      props.disabled
        ? `background: transparent !important; cursor: not-allowed !important`
        : `background: ${cinzaKuppi2} !important`};
  }
`;

export const PrevIcon = styled(AiOutlineLeft).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;
export const NextIcon = styled(AiOutlineRight).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;

export const ImageBox = styled.div`
  position: relative;
  max-width: 55px;
  max-height: 55px;
  width: 45px;
  height: 45px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  margin: 0 5px 0 10px;
`;
export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  border-radius: ${(props) => props.borderRadius};
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  border-radius: ${(props) => props.radius || px2vw(10)};
  font-size: ${(props) => props.size || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => props.fontWeight || '600'};
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${(props) => (props.height ? props.height : px2vw(44))};

  ${(props) =>
    props.complete
      ? css`
          animation: ${colorFadeIn} 0.7s forwards;
        `
      : `background-color: ${verdeKuppi2};`}

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 0px 1px 1.5px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;
