import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AccessValidate from '../../../utils/validations/AccessValidate';

import px2vw from '../../../utils/responsiveness/px2vw';

import { logout } from '../../../store/modules/auth/actions';

import { Col } from '../../../styles/grid';

import {
  LogoImage,
  LogoImageBox,
  OptionBox,
  OptionSeparator,
  OptionText,
  SelectedBar,
  LogoutButton,
  MenuIconBox,
  MenuIconImg,
  Container,
  Content,
} from './styles';

import {
  dashboard,
  dataClients,
  nearClients,
  districts,
  orders,
  massMessageGeneral,
  kuppiBoostOnboarding,
} from '../../../routes/routeMap';
import Accordion from '../../Accordion';

export default function SideBar({ option }) {
  const history = useHistory();
  const { access } = useSelector((state) => state.user);
  const [clientMenuOpenned, setClientMenuOpenned] = useState(false);
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(logout());
  }

  useEffect(() => {
    setClientMenuOpenned(
      option === 'data' ||
      option === 'district' ||
      option === 'orders' ||
      option === 'nearClients'
    );
  }, [option]);

  return (
    <Col>
      <LogoImageBox>
        <LogoImage
          src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
        />
      </LogoImageBox>
      <Container>
        <Content>
          <OptionBox
            optionactive={option === 'dashboard' ? 'true' : undefined}
            to={dashboard}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Dashboard"
                src={require('../../../assets/images/dashboard_icon/dashboard_icon@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Dashboard</OptionText>
            <SelectedBar optionActive={option === 'dashboard'} />
          </OptionBox>

          {(!AccessValidate(access, 'admin_reports', 'reports', null) ||
            AccessValidate(access, 'administrator', null, null)) && (
            <>
              <Accordion
                title="Clientes"
                selected={clientMenuOpenned}
                onClick={() => {
                  if (!clientMenuOpenned) {
                    history.push(dataClients);
                  }
                  setClientMenuOpenned(true);
                }}
                optionactive={clientMenuOpenned}
              >
                <OptionBox
                  optionactive={option === 'data' ? 'true' : undefined}
                  to={dataClients}
                >
                  <OptionText className="text">{'> Cadastro'}</OptionText>
                  <SelectedBar optionActive={option === 'data'} />
                </OptionBox>

                <OptionBox
                  optionactive={option === 'nearClients' ? 'true' : undefined}
                  to={nearClients}
                >
                  <OptionText
                    className="text"
                    margtop={px2vw(10)}
                    margbottom={px2vw(5)}
                  >
                    {'> Clientes Próximos'}
                  </OptionText>
                  <SelectedBar optionActive={option === 'nearClients'} />
                </OptionBox>

                <OptionBox
                  optionactive={option === 'district' ? 'true' : undefined}
                  to={districts}
                >
                  <OptionText className="text">{'> Bairros'}</OptionText>
                  <SelectedBar optionActive={option === 'district'} />
                </OptionBox>

                <OptionBox
                  optionactive={option === 'orders' ? 'true' : undefined}
                  to={orders}
                >
                  <OptionText className="text">{'> Pedidos'}</OptionText>
                  <SelectedBar optionActive={option === 'orders'} />
                </OptionBox>
              </Accordion>

              <OptionBox
                  optionactive={option === 'massMessageGeneral' ? 'true' : undefined}
                  to={massMessageGeneral}
                >
                  <MenuIconBox>
                    <MenuIconImg
                      className="icon"
                      alt="Disparo em Massa"
                      src={require('../../../assets/images/mass_message/mass_message@2x.png')}
                    />
                  </MenuIconBox>
                  <OptionText className="text">Disparo em Massa</OptionText>
                  <SelectedBar optionActive={option === 'massMessageGeneral'} />
                </OptionBox>

                <OptionBox
                  optionactive={
                    option === 'kuppiBoostOnboarding' ? 'true' : undefined
                  }
                  to={kuppiBoostOnboarding}
                >
                  <MenuIconBox>
                    <MenuIconImg
                      className="icon"
                      alt="Kuppi Boost Onboarding"
                      src={require('../../../assets/images/reports/reports@2x.png')}
                    />
                  </MenuIconBox>
                  <OptionText className="text">Kuppi Boost Onboarding</OptionText>

                  <SelectedBar optionActive={option === 'kuppiBoostOnboarding'} />
                </OptionBox>
            </>
          )}
          <OptionSeparator />
          <LogoutButton onClick={() => handleLogout()}>
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Logout"
                src={require('../../../assets/images/logout/logout@2x.png')}
              />
            </MenuIconBox>
            <OptionText
              className="text"
              margleft={px2vw(30)}
              margtop={px2vw(4)}
            >
              Sair
            </OptionText>
          </LogoutButton>
        </Content>
      </Container>
    </Col>
  );
}
