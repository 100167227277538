import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../../../styles/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';

import { Col, Row } from '../../../styles/grid';
import { Title } from '../styles';
import { SearchButton, SearchInput, WhiteBox, DateDiv, TextRegular } from './styles';
import { ContentPage } from '../../_layouts/styles/common';
import { LoadingBox } from '../../../styles/common';
import { preto } from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

import DataClientsTable from '../../../components/Tables/DataClientsTable';
import AdvertiserDetails from './AdvertiserDetails';
import {
  clearClientDataSuccess,
  clearSuccess,
  getClientsRequest,
} from '../../../store/modules/clients/actions';


export default function DataClients() {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients.clients);

  const {
    clientsLoading,
    clientDataLoading,
    clientDataSuccess,
    clientDataError,
  } = useSelector((state) => state.clients);

  const [advertiserDetails, setAdvertiserDetails] = useState(false);
  const [advertiserId, setAdvertiserId] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  registerLocale('pt-BR', ptBR);

  const handler = {
    advertiserId: advertiserId,
    setAdvertiserDetails: (val) => setAdvertiserDetails(val),
    setAdvertiserId: (id) => setAdvertiserId(id),
    setSearchInputValue: (val) => setSearchInputValue(val),
    setStartDate: (val) => setStartDate(val),
    setEndDate: (val) => setEndDate(val)
  };

  function handleGetClients(criteria) {
    if (startDate !== null || endDate !== null) {
      if (startDate == null || endDate == null) {
        toast.error('Informe as datas de criação corretamente.', {
          autoClose: 6000,
        });
  
        return false;
      }
      
      // Start Date - DDMMAAAA
      const startDateDay = startDate.getDate().toString();
      const startDateMonth = (startDate.getMonth() + 1).toString();
      const startDateYear = startDate.getFullYear()

      const newStartDate = new Date(`${startDateYear}-${startDateMonth}-${startDateDay}`);
      try {
        newStartDate.toISOString();
      } catch (e) {
        toast.error('Data de início inválida.', {
          autoClose: 6000,
        });

        return false;
      }

      // End Date - DDMMAAAA
      const endDateDay = endDate.getDate().toString();
      const endDateMonth = (endDate.getMonth() + 1).toString();
      const endDateYear = endDate.getFullYear()

      const newEndDate = new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`);
      try {
        newEndDate.toISOString();
      } catch (e) {
        toast.error('Data de fim inválida.', {
          autoClose: 6000,
        });

        return false;
      }

      if (newEndDate < newStartDate) {
        toast.error('Data de fim não pode ser menor que a data de início.', {
          autoClose: 6000,
        });
  
        return false;
      }

      if (newEndDate > new Date()) {
        toast.error('Data de fim não pode ser maior que a data atual.', {
          autoClose: 6000,
        });
  
        return false;
      }

      const diffStartDates = moment(newStartDate)
      const diffEndDates = moment(newEndDate);
      if (diffEndDates.diff(diffStartDates, 'days') > 7) {
        toast.error('Intervalo de pesquisa não pode ser maior que 7 dias.', {
          autoClose: 6000,
        });

        return false;
      }
    }

    dispatch(getClientsRequest(criteria, startDate, endDate));
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter' && (searchInputValue !== '' || (startDate !== null && endDate !== null))) {
      event.preventDefault();
      handleGetClients(searchInputValue)
    }
  };

  const Content = (
    <ContentPage>
      <Row height="auto" alignStart justifyStart>
        <Title>Cadastro</Title>
      </Row>
      <WhiteBox justifyStart height="auto" margtop={px2vw(30)}>
        <Row height="auto" spaceBetween alignCenter>
          <Title boxTitle>
            Buscar
          </Title>
          <Row width="auto">
            <Row
              width="auto"
              margtop={px2vw(6)}
              margright={px2vw(8)}
            >
              <TextRegular>Criado em: </TextRegular>
            </Row>
            <Col width="auto" alignStart>
              <DateDiv>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onFocus={() => setStartDate(null)}
                  onChange={(date) => setStartDate(date)}
                  locale="pt-BR"
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="date"
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </DateDiv>
            </Col>
            <Row
              width="auto"
              margtop={px2vw(6)}
              margleft={px2vw(8)}
              margright={px2vw(8)}
            >
              <TextRegular>até</TextRegular>
            </Row>
            <Col width="auto" alignStart>
              <DateDiv>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onFocus={() => setEndDate(null)}
                  onChange={(date) => setEndDate(date)}
                  locale="pt-BR"
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="date"
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </DateDiv>
            </Col>
          </Row>
          <Row width="auto">
            <SearchInput
              id="searchClient"
              name="searchClient"
              type="text"
              placeholder="Digite o Nome, ID ou CPF/ CNPJ do cliente"
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
              height={px2vw(30)}
              width={px2vw(350)}
              padleft={px2vw(10)}
              onKeyPress={handleKeyPress} 
            />
            <SearchButton
              onClick={() => handleGetClients(searchInputValue)}
              disabled={(searchInputValue === '' && (startDate == null || endDate == null))}
            >
              {clientsLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Pesquisar'
              )}
            </SearchButton>
          </Row>
        </Row>

        <Row width="auto">
          <DataClientsTable data={clients} handler={handler} />
        </Row>
      </WhiteBox>
    </ContentPage>
  );

  useEffect(() => {
    dispatch(clearSuccess())
  },[]);

  useEffect(() => {
    if (clientDataSuccess) {
      setAdvertiserDetails(true);
      dispatch(clearClientDataSuccess());
    }
  }, [clientDataSuccess]);

  useEffect(() => {
    if (clientDataError) {
      setAdvertiserDetails(false);
    }
  }, [clientDataError]);

  if (clientDataLoading || advertiserDetails) {
    return (
      <>
        {clientDataLoading ? (
          <LoadingBox dataClientsLoading>
            <Spinner
              style={{
                width: '1.2rem',
                height: '1.2rem',
                color: preto,
              }}
            />
          </LoadingBox>
        ) : (
          <AdvertiserDetails handler={handler} />
        )}
      </>
    );
  } else {
    return Content;
  }
}
