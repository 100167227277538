import styled from 'styled-components';
import { cinzaKuppi6, preto } from '../../styles/colors';
import px2vw from '../../utils/responsiveness/px2vw';

export const Title = styled.div`
  color: ${preto};
  font-size: ${px2vw(95)};
`;

export const Description = styled.div`
  color: ${preto};
  font-size: ${px2vw(18)};
`;

export const Description2 = styled.div`
  color: ${cinzaKuppi6};
  font-size: ${px2vw(14)};
  margin-top: ${px2vw(10)};
`;
