import React from 'react';
import { useSelector } from 'react-redux';
import px2vw from '../../utils/responsiveness/px2vw';
import Modal from '../Modal';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import closeButtonImg from '../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../styles/grid';
import {
  CancelButton,
  ConfirmButton,
  FormBox,
  InputTitle, 
} from '../EditResponsibleDataModal/styles';
import { preto } from '../../styles/colors';
import { LoadingBox } from '../../styles/common';
import { Spinner } from 'reactstrap';

export default function ConfirmPaymentReversalModal({ handler }) {
  const { paymentReversalLoading } = useSelector((state) => state.clients);

  function handleCloseModal() {
    handler.setConfirmPaymentReversal();
  }

  return (
    <Modal
      isVisible={handler.confirmPaymentReversal}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Confirmar Estorno de Pagamento</ModalTitleText>
        <CloseButton onClick={handleCloseModal}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handlePaymentReversalSubmit}>
        <Row margtop={px2vw(10)} justifyStart>
          <InputTitle fontweight="normal">
            Ao confirmar, será estornado o valor de investimento para a campanha ID:{' '}
            {handler.campaignId}
          </InputTitle>
        </Row>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton onClick={handleCloseModal}>Cancelar</CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit">
              {paymentReversalLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
}
