import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { Col } from '../../styles/grid';

import { Content, LoadingBox } from '../_layouts/styles/common';

import { preto } from '../../styles/colors';

import {
  LoginLeft,
  LoginRight,
  LoginInputBox,
  LoginInputLabel,
  LoginInput,
  LoginButton,
  LoginButtonText,
  SeePasswordImage,
  LoginBox,
  SeePasswordButton,
  FormLogin,
  LogoLeftImage,
} from './styles';

import {
  loginRequest,
} from '../../store/modules/auth/actions';

export default function Login() {
  const urlParams = new URLSearchParams(useLocation().search);
  const error = urlParams.get('erro');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error === 'sessao-invalida') {
      toast.error('Sua sessão está inválida. Faça login novamente', {
        autoClose: false,
      });
    }
  }, [error])

  const handleSubmit = ({ email, password }) => {
    dispatch(loginRequest(email, password));
  };

  return (
    <Content>
      <LoginLeft>
        <LogoLeftImage
          src={require('../../assets/images/logo_kuppi/logo_Login_screen@2x.png')}
        />
      </LoginLeft>
      {/* Form Login */}
      <LoginRight>
        <FormLogin onSubmit={handleSubmit}>
          <LoginInputBox>
            <Col height="auto" width="80%">
              <LoginInputLabel>E-mail</LoginInputLabel>
            </Col>
            <LoginBox>
              <LoginInput
                name="email"
                type="email"
                placeholder="nome@email.com.br"
              />
            </LoginBox>
          </LoginInputBox>
          <LoginInputBox margtop="10px">
            <Col height="auto" width="80%">
              <LoginInputLabel>Senha</LoginInputLabel>
            </Col>
            <LoginBox>
              <LoginInput
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Insira sua senha"
              />
              <SeePasswordButton
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                <SeePasswordImage
                  src={require(passwordVisible
                    ? '../../assets/images/eye-outline/eye-outline.png'
                    : '../../assets/images/eye-outline/eye-off-outline.png')}
                />
              </SeePasswordButton>
            </LoginBox>
          </LoginInputBox>
          <LoginBox>
            <LoginButton type="submit" disabled={loading}>
              {loading ? (
                <LoadingBox>
                  <Spinner
                    style={{ width: '1.8rem', height: '1.8rem', color: preto }}
                  />{' '}
                </LoadingBox>
              ) : (
                <LoginButtonText>Entrar</LoginButtonText>
              )}
            </LoginButton>
          </LoginBox>
        </FormLogin>
      </LoginRight>
    </Content>
  );
}
