import moment from 'moment';

export const emailRegex = /^[a-zA-Z0-9._-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function onlyNumbers(value) {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    return number.replace(/\D/g, '');
  }
  return '';
}

export function onlyNumbersAmount(value) {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    return number.replace(/R\$ /g, '');
  }
  return '';
}

export function getURL(urlType) {
  const reactAppEnv = process.env.REACT_APP_ENV_ALL_API;
  const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API;
  const reactAppEnvAdmin = process.env.REACT_APP_ENV_ADMIN_API;

  const baseAPIsUrlsAdmin = {
    production: 'https://api.kuppi.com.br/admin',
    staging: 'https://stg-api.kuppi.com.br/admin',
    development: 'https://dev-api.kuppi.com.br/admin',
    qa: 'https://qa-api.kuppi.com.br/admin',
    local: 'http://localhost:3333/admin',
  };

  const baseAPIsUrlsGlobal = {
    production: 'https://api.kuppi.com.br/global',
    staging: 'https://stg-api.kuppi.com.br/global',
    development: 'https://dev-api.kuppi.com.br/global',
    qa: 'https://qa-api.kuppi.com.br/global',
    local: 'http://localhost:3334/global',
  };
  
  const baseUrlsAnunciante = {
    production: 'https://portal.kuppi.com.br/',
    staging: 'https://stg-portal.kuppi.com.br/',
    qa: 'https://qa-portal.kuppi.com.br/',
    development: 'https://dev-portal.kuppi.com.br/',
    local: 'http://localhost:3000/',
  };

  if (urlType === 'global_api') {
    return baseAPIsUrlsGlobal[reactAppEnv || reactAppEnvGlobal];
  }
  if (urlType === 'admin_api') {
    return baseAPIsUrlsAdmin[reactAppEnv || reactAppEnvAdmin];
  }
  if (urlType === 'anunciante') {
    return baseUrlsAnunciante[reactAppEnv || reactAppEnvAdmin];
  }

  return 'invalid urlType';
}

export function getWebMenuURL() {
  const reactAppEnv = process.env.REACT_APP_ENV_ALL_API;
  const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API;
  const reactAppEnvAdmin = process.env.REACT_APP_ENV_ADMIN_API;

  const baseUrlsWebMenu = {
    production: '.kuppi.app',
    staging: '.stg.kuppi.app',
    development: '.dev.kuppi.app',
    qa: '.qa.kuppi.app',
    local: '.localhost:3000',
  };

  return baseUrlsWebMenu[reactAppEnv || reactAppEnvGlobal || reactAppEnvAdmin];
}


export function definePlanName(
  startValidity,
  finishValidity,
  isFree,
  planPeriod,
  planName,
  isFirstSteps = false
) {
  let definedPlanName = '-';

  if (!isFree) {
    definedPlanName = planName;
  } else {
    const definedPlanPeriod = definePlanPeriodBasedValidity(
      startValidity,
      finishValidity,
      isFree,
      planPeriod
    );

    if (definedPlanPeriod.includes('dias')) {
      definedPlanName = `${definedPlanPeriod} grátis`;
    } else if (definedPlanPeriod.includes('mês')) {
      if (isFirstSteps) {
        definedPlanName = `um mês grátis`;
      } else {
        definedPlanName = `Mês grátis`;
      }
    } else {
      definedPlanName = `${definedPlanPeriod} gratuitos`;
    }
  }

  return definedPlanName;
}

export function definePlanPeriodBasedValidity(
  startValidity,
  finishValidity,
  isFree,
  planPeriod
) {
  let definedPlanPeriod = '-';

  if (!isFree) {
    if (planPeriod === 1) {
      definedPlanPeriod = `${planPeriod} mês`;
    } else {
      definedPlanPeriod = `${planPeriod} meses`;
    }
  } else {
    const finishValidityArray = finishValidity.split('/');
    const finishValidityDate = moment(
      new Date(
        `${finishValidityArray[2]}-${finishValidityArray[1]}-${finishValidityArray[0]}T00:00:00`
      )
    );
    const startValidityArray = startValidity.split('/');
    const startValidityDate = moment(
      new Date(
        `${startValidityArray[2]}-${startValidityArray[1]}-${startValidityArray[0]}T00:00:00`
      )
    );

    definedPlanPeriod = finishValidityDate.diff(startValidityDate, 'days');

    if (definedPlanPeriod < 30) {
      definedPlanPeriod = `${definedPlanPeriod} dias`;
    } else if (definedPlanPeriod === 30) {
      definedPlanPeriod = `1 mês`;
    } else {
      definedPlanPeriod = `${Math.round(definedPlanPeriod / 30)} meses`;
    }
  }

  return definedPlanPeriod;
}

export function formatCurrencyBRL(value, options) {
  const strValue = value === Infinity ? 0 : value;
  const maximumFractionDigits = options.maximumFractionDigits || 2;
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    maximumFractionDigits,
    ...options,
  });
  return formatter.format(strValue);
}

export function only2DecimalPlaces(value) {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    const numberParts = number.split('.');
    if (numberParts.length > 1) {
      return numberParts[0] + '.' + numberParts[1].substring(0, 2);
    }
    return numberParts[0];
  }
  return '0';
}
