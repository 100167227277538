import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import reports from './reports/sagas';
import monthlyPaymentMetrics from './monthlyPaymentMetrics/sagas';
import clients from './clients/sagas';
import kuppiBoostValidation from './kuppiBoostValidation/sagas';
import massMessageGeneral from './massMessageGeneral/sagas';

export default function* rootSaga() {
  return yield all([auth, user, reports, monthlyPaymentMetrics, clients, kuppiBoostValidation, massMessageGeneral]);
}
