import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Dashboard from '../pages/Dashboard';
import MonthlyPaymentsMetrics from '../pages/MonthlyPaymentsMetrics';
import DataClients from '../pages/Clients/DataClients';
import Districts from '../pages/Clients/Districts';
import Orders from '../pages/Clients/Orders';
import NearClients from '../pages/Clients/NearClients';
import MassMessageGeneral from '../pages/MassMessageGeneral';
import KuppiBoostValidation from '../pages/KuppiBoostValidation';
// import AdvertiserChurn from '../pages/AdvertiserChurn';

import {
  login,
  dashboard,
  kuppiBoostOnboarding,
  monthPaymentsMetrics,
  dataClients,
  districts,
  orders,
  nearClients,
  massMessageGeneral,
  // churn,
} from './routeMap';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={login} component={Login} noLayout />

        <Route
          path={dashboard}
          option="dashboard"
          component={Dashboard}
          isPrivate
        />

        <Route path={dataClients} option="data" component={DataClients} isPrivate />

        <Route path={districts} option="district" component={Districts} isPrivate />

        <Route path={orders} option="orders" component={Orders} isPrivate />

        <Route path={nearClients} option="nearClients" component={NearClients} isPrivate />

        <Route path={massMessageGeneral} option="massMessageGeneral" component={MassMessageGeneral} isPrivate />

        <Route
          path={kuppiBoostOnboarding}
          option="kuppiBoostOnboarding"
          component={KuppiBoostValidation}
          isPrivate
        />

        <Route
          path={monthPaymentsMetrics}
          component={MonthlyPaymentsMetrics}
          noLayout
        />

        {/* <Route path={churn} option="churn" component={AdvertiserChurn} isPrivate /> */}

        <Route path="/" component={Page404} noLayout />
        <Route path="/" component={Login} noLayout />
      </Switch>
    </BrowserRouter>
  );
}
