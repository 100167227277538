import React from 'react';

import px2vw from '../../utils/responsiveness/px2vw';

import AstronautGuy from '../../components/AstronautGuy';

import { Col, Row } from '../../styles/grid';
import { Title, Description, Description2 } from './styles';

export default function Page404() {
  return (
    <Row alignCenter justifyCenter>
      <Row width={px2vw(350)} height="auto">
        <AstronautGuy />
      </Row>
      <Col width="auto">
        <Title>404</Title>
        <Col height="auto">
          <Description>
            Oops! Página não encontrada.
          </Description>
          <Description2>
            A página que você procura não existe.
          </Description2>
        </Col>
      </Col>
    </Row>
  );
}
