import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { useSelector } from 'react-redux';

import moment from 'moment';

import px2vw from '../../../utils/responsiveness/px2vw';
import { screenAmountMask } from '../../../utils/masks';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
} from './styles';
import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TablePerPage from '../../Tables/TablePerPage';
import { Col, Row } from '../../../styles/grid';

export default function BonusHistoricTable() {
  const { massMessageBonusData } = useSelector((state) => state.clients);
  const [rows, setRows] = useState(massMessageBonusData);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('id');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(massMessageBonusData);
  }, [massMessageBonusData]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  const handleGetBonusTypeText = useCallback(
    (bonusType) => {
      switch (bonusType) {
        case 'challenge_bonus':
          return 'Bônus por completar o desafio da ativação';
        case 'mgm_bonus':
          return 'Bônus por indicação';
        case 'recovery_bonus':
          return 'Bônus para campanhas de recuperacao de churns, trials, etc';
        case 'cashback_bonus':
          return 'Bônus por ter feito um disparo pagando R$50 ou mais';
        case 'signature_bonus':
          return 'Bônus concedido para novos cadastros';
        case 'credit_bonus':
          return 'Bônus para casos em que queiramos dar um agrado ao cliente devido a um problema, instabilidade, demora, etc';
      }
    },
    [massMessageBonusData]
  );

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  return (
    <Col padding="0 15px" alignCenter justifyCenter>
      <Row justifyStart padding="0 10px 0 10px">
        <Header width={px2vw(120)} onClick={() => handleSort('created_at')}>
          <HeaderButton>
            Data
            <SvgBox sorted={sortedCol === 'created_at'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(700)} onClick={() => handleSort('bonus_type')}>
          <HeaderButton>
            Tipo
            <SvgBox sorted={sortedCol === 'bonus_type'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('budget')}>
          <HeaderButton column>
            <div>
              Valor
              <SvgBox sorted={sortedCol === 'budget'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </div>
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((item, index) =>
          structure[currentPage] &&
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 ? (
            <Fragment key={item.id}>
              <TableRow
                alignCenter
                justifyStart
                padding="0 10px 0 10px"
                minheight={px2vw(35)}
              >
                <Content width={px2vw(120)}>
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </Content>
                <Content width={px2vw(700)}>
                  {handleGetBonusTypeText(
                    item.bonus_type
                  )}
                </Content>
                <Content width={px2vw(120)}>
                  {screenAmountMask(item.budget.toFixed(2))}
                </Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Row padding="15px 0 10px 0">Nenhum bônus no momento.</Row>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
