export const branco = '#FFFFFF';
export const preto = '#000000';
export const preto2 = '#3E393E';
export const verdeKuppi1 = '#2BC48A';
export const verdeKuppi2 = '#26AB81';
export const verdeKuppi3 = '#2FFE60';
export const verdeKuppi4 = '#6EC7AB';
export const verdeKuppi5 = '#2CFE89';
export const verdeKuppi6 = '#057940';
export const verdeKuppi7 = '#7dfe9b';
export const verdeKuppi8 = '#2d3748';
export const verdeKuppi9 = '#3CB371';
export const verdeKuppi10 = '#20FE53';
export const verdeKuppi11 = `${verdeKuppi7}99`;
export const verdeKuppi12 = `${verdeKuppi4}35`;
export const verdeKuppi13 = '#8AF2A3';
export const verdeKuppi14 = '#8EF6A7';
export const verdeKuppi15 = '#BDF3FF';
export const verdeKuppi16 = '#2FFE5F';
export const verdeKuppi17 = '#04DC35';
export const verdeKuppi18 = '#00FF19';
export const verdeKuppi19 = '#97FEAF';
export const verdeKuppi20 = '#1BC000';
export const verdeKuppi21 = '#C8EADF';
export const verdeKuppi22 = '#CFFECE';
export const cinzaKuppi1 = '#F6F7FB';
export const cinzaKuppi2 = '#EFEFEF';
export const cinzaKuppi3 = '#A3A3A3';
export const cinzaKuppi4 = '#DBDBDB';
export const cinzaKuppi5 = '#707070';
export const cinzaKuppi6 = '#383539';
export const cinzaKuppi7 = '#9B999B';
export const cinzaKuppi8 = '#F2F2F2';
export const cinzaKuppi9 = '#DDDDDD';
export const cinzaKuppi10 = '#3E393E';
export const cinzaKuppi11 = '#C4C4C4';
export const cinzaKuppi12 = '#F8F9FB';
export const cinzaKuppi13 = '#E0E0E0';
export const cinzaKuppi14 = '#6C798F';
export const cinzaKuppi15 = '#B6B6B6';
export const amareloKuppi1 = '#FBD271';
export const amareloKuppi2 = '#F9AE00';
export const amareloKuppi3 = '#FEFCBF';
export const amareloKuppi4 = '#975A16';
export const amareloKuppi5 = '#FFC700';
export const amareloKuppi6 = '#FEEE2C';
export const amareloKuppi7 = '#FEF5AD';
export const marromKuppi1 = '#B25A16';
export const vermelhoKuppi1 = '#FF4343';
export const vermelhoKuppi2 = '#FF6C6C';
export const vermelhoKuppi3 = '#A60303';
export const vermelhoKuppi4 = '#FD0404';
export const vermelhoKuppi5 = '#FE5C5C';
export const vermelhoKuppi6 = '#CA0201';
export const laranjaKuppi1 = '#F48400';
export const laranjaKuppi2 = '#F94F00';
export const laranjaKuppi3 = '#FF6A00';
export const roxoKuppi1 = '#A985B9';
export const roxoKuppi2 = '#652581';
export const rosaKuppi1 = `${vermelhoKuppi1}60`;
export const azulKuppi1 = '#0071CE';
export const azulKuppi2 = '#007BC3';
export const azulKuppi3 = '#BDF3FF';
export const azulKuppi4 = '#07396a';
export const azulKuppi5 = '#004DC0';
export const azulKuppi6 = '#0085FF';
export const azulKuppi7 = '#1E80DC';
export const azulKuppi8 = '#D0F4FF';
