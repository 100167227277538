import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../Table';

import px2vw from '../../../utils/responsiveness/px2vw';

const columns = [
  {
    field: 'advertiser_info_id',
    title: 'ID',
    width: px2vw(50),
    render: ({ id }) => id,
  },
  {
    field: 'name',
    title: 'Nome',
    width: px2vw(350),
    render: ({ name }) => name,
  },
];

function MassMessageUnlimitedPackageTable({ data }) {
  return (
    <Table
      data={data}
      columns={columns}
      clientsType="MassMessageUnlimitedPackage"
      handler={{}}
    />
  );
}

MassMessageUnlimitedPackageTable.propTypes = {
  data: PropTypes.array,
};

export default MassMessageUnlimitedPackageTable;
