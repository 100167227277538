import React from 'react';
import { useSelector } from 'react-redux';
import px2vw from '../../utils/responsiveness/px2vw';
import Modal from '../Modal';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import closeButtonImg from '../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../styles/grid';
import {
  CancelButton,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';
import { preto } from '../../styles/colors';
import { LoadingBox } from '../../styles/common';
import { Spinner } from 'reactstrap';

export default function ManualConciliationModal({ handler }) {
  const { manualConciliationLoading } = useSelector((state) => state.clients);

  function handleCloseModal() {
    handler.setManualConciliation();
    handler.setPaymentId('');
  }

  return (
    <Modal
      isVisible={handler.manualConciliation}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Conciliação Manual de Pagamento</ModalTitleText>
        <CloseButton onClick={handleCloseModal}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handleManualConciliationSubmit}>
        <Row margtop={px2vw(10)} justifyStart>
          <InputTitle fontweight="normal">
            Ao confirmar, será atualizado o pagamento para a assinatura ID:{' '}
            {handler.manualConciliationSubscriptionId}
          </InputTitle>
        </Row>
        <Col margtop={px2vw(10)}>
          <InputTitle>Informe o ID da NOVA transação da Pagar.Me gerada após o reprocessamento</InputTitle>
          <InputData
            name="payment_id"
            type="text"
            placeholder="Digite aqui o id do pagamento"
            value={handler.paymentId}
            onChange={(e) => handler.setPaymentId(e.target.value)}
          />
        </Col>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton onClick={handleCloseModal}>Cancelar</CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit">
              {manualConciliationLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
}
