export default function AccessValidate(
  accessData,
  role,
  permissionRole,
  permissionUser,
  inRouter
) {
  let successRoleValidate = false;
  let successRolePermissionValidate = false;
  let successUserPermissionValidate = false;

  const Roles = [];
  const RolePermissions = [];
  const UserPermissions = [];
  let isAdmin = false;

  if (role) {
    accessData.roles.forEach((r) => {
      if (r.slug === 'administrator') {
        isAdmin = true;
      }

      Roles.push(r.slug);

      if (permissionRole) {
        r.permissions.forEach((p) => {
          RolePermissions.push(`${r.slug}_${p.slug}`);
        });
      }
    });

    successRoleValidate = Roles.includes(role);
    successRolePermissionValidate = RolePermissions.includes(
      `${role}_${permissionRole}`
    );
  }

  if (isAdmin) {
    return true;
  }

  if (permissionUser) {
    accessData.permissions.forEach((pu) => {
      UserPermissions.push(pu.slug);
    });

    successUserPermissionValidate = UserPermissions.includes(permissionUser);
  }

  if (inRouter) {
    if (role && permissionRole && permissionUser) {
      return (
        successRoleValidate &&
        successRolePermissionValidate &&
        successUserPermissionValidate
      );
    }

    if (role && permissionRole && !permissionUser) {
      return successRoleValidate && successRolePermissionValidate;
    }

    if (role && !permissionRole && permissionUser) {
      return successRoleValidate && successUserPermissionValidate;
    }

    if (role && !permissionRole && !permissionUser) {
      return successRoleValidate;
    }

    if (!role && permissionRole && !permissionUser) {
      return successRolePermissionValidate;
    }

    if (!role && !permissionRole && permissionUser) {
      return successUserPermissionValidate;
    }

    if (!role && !permissionRole && !permissionUser) {
      return true;
    }
  }

  if (!inRouter) {
    if (role && permissionRole && permissionUser) {
      return (
        successRoleValidate &&
        successRolePermissionValidate &&
        successUserPermissionValidate
      );
    }

    if (role && permissionRole && !permissionUser) {
      return successRoleValidate && successRolePermissionValidate;
    }

    if (role && !permissionRole && permissionUser) {
      return successRoleValidate && successUserPermissionValidate;
    }

    if (role && !permissionRole && !permissionUser) {
      return successRoleValidate;
    }

    if (!role && permissionRole && !permissionUser) {
      return successRolePermissionValidate;
    }

    if (!role && !permissionRole && permissionUser) {
      return successUserPermissionValidate;
    }

    if (!role && !permissionRole && !permissionUser) {
      return false;
    }
  }
}
