import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cinzaKuppi4 } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';

import { MenuBox, Tab, TabBox, TabsBox } from './styles';
import px2vw from '../../utils/responsiveness/px2vw';
import AdsTable from './AdsTable';
import PaymentHistoricTable from './PaymentHistoricTable';
import BonusHistoricTable from './BonusHistoricTable';

export default function MassMessageClientData() {
  const [selectedTab, setSelectedTab] = useState('tabCampaigns');

  const handleTabSelected = (newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <MenuBox>
      <Row
        height="auto"
        width="100%"
        className="pt-3 pb-4 pr-3"
        justifyStart
        alignCenter
        borderbottom={`2px solid ${cinzaKuppi4}`}
      >
        <TabsBox>
          <TabBox margL={px2vw(35)}>
            <Tab
              selected={selectedTab === 'tabCampaigns'}
              onClick={() => handleTabSelected('tabCampaigns')}
            >
              Todas as Campanhas
            </Tab>
          </TabBox>
          <TabBox margL={px2vw(35)}>
            <Tab
              selected={selectedTab === 'tabHistoricPayment'}
              onClick={() => handleTabSelected('tabHistoricPayment')}
            >
              Histórico de Pagamento
            </Tab>
          </TabBox>
          <TabBox margL={px2vw(35)}>
            <Tab
              selected={selectedTab === 'tabHistoricBonus'}
              onClick={() => handleTabSelected('tabHistoricBonus')}
            >
              Histórico de Bônus
            </Tab>
          </TabBox>
        </TabsBox>
      </Row>
      <Col
        className={
          selectedTab === 'tabHistoricPayment' ? 'mb-auto pb-3' : 'my-auto pl-3 pb-3'
        }
        alignCenter
      >
        {selectedTab === 'tabCampaigns' && (
          <AdsTable />
        )}
        {selectedTab === 'tabHistoricPayment' && (
          <PaymentHistoricTable />
        )}
        {selectedTab === 'tabHistoricBonus' && (
          <BonusHistoricTable />
        )}
      </Col>
    </MenuBox>     
  );
}
