import React from 'react';
import px2vw from '../../utils/responsiveness/px2vw';
import { getWebMenuURL } from '../../utils/utils';
import { Col } from '../../styles/grid';

import {
  CardBox,
  AdvertiserLogoImage,
  Divider,
  AdvertiserNameText,
  AdvertiserCategoryText,
  AdvertiserTimesText,
  ButtonGoToWebMenu,
} from './styles';
import { cpfCnpjMask } from '../../utils/masks';

export default function AdvertiserCard({ advertiserData }) {

  const minTime =
    advertiserData.delivery_time_avg < advertiserData.take_away_time_avg
      ? advertiserData.delivery_time_avg
      : advertiserData.take_away_time_avg;
  const maxTime =
    advertiserData.delivery_time_avg < advertiserData.take_away_time_avg
      ? advertiserData.delivery_time_avg
      : advertiserData.take_away_time_avg;
  const timeText = minTime === 0 && maxTime === 0 ? '' : `• ${minTime} - ${maxTime} min`

  function openAdvertiserWebMenu() {
    window.open(`https://${advertiserData.slug}${getWebMenuURL()}`, '_blank');
  }

  return (
    <CardBox
      onClick={() => openAdvertiserWebMenu()}
      title={advertiserData.name}
    >
      <Col
        margleft={px2vw(15)}
        margtop={px2vw(30)}
        width={px2vw(55)}
        height={px2vw(55)}
        background="white"
        borderRadius={px2vw(35)}
      >
        <AdvertiserLogoImage
          alt="logo"
          src={
            advertiserData?.logo
              ? `${advertiserData.logo}?nocache=${new Date().getTime()}`
              : require('../../assets/images/clients/near_clients/advertiser_without_logo@2x.png')
          }
        />
      </Col>
      <Divider />
      <Col>
        <AdvertiserNameText>
          ID: {advertiserData.advertiser_info_id}
        </AdvertiserNameText>
        <AdvertiserNameText>{advertiserData.name}</AdvertiserNameText>
        <AdvertiserCategoryText>
          CPF/ CNPJ: {cpfCnpjMask(advertiserData.cpf_cnpj)}
        </AdvertiserCategoryText>
        <AdvertiserTimesText>
          {advertiserData.is_using_delivery &&
            advertiserData.is_using_take_away &&
            `Entrega / Retirada ${timeText}`}
          {advertiserData.is_using_delivery &&
            !advertiserData.is_using_take_away &&
            `Entrega ${timeText}`}
          {!advertiserData.is_using_delivery &&
            advertiserData.is_using_take_away &&
            `Retirada ${timeText}`}
        </AdvertiserTimesText>
        <AdvertiserCategoryText>
          Distância: {`${advertiserData.distance} /Kms`}
        </AdvertiserCategoryText>
        <ButtonGoToWebMenu>Ir para o cardápio</ButtonGoToWebMenu>
      </Col>
    </CardBox>
  );
}
