import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styles';

import SideBar from '../../../components/Signed/SideBar';
import Skel from '../../../components/Signed/Skel';

export default function SignedLayout({ children, option }) {
  return (
    <Wrapper>
      <SideBar option={option} />
      <Skel>{children}</Skel>
    </Wrapper>
  );
}

SignedLayout.propTypes = {
  children: PropTypes.element.isRequired,
  option: PropTypes.string.isRequired,
};
