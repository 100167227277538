export const states = {
  AC: 'Acre – AC',
  AL: 'Alagoas – AL',
  AP: 'Amapá – AP',
  AM: 'Amazonas – AM',
  BA: 'Bahia – BA',
  CE: 'Ceará – CE',
  DF: 'Distrito Federal – DF',
  ES: 'Espírito Santo – ES',
  GO: 'Goiás – GO',
  MA: 'Maranhão – MA',
  MT: 'Mato Grosso – MT',
  MS: 'Mato Grosso do Sul – MS',
  MG: 'Minas Gerais – MG',
  PA: 'Pará – PA',
  PB: 'Paraíba – PB',
  PR: 'Paraná – PR',
  PE: 'Pernambuco – PE',
  PI: 'Piauí – PI',
  RR: 'Roraima – RR',
  RO: 'Rondônia – RO',
  RJ: 'Rio de Janeiro – RJ',
  RN: 'Rio Grande do Norte – RN',
  RS: 'Rio Grande do Sul – RS',
  SC: 'Santa Catarina – SC',
  SP: 'São Paulo – SP',
  SE: 'Sergipe – SE',
  TO: 'Tocantins – TO',
};

export const statesOptions = [
  { id: 'AC', title: 'AC (Acre)' },
  { id: 'AL', title: 'AL (Alagoas)' },
  { id: 'AP', title: 'AP (Amapá)' },
  { id: 'AM', title: 'AM (Amazonas)' },
  { id: 'BA', title: 'BA (Bahia)' },
  { id: 'CE', title: 'CE (Ceará)' },
  { id: 'DF', title: 'DF (Distrito Federal)' },
  { id: 'ES', title: 'ES (Espírito Santo)' },
  { id: 'GO', title: 'GO (Goiás)' },
  { id: 'MA', title: 'MA (Maranhão)' },
  { id: 'MT', title: 'MT (Mato Grosso)' },
  { id: 'MS', title: 'MS (Mato Grosso do Sul)' },
  { id: 'MG', title: 'MG (Minas Gerais)' },
  { id: 'PA', title: 'PA (Pará)' },
  { id: 'PB', title: 'PB (Paraíba)' },
  { id: 'PR', title: 'PR (Paraná)' },
  { id: 'PE', title: 'PE (Pernambuco)' },
  { id: 'PI', title: 'PI (Piauí)' },
  { id: 'RR', title: 'RR (Roraima)' },
  { id: 'RO', title: 'RO (Rondônia)' },
  { id: 'RJ', title: 'RJ (Rio de Janeiro)' },
  { id: 'RN', title: 'RN (Rio Grande do Norte)' },
  { id: 'RS', title: 'RS (Rio Grande do Sul)' },
  { id: 'SC', title: 'SC (Santa Catarina)' },
  { id: 'SP', title: 'SP (São Paulo)' },
  { id: 'SE', title: 'SE (Sergipe)' },
  { id: 'TO', title: 'TO (Tocantins)' },
];
