import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../styles/common';
import { preto } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';
import { CloseButton, ModalTitleText } from '../Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';

import closeButtonImg from '../../assets/images/close_button/close_button.png';

import px2vw from '../../utils/responsiveness/px2vw';

import Modal from '../Modal';

export default function DeleteOrdersModal({ handler }) {
  const { deleteOrdersLoading } = useSelector(
    (state) => state.clients
  );
  const [cancelTextConfirmation, setCancelTextConfirmation] = useState('');

  function handleCloseModal() {
    handler.setDeleteOrdersModalStatus(false);
  }

  return (
    <Modal
      isVisible={handler.deleteOrdersModalStatus}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={handleCloseModal}
          disabled={deleteOrdersLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Exclusão de Pedidos</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          {'Tem certeza que deseja excluir este(s) pedido(s) ?'}
        </InputTitle>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite 'excluir pedidos' para efetuar a exclusão
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="cancel"
          type="text"
          placeholder="excluir pedidos"
          width={px2vw(400)}
          value={cancelTextConfirmation}
          onChange={(e) => setCancelTextConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (cancelTextConfirmation === 'excluir pedidos') {
                handler.handleDeleteOrdersSubmit()
              }
            }} 
            disabled={cancelTextConfirmation !== 'excluir pedidos' || deleteOrdersLoading}
          >
            {deleteOrdersLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Excluir Pedido(s)'
              )}
            
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={handleCloseModal}
            disabled={deleteOrdersLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
