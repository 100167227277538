import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../../../../../styles/common';
import { preto } from '../../../../../../styles/colors';
import { Col, Row } from '../../../../../../styles/grid';
import { CloseButton, ModalTitleText } from '../../../../../../components/Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../../../../../../components/EditResponsibleDataModal/styles';

import closeButtonImg from '../../../../../../assets/images/close_button/close_button.png';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import Modal from '../../../../../../components/Modal';

import { forceWhatsappDisconnectionRequest } from '../../../../../../store/modules/clients/actions';

export default function ForceDisconnectionModal({ onClose }) {
  const { forceWhatsappDisconnectionLoading, clientData } = useSelector(
    (state) => state.clients
  );
  const [disconnectTextConfirmation, setDisconnectTextConfirmation] = useState('');
  const dispatch = useDispatch();

  return (
    <Modal
      isVisible
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={onClose}
          disabled={forceWhatsappDisconnectionLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Forçar desconexão whatsapp</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          {`Tem certeza que deseja forçar a desconexão do Whatsapp?`}
        </InputTitle>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite 'desconectar' para forçar a desconexão
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="disconnect"
          type="text"
          placeholder="desconectar"
          width={px2vw(400)}
          value={disconnectTextConfirmation}
          onChange={(e) => setDisconnectTextConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (disconnectTextConfirmation === 'desconectar') {
                dispatch(forceWhatsappDisconnectionRequest(clientData?.id));     
              }
            }} 
            disabled={disconnectTextConfirmation !== 'desconectar' || forceWhatsappDisconnectionLoading}
          >
            {forceWhatsappDisconnectionLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : ('Desconectar')}
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={onClose}
            disabled={forceWhatsappDisconnectionLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
