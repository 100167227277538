import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { LoadingBox } from '../../../../../../styles/common';
import { preto } from '../../../../../../styles/colors';
import { Col, Row } from '../../../../../../styles/grid';
import { CloseButton, ModalTitleText } from '../../../../../../components/Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../../../../../../components/EditResponsibleDataModal/styles';

import closeButtonImg from '../../../../../../assets/images/close_button/close_button.png';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import Modal from '../../../../../../components/Modal';

import { updatePixelsListRequest } from '../../../../../../store/modules/clients/actions';
import { onlyNumbers } from '../../../../../../utils/utils';

export default function AddPixelModal({ onClose }) {
  const { updatePixelsListLoading, clientData, pixelOwnDomainData } = useSelector(
    (state) => state.clients
  );
  const [addPixel, setAddPixel] = useState('');
  const dispatch = useDispatch();

  function handleAddPixel() {
    let newPixelsList = [];
    if (pixelOwnDomainData?.facebook_pixels) {
      newPixelsList = [...pixelOwnDomainData?.facebook_pixels]

      if (newPixelsList?.findIndex((pixel) => pixel === addPixel) !== -1) {
        toast.error('Pixel já cadastrado para esse cliente!')
        return;
      }
    }
    newPixelsList.push(addPixel);
    dispatch(updatePixelsListRequest(clientData?.id, newPixelsList));
  }

  return (
    <Modal
      isVisible
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={onClose}
          disabled={updatePixelsListLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Adicionar Pixel</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Insira o Pixel a ser incluído abaixo:
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="add"
          type="text"
          placeholder="Insira o Pixel a ser incluído"
          width={px2vw(400)}
          value={addPixel}
          onChange={(e) => setAddPixel(onlyNumbers(e.target.value))}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <ConfirmButton
            onClick={() => {
              if (addPixel.trim().length > 0) {
                handleAddPixel();                
              } else {
                toast.error('Deve ser informado o Pixel ID para inclusão!')
              }
            }} 
            disabled={updatePixelsListLoading}
          >
            {updatePixelsListLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : ('Adicionar')}
          </ConfirmButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <CancelButton  
            onClick={onClose}
            disabled={updatePixelsListLoading}
          >
            Voltar
          </CancelButton>
        </Col>
      </Row>
    </Modal>
  );
}
