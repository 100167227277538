import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { phoneMask } from '../../../utils/masks';

import { apiAdmin } from '../../../services/api';

import {
  getKuppiBoostOnboardingPendingResponse,
  getKuppiBoostWhatsappPendingResponse,
  formatKuppiBoostOnboardingPending as formatKuppiBoostOnboardingPendingAction,
  formatKuppiBoostWhatsappPending as formatKuppiBoostWhatsappPendingAction,
  checkKuppiBoostPermissionsResponse,
  updateKuppiBoostWhatsappValidateStatusResponse,
  requestFailure,
} from './actions';
import { logout } from '../auth/actions';

export function* getKuppiBoostOnboardingPendingRequest() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/kuppi-boost/onboarding-pending`
    );

    yield put(formatKuppiBoostOnboardingPendingAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());

      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* formatKuppiBoostOnboardingPending({ payload }) {
  const { data } = payload;

  function* destructData() {
    yield* data;
  }

  const sequence = destructData();
  let hasIterable = true;
  const formattedData = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      let formattedStatus;
      switch (value.status) {
        case 'pending_approval':
          formattedStatus = 'Pendente de Validação';
          break;
        case 'bad_configuration':
          formattedStatus = 'Erro na Configuração';
          break;
        default:
          formattedStatus = 'Status Inválido';
          break;
      }

      const currFormattedData = {
        advertiser_info_id: value.id,
        name: value.name,
        status: formattedStatus,
        phone_whatsapp_representative: phoneMask(value.phone_whatsapp_representative),
        facebook_page_id: value.facebook_page_id,
        instagram_page_id: value.instagram_page_id,
      };

      formattedData.push(currFormattedData);
    } else {
      hasIterable = false;
    }
  }
  yield put(getKuppiBoostOnboardingPendingResponse(formattedData));
}

export function* checkKuppiBoostPermissionsRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/kuppi-boost/clients/${payload.advertiserInfoId}/check-permissions`
    );

    if (response?.data?.error?.message) {
      toast.error(response?.data?.error?.message, {
        autoClose: false,
      });
    } else {
      toast.success('Permissões concedidas corretamente, usuário liberado para utilizar o Kuppi Boost', {
        autoClose: 6000
      })
    }

    yield put(checkKuppiBoostPermissionsResponse());
    yield put(formatKuppiBoostOnboardingPendingAction(response?.data?.pendingOnboardingValidation));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(checkKuppiBoostPermissionsResponse());

      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 8000,
        });
      } else {
        toast.error(
          'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
          { autoClose: 8000 }
        );
      }
    }
  }
}

export function* getKuppiBoostWhatsappPendingRequest() {
  try {
    const response = yield call(
      apiAdmin.get,
      `/kuppi-boost/whatsapp-validation-pending`
    );

    yield put(formatKuppiBoostWhatsappPendingAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export function* formatKuppiBoostWhatsappPending({ payload }) {
  const { data } = payload;

  function* destructData() {
    yield* data;
  }

  const sequence = destructData();
  let hasIterable = true;
  const formattedData = [];

  while (hasIterable) {
    const value = yield sequence.next().value;
    if (value) {
      let formattedStatus;
      switch (value.whatsapp_fb_linked_status) {
        case 'pending_approval':
          formattedStatus = 'Pendente de Validação';
          break;
        case 'not_linked':
          formattedStatus = 'Não Associado';
          break;
        case 'not_validated':
          formattedStatus = 'Não configurado';
          break;
        default:
          formattedStatus = 'Status Inválido';
          break;
      }

      const currFormattedData = {
        advertiser_info_id: value.id,
        name: value.name,
        whatsapp_fb_linked_status: formattedStatus,
        phone_whatsapp_representative: phoneMask(value.phone_whatsapp_representative),
        facebook_page_id: value.facebook_page_id,
        instagram_page_id: value.instagram_page_id,
      };
      formattedData.push(currFormattedData);

    } else {
      hasIterable = false;
    }
  }
  yield put(getKuppiBoostWhatsappPendingResponse(formattedData));
}

export function* updateKuppiBoostWhatsappValidateStatusRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.put,
      `/kuppi-boost/clients/${payload.data.advertiser_info_id}/update-whatsapp-validation-status`, payload.data
    );

    yield put(updateKuppiBoostWhatsappValidateStatusResponse());
    yield put(formatKuppiBoostWhatsappPendingAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(requestFailure());
      toast.error(
        'Não foi possível atualizar as informações, Faça o login novamente na plataforma e tente outra vez! Caso o erro persista, entre em contato com a equipe técnica.',
        { autoClose: 8000 }
      );
    }
  }
}

export default all([
  takeLatest(
    '@kuppiBoostValidation/KUPPI_BOOST_ONBOARDING_PENDING_REQUEST',
    getKuppiBoostOnboardingPendingRequest
  ),
  takeLatest('@kuppiBoostValidation/FORMAT_KUPPI_BOOST_ONBOARDING_PENDING', formatKuppiBoostOnboardingPending),
  takeLatest(
    '@kuppiBoostValidation/CHECK_KUPPI_BOOST_PERMISSIONS_REQUEST',
    checkKuppiBoostPermissionsRequest
  ),
  takeLatest(
    '@kuppiBoostValidation/KUPPI_BOOST_WHATSAPP_PENDING_REQUEST',
    getKuppiBoostWhatsappPendingRequest
  ),
  takeLatest('@kuppiBoostValidation/FORMAT_KUPPI_BOOST_WHATSAPP_PENDING', formatKuppiBoostWhatsappPending),
  takeLatest('@kuppiBoostValidation/UPDATE_KUPPI_BOOST_WHATSAPP_VALIDATE_STATUS_REQUEST', updateKuppiBoostWhatsappValidateStatusRequest),
]);
