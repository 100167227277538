import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import {
  CancelSubscriptionButton,
  EditButton,
  Hr,
  Text,
  WhiteBox,
} from '../../styles';
import { Col, Row } from '../../../../../styles/grid';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';

import editImg from '../../../../../assets/images/clients/edit@2x.png';
import { formatCurrencyBRL, onlyNumbersAmount } from '../../../../../utils/utils';
import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';
import AddBoostBalanceModal from '../../../../../components/AddBoostBalanceModal';
import {
  addKuppiBoostBalanceRequest,
  editFacebookInstagramIdRequest,
  getKuppiBoostDataRequest,
} from '../../../../../store/modules/clients/actions';
import KuppiBoostClientsData from '../../../../../components/KuppiBoostClientData';
import EditFacebookInstagramIdModal from '../../../../../components/EditFacebookInstagramIdModal';

export default function TabKuppiBoost() {
  const { access } = useSelector((state) => state.user);
  const financialAccess = AccessValidate(
    access,
    'admin_financial',
    'financial',
    null,
    false
  );

  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );

  const {
    kuppiBoostDataLoading,
    addKuppiBoostBalanceSuccess,
    paymentReversalSuccess,
    editFacebookInstagramIdSuccess
  } = useSelector((state) => state.clients);

  const kuppiBoostData = useSelector((state) => state.clients.kuppiBoostData);
  const clientData = useSelector((state) => state.clients.clientData);

  const [addBoostBalance, setAddBoostBalance] = useState(false);
  const balanceTypeList = [
    { title: 'Bônus por Assinatura' },
    { title: 'Bônus por Indicação' },
  ];
  const [balanceTypeTitle, setBalanceTypeTitle] = useState('');
  const [amountAdded, setAmountAdded] = useState('');
  const [mgmId, setMgmId] = useState(null);
  const [editFacebookInstagramId, setEditFacebookInstagramId] = useState(false);
  const [facebookId, setFacebookId] = useState('');
  const [instagramId, setInstagramId] = useState('');

  const dispatch = useDispatch();

  function handleAddKuppiBoostBalanceSubmit() {
    if (amountAdded.length < 3) {
      toast.error('Por gentileza inserir um valor válido! Exemplo: (5.00)');
    } else {
      let amount = onlyNumbersAmount(amountAdded);

      if (balanceTypeTitle === '') {
        toast.error(
          'Por gentileza selecionar o tipo de saldo a ser adicionado!'
        );
      } else if (amount < 0.01) {
        toast.error('Por gentileza inserir um valor maior que R$ 0,00!');
      } else if (parseInt(mgmId) === clientData.id) {
        toast.error(
          'Por gentileza inserir um ID do indicado diferente do ID do anunciante!'
        );
      } else {
        dispatch(addKuppiBoostBalanceRequest(clientData.id, amount, mgmId));
      }
    }
  }

  function handleEditFacebookInstagramIdSubmit() {
    if (facebookId.toString().trim() === '' || facebookId.toString().trim().length < 15) {
      toast.error('Por gentileza inserir um ID do Facebook válido!');
    } else if (instagramId.trim() === '' || instagramId.trim().length < 15) {
      toast.error('Por gentileza inserir um ID do Instagram válido!');
    } else {
      dispatch(editFacebookInstagramIdRequest(
        clientData.id,
        facebookId,
        instagramId
      ));
    }
  }

  function handleStatusText(status) {
    switch (status) {
      case 'pending_approval':
        return 'Aprovação Pendente';
      case 'bad_configuration':
        return 'Erro na Configuração';
      case 'not_started':
        return 'Não Iniciado';
      case 'ok':
        return 'Ok';
      default:
        return 'Status Inválido';
    }
  }

  function handleWhatsFbStatus(status) {
    switch (status) {
      case 'not_validated':
        return 'Não Validado';
      case 'pending_approval':
        return 'Aprovação Pendente';
      case 'not_linked':
        return 'Não Linkado';
      case 'ok':
        return 'Ok';
      default:
        return 'Status Inválido';
    }
  }

  useEffect(() => {
    setFacebookId(kuppiBoostData.facebook_page_id);
    setInstagramId(kuppiBoostData.instagram_page_id);
  }, [kuppiBoostData]);

  useEffect(() => {
    if (addKuppiBoostBalanceSuccess) {
      setAddBoostBalance(false);
      setAmountAdded('');
      setMgmId(null);
      setBalanceTypeTitle('');
      dispatch(getKuppiBoostDataRequest(clientData.id));
    }
  }, [addKuppiBoostBalanceSuccess]);

  useEffect(() => {
    if (paymentReversalSuccess) {
      dispatch(getKuppiBoostDataRequest(clientData.id));
    }
  }, [paymentReversalSuccess]);

  useEffect(() => {
    if (editFacebookInstagramIdSuccess) {
      setEditFacebookInstagramId(false);
      dispatch(getKuppiBoostDataRequest(clientData.id));
    }
  }, [editFacebookInstagramIdSuccess]);
  
  const handler = {
    addBoostBalance: addBoostBalance,
    balanceTypeList: balanceTypeList,
    balanceTypeTitle: balanceTypeTitle,
    amountAdded: amountAdded,
    mgmId: mgmId,
    editFacebookInstagramId: editFacebookInstagramId,
    facebookId: facebookId,
    instagramId: instagramId,
    setAddBoostBalance: () => setAddBoostBalance(!addBoostBalance),
    setBalanceTypeTitle: (e) => setBalanceTypeTitle(e),
    setAmountAdded: (val) => setAmountAdded(val),
    setMgmId: (id) => setMgmId(id),
    handleAddKuppiBoostBalanceSubmit: () => handleAddKuppiBoostBalanceSubmit(),
    setEditFacebookInstagramId: () =>
      setEditFacebookInstagramId(!editFacebookInstagramId),
    setFacebookId: (fbId) => setFacebookId(fbId),
    setInstagramId: (instaId) => setInstagramId(instaId),
    handleEditFacebookInstagramIdSubmit: () =>
      handleEditFacebookInstagramIdSubmit(),
  };

  return (
    <>
      {kuppiBoostDataLoading ? (
        <LoadingBox>
          <Spinner
            style={{
              width: '1.2rem',
              height: '1.2rem',
              color: preto,
            }}
          />{' '}
        </LoadingBox>
      ) : (
        <>
          <Col>
            <Row justifyStart alignCenter>
              <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(15)}>
                Dados do Kuppi Boost
              </Text>
              {operationalEditAccess && (
                <Col width="auto" height="auto">
                  <EditButton 
                    onClick={() => setEditFacebookInstagramId(true)}
                  >
                    <img
                      src={editImg}
                      alt="Edit Icon"
                      width={px2vw(175)}
                      height={px2vw(175)}
                    />
                    Editar
                  </EditButton>
                </Col>
              )}
            </Row>

            <WhiteBox margtop={px2vw(10)}>
              <Row spaceBetween margbottom={px2vw(10)} alignCenter>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Cliente concluiu o onboarding?
                  </Text>
                  <Text>
                    {kuppiBoostData.is_finished_onboarding === true
                      ? 'Sim'
                      : 'Não'}
                  </Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Status
                  </Text>
                  <Text>{handleStatusText(kuppiBoostData.status)}</Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Saldo
                  </Text>
                  <Text>{formatCurrencyBRL(kuppiBoostData.balance, {})}</Text>
                </Col>
                <Col width="auto">
                  {financialAccess && (
                    <CancelSubscriptionButton
                      onClick={() => setAddBoostBalance(true)}
                    >
                      Adicionar Saldo
                    </CancelSubscriptionButton>
                  )}
                </Col>
              </Row>
              <Hr />
              <Row spaceBetween margtop={px2vw(10)} alignCenter>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Id do Facebook
                  </Text>
                  <Text>
                    {kuppiBoostData.facebook_page_id === null
                      ? '-'
                      : kuppiBoostData.facebook_page_id}
                  </Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Id do Instagram
                  </Text>
                  <Text>
                    {kuppiBoostData.instagram_page_id === null
                      ? '-'
                      : kuppiBoostData.instagram_page_id}
                  </Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Whatsapp/ Facebook Status
                  </Text>
                  <Text>
                    {handleWhatsFbStatus(
                      kuppiBoostData.whatsapp_fb_linked_status
                    )}
                  </Text>
                </Col>
                <Col width="auto"></Col>
                <Col width="auto"></Col>
              </Row>
            </WhiteBox>
          </Col>
          <Col margtop={px2vw(20)}>
            <KuppiBoostClientsData />
          </Col>
        </>
      )}
      {addBoostBalance && <AddBoostBalanceModal handler={handler} />}
      {editFacebookInstagramId && (
        <EditFacebookInstagramIdModal handler={handler} />
      )}
    </>
  );
}
