import React from 'react';

import { Row } from '../../styles/grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import px2vw from '../../utils/responsiveness/px2vw';

import {
  ContentBox,
  MenuIconBox,
  MenuIconImg,
  Text,
  TextContent,
} from './style';
import { preto2 } from '../../styles/colors';

function Accordion(props) {
  const rotateValue = props.selected ? '180deg' : 0;
  const green = props.optionactive
    ? 'invert(59%) sepia(83%) saturate(389%) hue-rotate(106deg) brightness(93%) contrast(85%)'
    : 'none';
  const styleClass = {
    transform: `rotate(${rotateValue})`,
    filter: `${green}`,
  };

  return (
    <ContentBox onClick={props.onClick}>
      {/* Icon and Title */}
      <Row width="auto" height="auto">
        <MenuIconBox optionactive={props.optionactive}>
          <MenuIconImg
            className="icon"
            alt="arrow"
            src={require('../../assets/images/clients/clipboard@2x.png')}
          />
        </MenuIconBox>
        <Text
          id="1"
          size={px2vw(15)}
          color={preto2}
          justifyStart
          optionactive={props.optionactive}
          margleft={px2vw(5)}
        >
          {props.title}
        </Text>
        <Row width="auto" margleft={px2vw(5)} margtop={px2vw(3)}>
          <ExpandMoreIcon
            fontSize="medium"
            style={styleClass}
            className="ml-auto"
          />
        </Row>
      </Row>

      <Row justifyStart height="auto">
        {props.selected && <TextContent>{props.children}</TextContent>}
      </Row>
    </ContentBox>
  );
}

export default Accordion;
