import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { Col, Row } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';
import { Title } from '../Clients/styles';
import { EditButton, Text, WhiteBox } from './styles';
import editImg from '../../assets/images/clients/edit@2x.png';
import MassMessageUnlimitedPackageTable from '../../components/Tables/MassMessageUnlimitedPackageTable';
import MassMessageGeneralAmountTable from '../../components/Tables/MassMessageGeneralAmountTable';
import { ContentPage } from '../_layouts/styles/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMassMessageGeneralRequest,
  clearUpdateMassMessageGeneralAmountSuccess,
} from '../../store/modules/massMessageGeneral/actions';
import { LoadingBox } from '../../styles/common';
import { preto } from '../../styles/colors';
import AccessValidate from '../../utils/validations/AccessValidate';
import ChangeGeneralAmountModal from './ChangeGeneralAmountModal';

export default function MassMessageGeneral() {
  const dispatch = useDispatch();
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );
  const clientsWithUnlimitedPackage = useSelector((state) => state.massMessageGeneral.clientsWithUnlimitedPackage);
  const perContactAmounts = useSelector((state) => state.massMessageGeneral.perContactAmounts);
  const [currMassMessageAmountData, setCurrMassMessageAmountData] = useState(null);
  const [changeGeneralAmountModal, setChangeGeneralAmountModal] = useState(false);
  const {
    massMessageGeneralLoading,
    updateMassMessageGeneralAmountSuccess
  } = useSelector((state) => state.massMessageGeneral);

  useEffect(() => {
    dispatch(getMassMessageGeneralRequest());
  }, [])

  useEffect(() => {
    if (perContactAmounts?.length > 0) {
      setCurrMassMessageAmountData(perContactAmounts[0]);
    }
  }, [perContactAmounts])

  useEffect(() => {
    if (updateMassMessageGeneralAmountSuccess) {
      setChangeGeneralAmountModal(false);
      dispatch(clearUpdateMassMessageGeneralAmountSuccess());
    }
  }, [updateMassMessageGeneralAmountSuccess])


  return (
    <>
      <ContentPage>
        <Row height="auto" alignStart justifyStart>
          <Title>Disparo em Massa</Title>
        </Row>
        <Row margtop={px2vw(20)}>
          <Col height="auto" margright={px2vw(20)} margtop={px2vw(10.5)}>
            <Text fontweight="bold" fontsize={px2vw(13)}>
              Lista de clientes com plano de disparo ilimitado
            </Text>
            <WhiteBox margtop={px2vw(10)} width={px2vw(500)}>
              <Row width="auto">
                {massMessageGeneralLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.2rem',
                        height: '1.2rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <MassMessageUnlimitedPackageTable data={clientsWithUnlimitedPackage} />
                )}
              </Row>
            </WhiteBox>
          </Col>
          <Col height="auto">
            <Row height="auto" justifyStart alignCenter>
              <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(15)}>
                Valor geral de disparo
              </Text>
              {operationalEditAccess && (
                <EditButton onClick={() => setChangeGeneralAmountModal(true)}>
                  <img
                    src={editImg}
                    alt="Edit Icon"
                    width={px2vw(175)}
                    height={px2vw(175)}
                  />
                  Editar
                </EditButton>
              )}
            </Row>
            <WhiteBox margtop={px2vw(10)} width={px2vw(500)}>
              <Row width="auto">
                {massMessageGeneralLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.2rem',
                        height: '1.2rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <MassMessageGeneralAmountTable data={perContactAmounts} />
                )}
              </Row>
            </WhiteBox>
          </Col>
        </Row>
      </ContentPage>
      {changeGeneralAmountModal && 
        <ChangeGeneralAmountModal
          currMassMessageAmountData={currMassMessageAmountData}
          onClose={() => setChangeGeneralAmountModal(false)}
        />
      }
    </>
    
  );
}
