import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Table from '../../Table';

import px2vw from '../../../utils/responsiveness/px2vw';
import { useSelector } from 'react-redux';

const columns = [
  {
    field: 'stateCity',
    title: 'Estado/Cidade',
    width: px2vw(250),
    render: ({ stateCity }) => stateCity,
  },
  {
    field: 'district',
    title: 'Bairro',
    width: px2vw(250),
    maxCharLength: '100ch',
    render: ({ district }) => district,
  },
];

function DistrictsTable({ data }) {
  const { districtsSuccess } = useSelector((state) => state.clients);
  const [dataUpdated, setDataUpdated] = useState([]);
  useEffect(() => {
    if(districtsSuccess){
      setDataUpdated(data);
    }   
  }, [districtsSuccess, data]);
  return <Table data={dataUpdated} columns={columns} clientsType="District" />;
}

DistrictsTable.propTypes = {
  data: PropTypes.array,
};

export default DistrictsTable;
