import produce from 'immer';

const INITIAL_STATE = {
  clientCategories: [],
  subscriptionsList: [],
  clientsLoading: false,
  error: false,
  clients: [],
  clientData: {},
  clientDataLoading: false,
  clientDataSuccess: false,
  clientDataError: false,
  subscriptions: [],
  isDuePayment: false,
  subscriptionsLoading: false,
  subscriptionsError: false,
  recurring: {},
  updateRepresentativeDataLoading: false,
  updateRepresentativeDataSuccess: false,
  generateNewPasswordLoading: false,
  generateNewPasswordSuccess: false,
  updateStoreDataLoading: false,
  updateStoreDataSuccess: false,
  storeStatusLoading: false,
  storeStatusSuccess: false,
  updateStoreSubscriptionLoading: false,
  updateStoreSubscriptionSuccess: false,
  manualConciliationLoading: false,
  manualConciliationSuccess: false,
  cancelSubscriptionLoading: false,
  cancelSubscriptionSuccess: false,
  localities: [],
  localitiesLoading: false,
  localitiesSuccess: true,
  districts: [],
  districtsLoading: true,
  districtsSuccess: false,
  addDistrictLoading: false,
  addDistrictSuccess: false,
  orders: [],
  ordersLoading: false,
  ordersError: false,
  deleteOrdersLoading: false,
  deleteOrdersSuccess: false,
  removeCreditCardLoading: false,
  removeCreditCardSuccess: false,
  kuppiBoostDataLoading: false,
  kuppiBoostDataSuccess: false,
  kuppiBoostData: {},
  kuppiBoostDataError: false,
  addKuppiBoostBalanceLoading: false,
  addKuppiBoostBalanceSuccess: false,
  campaignsData: [],
  paymentsData: [],
  campaignsDataLoading: false,
  campaignsDataSuccess: false,
  campaignsDataError: false,
  paymentReversalLoading: false,
  paymentReversalSuccess: false,
  editFacebookInstagramIdLoading: false,
  editFacebookInstagramIdSuccess: false,
  nearClientsLoading: false,
  nearClientsSuccess: false,
  nearClients: {},
  pixelOwnDomainDataLoading: false,
  pixelOwnDomainDataSuccess: false,
  pixelOwnDomainDataError: false,
  pixelOwnDomainData: {},
  updatePixelsListLoading: false,
  updatePixelsListSuccess: false,
  whatsappDataLoading: false,
  whatsappDataSuccess: false,
  whatsappDataError: false,
  whatsappData: {},
  forceWhatsappDisconnectionLoading: false,
  forceWhatsappDisconnectionSuccess: false,
  massMessageDataLoading: false,
  massMessageDataSuccess: false,
  massMessageData: {},
  massMessageDataError: false,
  addMassMessageBalanceLoading: false,
  addMassMessageBalanceSuccess: false,
  editMassMessageClientLoading: false,
  editMassMessageClientSuccess: false,
  massMessageCampaignsData: [],
  massMessagePaymentsData: [],
  massMessageBonusData: [],
  massMessageCampaignsDataLoading: false,
  massMessageCampaignsDataSuccess: false,
  massMessageCampaignsDataError: false,
  clearCacheLoading: false,
  advertiserAdminLoginLoading: false,
  newPaymentLinkLoading: false,
  copyCatalogLoading: false,
  copyCatalogSuccess: false,
  getUserPermissionsLoading: false,
  userPermissions: {},
  updateUserPermissionLoading: false,
  updateUserPermissionsSuccess: false,
  allowMoreFreePeriodLoading: false,
  addDomainLoading: false,
  addDomainSuccess: false,
  linkWebMenuDomainLoading: false,
  removeDomainLoading: false,
  removeDomainSuccess: false,
  campaignActionLoading: false,
  campaignActionSuccess: false,
};

export default function clients(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@clients/GET_CLIENTS_REQUEST': {
        draft.clientsLoading = true;
        draft.clients = [];
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_SUCCESS': {
        draft.clientsLoading = false;
        if (action.payload.clients !== '') {
          draft.clients = action.payload.clients;
        }
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_FAILURE': {
        draft.clientsLoading = false;
        draft.clients = [];
        draft.error = true;
        break;
      }
      case '@clients/GET_CLIENT_DATA_REQUEST': {
        draft.clientDataLoading = true;
        draft.clientDataSuccess = false;
        draft.clientData = {};
        draft.clientDataError = false;
        break;
      }
      case '@clients/GET_CLIENT_DATA_SUCCESS': {
        draft.clientDataLoading = false;
        draft.clientDataSuccess = true;
        draft.clientData = action.payload.clientData;
        draft.clientDataError = false;
        break;
      }
      case '@clients/GET_CLIENT_DATA_FAILURE': {
        draft.clientDataLoading = false;
        draft.clientDataSuccess = false;
        draft.clientData = {};
        draft.clientDataError = true;
        break;
      }
      case '@clients/GET_CLIENT_CATEGORIES_SUCCESS': {
        draft.clientCategories = action.payload.categories;
        break;
      }
      case '@clients/GET_CLIENT_CATEGORIES_FAILURE': {
        draft.clientCategories = [];
        break;
      }
      case '@clients/UPDATE_REPRESENTATIVE_DATA_REQUEST': {
        draft.updateRepresentativeDataLoading = true;
        draft.updateRepresentativeDataSuccess = false;
        break;
      }
      case '@clients/UPDATE_REPRESENTATIVE_DATA_SUCCESS': {
        draft.updateRepresentativeDataLoading = false;
        draft.updateRepresentativeDataSuccess = true;
        break;
      }
      case '@clients/UPDATE_REPRESENTATIVE_DATA_FAILURE': {
        draft.updateRepresentativeDataLoading = false;
        draft.updateRepresentativeDataSuccess = false;
        break;
      }
      case '@clients/GENERATE_NEW_PASSWORD_REQUEST': {
        draft.generateNewPasswordLoading = true;
        draft.generateNewPasswordSuccess = false;
        break;
      }
      case '@clients/GENERATE_NEW_PASSWORD_SUCCESS': {
        draft.generateNewPasswordLoading = false;
        draft.generateNewPasswordSuccess = true;
        break;
      }
      case '@clients/GENERATE_NEW_PASSWORD_FAILURE': {
        draft.generateNewPasswordLoading = false;
        draft.generateNewPasswordSuccess = false;
        break;
      }
      case '@clients/UPDATE_STORE_DATA_REQUEST': {
        draft.updateStoreDataLoading = true;
        draft.updateStoreDataSuccess = false;
        break;
      }
      case '@clients/UPDATE_STORE_DATA_SUCCESS': {
        draft.updateStoreDataLoading = false;
        draft.updateStoreDataSuccess = true;
        break;
      }
      case '@clients/UPDATE_STORE_DATA_FAILURE': {
        draft.updateStoreDataLoading = false;
        draft.updateStoreDataSuccess = false;
        break;
      }
      case '@clients/UPDATE_STORE_STATUS_DATA_REQUEST': {
        draft.storeStatusLoading = true;
        draft.storeStatusSuccess = false;
        break;
      }
      case '@clients/UPDATE_STORE_STATUS_DATA_SUCCESS': {
        draft.storeStatusLoading = false;
        draft.storeStatusSuccess = true;
        break;
      }
      case '@clients/UPDATE_STORE_STATUS_DATA_FAILURE': {
        draft.storeStatusLoading = false;
        draft.storeStatusSuccess = false;
        break;
      }
      case '@clients/COPY_CATALOG_REQUEST': {
        draft.copyCatalogLoading = true;
        draft.copyCatalogSuccess = false;
        break;
      }
      case '@clients/COPY_CATALOG_SUCCESS': {
        draft.copyCatalogLoading = false;
        draft.copyCatalogSuccess = true;
        break;
      }
      case '@clients/COPY_CATALOG_FAILURE': {
        draft.copyCatalogLoading = false;
        draft.copyCatalogSuccess = false;
        break;
      }
      case '@clients/CLEAR_COPY_CATALOG_SUCCESS': {
        draft.copyCatalogSuccess = false;
        break;
      }
      case '@clients/GET_USER_PERMISSIONS_REQUEST': {
        draft.getUserPermissionsLoading = true;
        draft.userPermissions = {};
        break;
      }
      case '@clients/GET_USER_PERMISSIONS_SUCCESS': {
        draft.getUserPermissionsLoading = false;
        draft.userPermissions = action.payload.userPermissions;
        break;
      }
      case '@clients/GET_USER_PERMISSIONS_FAILURE': {
        draft.getUserPermissionsLoading = false;
        draft.userPermissions = {};
        break;
      }
      case '@clients/UPDATE_USER_PERMISSIONS_REQUEST': {
        draft.updateUserPermissionsLoading = true;
        draft.updateUserPermissionsSuccess = false;
        break;
      }
      case '@clients/UPDATE_USER_PERMISSIONS_SUCCESS': {
        draft.updateUserPermissionsLoading = false;
        draft.updateUserPermissionsSuccess = true;
        draft.userPermissions = action.payload.userPermissions;
        break;
      }
      case '@clients/UPDATE_USER_PERMISSIONS_FAILURE': {
        draft.updateUserPermissionsLoading = false;
        draft.updateUserPermissionsSuccess = false;
        break;
      }
      case '@clients/CLEAR_UPDATE_USER_PERMISSIONS_SUCCESS': {
        draft.updateUserPermissionsSuccess = false;
        break;
      }
      case '@clients/ALLOW_MORE_FREE_PERIOD_REQUEST': {
        draft.allowMoreFreePeriodLoading = true;
        break;
      }
      case '@clients/ALLOW_MORE_FREE_PERIOD_SUCCESS': {
        draft.allowMoreFreePeriodLoading = false;
        draft.clientData = action.payload.clientData;
        break;
      }
      case '@clients/ALLOW_MORE_FREE_PERIOD_FAILURE': {
        draft.allowMoreFreePeriodLoading = false;
        break;
      }
      case '@clients/GET_NEAR_CLIENTS_REQUEST': {
        draft.nearClientsLoading = true;
        draft.nearClientsSuccess = false;
        break;
      }
      case '@clients/GET_NEAR_CLIENTS_SUCCESS': {
        draft.nearClientsLoading = false;
        draft.nearClientsSuccess = true;
        draft.nearClients = action.payload.nearClients;
        break;
      }
      case '@clients/GET_NEAR_CLIENTS_FAILURE': {
        draft.nearClientsLoading = false;
        draft.nearClientsSuccess = false;
        draft.nearClients = {};
        break;
      }
      case '@clients/GET_SUBSCRIPTIONS_LIST_SUCCESS': {
        draft.subscriptionsList = action.payload.subscriptionsList;
        break;
      }
      case '@clients/GET_SUBSCRIPTIONS_LIST_FAILURE': {
        draft.subscriptionsList = [];
        break;
      }
      case '@clients/GET_SUBSCRIPTIONS_REQUEST': {
        draft.subscriptions = [];
        draft.recurring = {};
        draft.subscriptionsLoading = true;
        draft.subscriptionsError = false;
        draft.cancelSubscriptionSuccess = false;
        draft.updateStoreSubscriptionSuccess = false;
        break;
      }
      case '@clients/GET_SUBSCRIPTIONS_SUCCESS': {
        draft.subscriptions = action.payload.subscriptions;
        draft.isDuePayment = action.payload.isDuePayment;
        draft.subscriptionsLoading = false;
        draft.subscriptionsError = false;
        break;
      }
      case '@clients/GET_SUBSCRIPTIONS_FAILURE': {
        draft.subscriptions = [];
        draft.isDuePayment = false;
        draft.subscriptionsLoading = false;
        draft.subscriptionsError = true;
        break;
      }
      case '@clients/GET_RECURRING_SUCCESS': {
        draft.recurring = action.payload.recurring;
        draft.subscriptionsLoading = false;
        draft.subscriptionsError = false;
        break;
      }
      case '@clients/UPDATE_STORE_SUBSCRIPTION_DATA_REQUEST': {
        draft.updateStoreSubscriptionLoading = true;
        draft.updateStoreSubscriptionSuccess = false;
        break;
      }
      case '@clients/UPDATE_STORE_SUBSCRIPTION_DATA_SUCCESS': {
        draft.updateStoreSubscriptionLoading = false;
        draft.updateStoreSubscriptionSuccess = true;
        break;
      }
      case '@clients/UPDATE_STORE_SUBSCRIPTION_DATA_FAILURE': {
        draft.updateStoreSubscriptionLoading = false;
        draft.updateStoreSubscriptionSuccess = false;
        break;
      }
      case '@clients/MANUAL_CONCILIATION_REQUEST': {
        draft.manualConciliationLoading = true;
        draft.manualConciliationSuccess = false;
        break;
      }
      case '@clients/MANUAL_CONCILIATION_SUCCESS': {
        draft.manualConciliationLoading = false;
        draft.manualConciliationSuccess = true;
        break;
      }
      case '@clients/MANUAL_CONCILIATION_FAILURE': {
        draft.manualConciliationLoading = false;
        draft.manualConciliationSuccess = false;
        break;
      }
      case '@clients/CANCEL_SUBSCRIPTION_REQUEST': {
        draft.cancelSubscriptionLoading = true;
        draft.cancelSubscriptionSuccess = false;
        break;
      }
      case '@clients/CANCEL_SUBSCRIPTION_SUCCESS': {
        draft.cancelSubscriptionLoading = false;
        draft.cancelSubscriptionSuccess = true;
        break;
      }
      case '@clients/CANCEL_SUBSCRIPTION_FAILURE': {
        draft.cancelSubscriptionLoading = false;
        draft.cancelSubscriptionSuccess = false;
        break;
      }
      case '@clients/REMOVE_CREDIT_CARD_REQUEST': {
        draft.removeCreditCardLoading = true;
        draft.removeCreditCardSuccess = false;
        break;
      }
      case '@clients/REMOVE_CREDIT_CARD_SUCCESS': {
        draft.removeCreditCardLoading = false;
        draft.removeCreditCardSuccess = true;
        break;
      }
      case '@clients/REMOVE_CREDIT_CARD_FAILURE': {
        draft.removeCreditCardLoading = false;
        draft.removeCreditCardSuccess = false;
        break;
      }
      case '@clients/GENERATE_NEW_PAYMENT_LINK_REQUEST': {
        draft.newPaymentLinkLoading = true;
        break;
      }
      case '@clients/GENERATE_NEW_PAYMENT_LINK_SUCCESS': {
        draft.newPaymentLinkLoading = false;
        break;
      }
      case '@clients/GENERATE_NEW_PAYMENT_LINK_FAILURE': {
        draft.newPaymentLinkLoading = false;
        break;
      }
      case '@clients/GET_KUPPI_BOOST_DATA_REQUEST': {
        draft.kuppiBoostDataLoading = true;
        draft.kuppiBoostDataSuccess = false;
        draft.kuppiBoostData = {};
        draft.kuppiBoostDataError = false;
        break;
      }
      case '@clients/GET_KUPPI_BOOST_DATA_SUCCESS': {
        draft.kuppiBoostDataLoading = false;
        draft.kuppiBoostDataSuccess = true;
        draft.kuppiBoostData = action.payload.kuppiBoostData;
        draft.kuppiBoostDataError = false;
        break;
      }
      case '@clients/GET_KUPPI_BOOST_DATA_FAILURE': {
        draft.kuppiBoostDataLoading = false;
        draft.kuppiBoostDataSuccess = false;
        draft.kuppiBoostData = {};
        draft.kuppiBoostDataError = true;
        break;
      }
      case '@clients/GET_BOOST_CAMPAIGNS_REQUEST': {
        draft.campaignsDataLoading = true;
        draft.campaignsDataSuccess = false;
        draft.campaignsData = [];
        draft.paymentsData = [];
        draft.campaignsDataError = false;
        break;
      }
      case '@clients/GET_BOOST_CAMPAIGNS_SUCCESS': {
        draft.campaignsDataLoading = false;
        draft.campaignsDataSuccess = true;
        draft.campaignsData = action.payload.campaignsData.campaigns;
        draft.paymentsData = action.payload.campaignsData.payments_data;
        draft.campaignsDataError = false;
        break;
      }
      case '@clients/GET_BOOST_CAMPAIGNS_FAILURE': {
        draft.campaignsDataLoading = false;
        draft.campaignsDataSuccess = false;
        draft.campaignsData = [];
        draft.paymentsData = [];
        draft.campaignsDataError = true;
        break;
      }
      case '@clients/ADD_KUPPI_BOOST_BALANCE_REQUEST': {
        draft.addKuppiBoostBalanceLoading = true;
        draft.addKuppiBoostBalanceSuccess = false;
        break;
      }
      case '@clients/ADD_KUPPI_BOOST_BALANCE_SUCCESS': {
        draft.addKuppiBoostBalanceLoading = false;
        draft.addKuppiBoostBalanceSuccess = true;
        break;
      }
      case '@clients/ADD_KUPPI_BOOST_BALANCE_FAILURE': {
        draft.addKuppiBoostBalanceLoading = false;
        draft.addKuppiBoostBalanceSuccess = false;
        break;
      }
      case '@clients/PAYMENT_REVERSAL_REQUEST': {
        draft.paymentReversalLoading = true;
        draft.paymentReversalSuccess = false;
        break;
      }
      case '@clients/PAYMENT_REVERSAL_SUCCESS': {
        draft.paymentReversalLoading = false;
        draft.paymentReversalSuccess = true;
        break;
      }
      case '@clients/PAYMENT_REVERSAL_FAILURE': {
        draft.paymentReversalLoading = false;
        draft.paymentReversalSuccess = false;
        break;
      }
      case '@clients/EDIT_FACEBOOK_INSTAGRAM_ID_REQUEST': {
        draft.editFacebookInstagramIdLoading = true;
        draft.editFacebookInstagramIdSuccess = false;
        break;
      }
      case '@clients/EDIT_FACEBOOK_INSTAGRAM_ID_SUCCESS': {
        draft.editFacebookInstagramIdLoading = false;
        draft.editFacebookInstagramIdSuccess = true;
        break;
      }
      case '@clients/EDIT_FACEBOOK_INSTAGRAM_ID_FAILURE': {
        draft.editFacebookInstagramIdLoading = false;
        draft.editFacebookInstagramIdSuccess = false;
        break;
      }
      case '@clients/GET_LOCALITIES_REQUEST': {
        draft.localities = [];
        draft.localitiesLoading = true;
        draft.localitiesSuccess = false;
        break;
      }
      case '@clients/GET_LOCALITIES_SUCCESS': {
        draft.localities = action.payload.localities;
        draft.localitiesLoading = false;
        draft.localitiesSuccess = true;
        break;
      }
      case '@clients/GET_LOCALITIES_FAILURE': {
        draft.localities = [];
        draft.localitiesLoading = false;
        draft.localitiesSuccess = false;
        break;
      }
      case '@clients/GET_DISTRICTS_REQUEST': {
        draft.districts = [];
        draft.districtsLoading = true;
        draft.districtsSuccess = false;
        break;
      }
      case '@clients/GET_DISTRICTS_SUCCESS': {
        draft.districts = action.payload.districts;
        draft.districtsLoading = false;
        draft.districtsSuccess = true;
        break;
      }
      case '@clients/GET_DISTRICTS_FAILURE': {
        draft.districts = [];
        draft.districtsLoading = false;
        draft.districtsSuccess = false;
        break;
      }
      case '@clients/ADD_DISTRICT_REQUEST': {
        draft.addDistrictLoading = true;
        draft.addDistrictSuccess = false;
        break;
      }
      case '@clients/ADD_DISTRICT_SUCCESS': {
        draft.addDistrictLoading = false;
        draft.addDistrictSuccess = true;
        break;
      }
      case '@clients/ADD_DISTRICT_FAILURE': {
        draft.addDistrictLoading = false;
        draft.addDistrictSuccess = false;
        break;
      }
      case '@clients/GET_ORDERS_REQUEST': {
        draft.ordersLoading = true;
        draft.orders = [];
        draft.ordersError = false;
        break;
      }
      case '@clients/GET_ORDERS_SUCCESS': {
        draft.ordersLoading = false;
        draft.orders = action.payload.orders;
        draft.ordersError = false;
        break;
      }
      case '@clients/GET_ORDERS_FAILURE': {
        draft.ordersLoading = false;
        draft.orders = [];
        draft.ordersError = false;
        break;
      }
      case '@clients/DELETE_ORDERS_REQUEST': {
        draft.deleteOrdersLoading = true;
        draft.deleteOrdersSuccess = false;
        break;
      }
      case '@clients/DELETE_ORDERS_SUCCESS': {
        draft.deleteOrdersLoading = false;
        draft.deleteOrdersSuccess = true;
        break;
      }
      case '@clients/DELETE_ORDERS_FAILURE': {
        draft.deleteOrdersLoading = false;
        draft.deleteOrdersSuccess = false;
        break;
      }
      case '@clients/DISTRICT_CLEAR_SUCCESS': {
        draft.localitiesSuccess = false;
        draft.districtsSuccess = false;
        draft.addDistrictSuccess = false;
        break;
      }
      case '@clients/CLEAR_SUCCESS': {
        draft.updateStoreDataSuccess = false;
        draft.updateRepresentativeDataSuccess = false;
        draft.storeStatusSuccess = false;
        draft.updateStoreSubscriptionSuccess = false;
        draft.clients = [];
        draft.orders = [];
        draft.nearClients = {length: 0};
        draft.deleteOrdersSuccess = false;
        draft.manualConciliationSuccess = false;
        draft.removeCreditCardSuccess = false;
        draft.addKuppiBoostBalanceSuccess = false;
        break;
      }
      case '@clients/CLEAR_CLIENT_REQUEST_ERRORS': {
        draft.subscriptionsError = false;
        draft.kuppiBoostDataError = false;
        draft.campaignsDataError = false;
        draft.whatsappDataError = false;
        draft.pixelOwnDomainDataError = false;
        draft.massMessageDataError = false;
        break;
      }
      case '@clients/CLEAR_CLIENT_DATA_SUCCESS': {
        draft.clientDataSuccess = false;
        break;
      }
      case '@clients/GET_PIXEL_OWN_DOMAIN_DATA_REQUEST': {
        draft.pixelOwnDomainDataLoading = true;
        draft.pixelOwnDomainDataSuccess = false;
        draft.pixelOwnDomainDataError = false;
        draft.pixelOwnDomainData = {};
        break;
      }
      case '@clients/GET_PIXEL_OWN_DOMAIN_DATA_SUCCESS': {
        draft.pixelOwnDomainDataLoading = false;
        draft.pixelOwnDomainDataSuccess = true;
        draft.pixelOwnDomainDataError = false;
        draft.pixelOwnDomainData = action.payload.pixelOwnDomainData;
        break;
      }
      case '@clients/GET_PIXEL_OWN_DOMAIN_DATA_FAILURE': {
        draft.pixelOwnDomainDataLoading = false;
        draft.pixelOwnDomainDataSuccess = false;
        draft.pixelOwnDomainDataError = true;
        draft.pixelOwnDomainData = {};
        break;
      }
      case '@clients/UPDATE_PIXELS_LIST_REQUEST': {
        draft.updatePixelsListLoading = true;
        draft.updatePixelsListSuccess = false;
        break;
      }
      case '@clients/UPDATE_PIXELS_LIST_SUCCESS': {
        draft.updatePixelsListLoading = false;
        draft.updatePixelsListSuccess = true;
        draft.pixelOwnDomainData = action.payload.pixelOwnDomainData;
        break;
      }
      case '@clients/UPDATE_PIXELS_LIST_FAILURE': {
        draft.updatePixelsListLoading = false;
        draft.updatePixelsListSuccess = false;
        break;
      }
      case '@clients/CLEAR_UPDATE_PIXELS_LIST_SUCCESS': {
        draft.addDomainSuccess = false;
        break;
      }
      case '@clients/ADD_DOMAIN_REQUEST': {
        draft.addDomainLoading = true;
        draft.addDomainSuccess = false;
        break;
      }
      case '@clients/ADD_DOMAIN_SUCCESS': {
        draft.addDomainLoading = false;
        draft.addDomainSuccess = true;
        draft.pixelOwnDomainData = action.payload.pixelOwnDomainData;
        break;
      }
      case '@clients/ADD_DOMAIN_FAILURE': {
        draft.addDomainLoading = false;
        draft.addDomainSuccess = false;
        break;
      }
      case '@clients/CLEAR_ADD_DOMAIN_SUCCESS': {
        draft.addDomainSuccess = false;
        break;
      }
      case '@clients/LINK_WEB_MENU_DOMAIN_REQUEST': {
        draft.linkWebMenuDomainLoading = true;
        break;
      }
      case '@clients/LINK_WEB_MENU_DOMAIN_SUCCESS': {
        draft.linkWebMenuDomainLoading = false;
        draft.pixelOwnDomainData = action.payload.pixelOwnDomainData;
        break;
      }
      case '@clients/LINK_WEB_MENU_DOMAIN_FAILURE': {
        draft.linkWebMenuDomainLoading = false;
        break;
      }
      case '@clients/REMOVE_DOMAIN_REQUEST': {
        draft.removeDomainLoading = true;
        draft.removeDomainSuccess = false;
        break;
      }
      case '@clients/REMOVE_DOMAIN_SUCCESS': {
        draft.removeDomainLoading = false;
        draft.removeDomainSuccess = true;
        draft.pixelOwnDomainData = action.payload.pixelOwnDomainData;
        break;
      }
      case '@clients/REMOVE_DOMAIN_FAILURE': {
        draft.removeDomainLoading = false;
        draft.removeDomainSuccess = false;
        break;
      }
      case '@clients/CLEAR_REMOVE_DOMAIN_SUCCESS': {
        draft.removeDomainLoading = false;
        break;
      }
      case '@clients/GET_WHATSAPP_DATA_REQUEST': {
        draft.whatsappDataLoading = true;
        draft.whatsappDataSuccess = false;
        draft.whatsappDataError = false;
        draft.whatsappData = {};
        break;
      }
      case '@clients/GET_WHATSAPP_DATA_SUCCESS': {
        draft.whatsappDataLoading = false;
        draft.whatsappDataSuccess = true;
        draft.whatsappDataError = false;
        draft.whatsappData = action.payload.whatsappData;
        break;
      }
      case '@clients/GET_WHATSAPP_DATA_FAILURE': {
        draft.whatsappDataLoading = false;
        draft.whatsappDataSuccess = false;
        draft.whatsappDataError = true;
        draft.whatsappData = {};
        break;
      }
      case '@clients/FORCE_WHATSAPP_DISCONNECTION_REQUEST': {
        draft.forceWhatsappDisconnectionLoading = true;
        draft.forceWhatsappDisconnectionSuccess = false;
        break;
      }
      case '@clients/FORCE_WHATSAPP_DISCONNECTION_SUCCESS': {
        draft.forceWhatsappDisconnectionLoading = false;
        draft.forceWhatsappDisconnectionSuccess = true;
        draft.whatsappData = action.payload.whatsappData;
        break;
      }
      case '@clients/FORCE_WHATSAPP_DISCONNECTION_FAILURE': {
        draft.forceWhatsappDisconnectionLoading = false;
        draft.forceWhatsappDisconnectionSuccess = false;
        break;
      }
      case '@clients/CLEAR_FORCE_WHATSAPP_DISCONNECTION_SUCCESS': {
        draft.forceWhatsappDisconnectionSuccess = false;
        break;
      }
      case '@clients/GET_MASS_MESSAGE_CLIENT_DATA_REQUEST': {
        draft.massMessageDataLoading = true;
        draft.massMessageDataSuccess = false;
        draft.massMessageData = {};
        draft.massMessageDataError = false;
        break;
      }
      case '@clients/GET_MASS_MESSAGE_CLIENT_DATA_SUCCESS': {
        draft.massMessageDataLoading = false;
        draft.massMessageDataSuccess = true;
        draft.massMessageData = action.payload.massMessageData;
        draft.massMessageDataError = false;
        break;
      }
      case '@clients/GET_MASS_MESSAGE_CLIENT_DATA_FAILURE': {
        draft.massMessageDataLoading = false;
        draft.massMessageDataSuccess = false;
        draft.massMessageData = {};
        draft.massMessageDataError = true;
        break;
      }
      case '@clients/GET_MASS_MESSAGE_CLIENT_CAMPAIGNS_REQUEST': {
        draft.massMessageCampaignsDataLoading = true;
        draft.massMessageCampaignsDataSuccess = false;
        draft.massMessageCampaignsData = [];
        draft.massMessagePaymentsData = [];
        draft.massMessageBonusData = [];
        draft.massMessageCampaignsDataError = false;
        break;
      }
      case '@clients/GET_MASS_MESSAGE_CLIENT_CAMPAIGNS_SUCCESS': {
        draft.massMessageCampaignsDataLoading = false;
        draft.massMessageCampaignsDataSuccess = true;
        draft.massMessageCampaignsData = action.payload.campaignsData.campaigns;
        draft.massMessagePaymentsData = action.payload.campaignsData.payments_data;
        draft.massMessageBonusData = action.payload.campaignsData.bonus_data;
        draft.massMessageCampaignsDataError = false;
        break;
      }
      case '@clients/GET_MASS_MESSAGE_CLIENT_CAMPAIGNS_FAILURE': {
        draft.massMessageCampaignsDataLoading = false;
        draft.massMessageCampaignsDataSuccess = false;
        draft.massMessageCampaignsData = [];
        draft.massMessagePaymentsData = [];
        draft.massMessageBonusData = [];
        draft.massMessageCampaignsDataError = true;
        break;
      }
      case '@clients/CAMPAIGN_ACTION_REQUEST': {
        draft.campaignActionLoading = true;
        draft.campaignActionSuccess = false;
        break;
      }
      case '@clients/CAMPAIGN_ACTION_SUCCESS': {
        draft.campaignActionLoading = false;
        draft.campaignActionSuccess = true;
        draft.massMessageCampaignsData = action.payload.campaignsData.campaigns;
        draft.massMessagePaymentsData = action.payload.campaignsData.payments_data;
        draft.massMessageBonusData = action.payload.campaignsData.bonus_data;
        break;
      }
      case '@clients/CAMPAIGN_ACTION_FAILURE': {
        draft.campaignActionLoading = false;
        draft.campaignActionSuccess = false;
        break;
      }
      case '@clients/CLEAR_CAMPAIGN_ACTION_SUCCESS': {
        draft.addDomainSuccess = false;
        break;
      }
      case '@clients/ADD_MASS_MESSAGE_CLIENT_BALANCE_REQUEST': {
        draft.addMassMessageBalanceLoading = true;
        draft.addMassMessageBalanceSuccess = false;
        break;
      }
      case '@clients/ADD_MASS_MESSAGE_CLIENT_BALANCE_SUCCESS': {
        draft.addMassMessageBalanceLoading = false;
        draft.addMassMessageBalanceSuccess = true;
        break;
      }
      case '@clients/ADD_MASS_MESSAGE_CLIENT_BALANCE_FAILURE': {
        draft.addMassMessageBalanceLoading = false;
        draft.addMassMessageBalanceSuccess = false;
        break;
      }
      case '@clients/EDIT_MASS_MESSAGE_CLIENT_REQUEST': {
        draft.editMassMessageClientLoading = true;
        draft.editMassMessageClientSuccess = false;
        break;
      }
      case '@clients/EDIT_MASS_MESSAGE_CLIENT_SUCCESS': {
        draft.editMassMessageClientLoading = false;
        draft.editMassMessageClientSuccess = true;
        break;
      }
      case '@clients/EDIT_MASS_MESSAGE_CLIENT_FAILURE': {
        draft.editMassMessageClientLoading = false;
        draft.editMassMessageClientSuccess = false;
        break;
      }
      case '@clients/CLEAR_MASS_MESSAGE_CLIENT_SUCCESS': {
        draft.addMassMessageBalanceSuccess = false;
        draft.editMassMessageClientSuccess = false;
        break;
      }
      case '@clients/CLEAR_CACHE_REQUEST': {
        draft.clearCacheLoading = true;
        break;
      }
      case '@clients/CLEAR_CACHE_REQUEST_SUCCESS':
      case '@clients/CLEAR_CACHE_REQUEST_FAILURE': {
        draft.clearCacheLoading = false;
        break;
      }
      case '@clients/ADVERTISER_ADMIN_LOGIN_REQUEST': {
        draft.advertiserAdminLoginLoading = true;
        break;
      }
      case '@clients/ADVERTISER_ADMIN_LOGIN_REQUEST_SUCCESS':
      case '@clients/ADVERTISER_ADMIN_LOGIN_REQUEST_FAILURE': {
        draft.advertiserAdminLoginLoading = false;
        break;
      }
      default:
    }
  });
}
