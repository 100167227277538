import styled from 'styled-components';

import { Input, Select } from '@rocketseat/unform';
import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi21,
  cinzaKuppi4,
  cinzaKuppi7,
  cinzaKuppi3,
  vermelhoKuppi1,
  preto,
} from '../../../styles/colors';


export const SearchInput = styled(Input)`
  padding: ${px2vw(2)} !important;
  border: ${px2vw(2)} solid ${cinzaKuppi4} !important;
  border-radius: ${px2vw(5)} ${px2vw(0)} ${px2vw(0)} ${px2vw(5)} !important;
  font-size: ${px2vw(14)} !important;
  font-family: AvenirNextLTPro;
  font-weight: normal !important;
  letter-spacing: 0.36px !important;

  ::placeholder {
    color: ${cinzaKuppi7} !important;
  }

  :focus {
    border: ${px2vw(2)} solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
      width: ${props.width} !important;
      height: ${props.height} !important;
      margin-top: ${props.margtop} !important;
      border: ${props.border} !important;
      border-radius: ${props.borderRadius} !important;
   `};

  ${(props) =>
    props.disabled &&
    `
      background: ${cinzaKuppi4} !important;
      color: ${cinzaKuppi3} !important;
      border: 1px solid ${cinzaKuppi3} !important;
    `};

  ${(props) =>
    props.empty &&
    `
      border: 1px solid ${vermelhoKuppi1} !important;
    `};
`;

export const SearchButton = styled.button`
  border: none;
  background-color: ${verdeKuppi2};
  color: ${branco};
  font-weight: bold;
  font-size: ${px2vw(14)};
  width: ${px2vw(150)};
  height: ${px2vw(30)};
  border-radius: ${px2vw(0)} ${px2vw(5)} ${px2vw(5)} ${px2vw(0)};

  ${(props) => props.nearClients && `
    width: ${px2vw(150)};
    border-radius: ${px2vw(5)};
  `}
`;

export const SelectInput = styled(Select)`
  height: ${px2vw(35)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  border: 1px solid ${cinzaKuppi4};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    background-color: ${branco};
    border: ${props.border} !important;
    border-radius: ${px2vw(5)}
  `};
`;

export const InputTitle = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  display: inline-block;

  ${(props) => ` 
    color: ${props.color};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    font-size: ${props.fontsize};
  `};

  ${(props) =>
    props.isMobile &&
    `
    font-size: ${px2vw(2)};
  `};
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${branco};
  padding: ${px2vw(15)};
  border-radius: ${px2vw(10)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
  `}

  ${(props) => props.justifyStart && `justify-content: flex-start;`};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;