import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RealTimeDimensions from '../../utils/responsiveness/RealTimeDimensions';

import MonthlyPaymentsMetricsMobile from './Mobile';
import MonthlyPaymentsMetricsWeb from './Web';

import {
  getMonthlyPaymentMetricsRequest,
} from '../../store/modules/monthlyPaymentMetrics/actions';

export default function MonthlyPaymentsMetrics() {
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;
  const [activeMonth, setActiveMonth] = useState('current');
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().toLocaleString('default', { month: 'long' });
  const [previousYear, setPreviousYear] = useState('');
  const [previousMonth, setPreviousMonth] = useState('');
  const [previousYear2, setPreviousYear2] = useState('');
  const [previousMonth2, setPreviousMonth2] = useState('');
  const [previousYear3, setPreviousYear3] = useState('');
  const [previousMonth3, setPreviousMonth3] = useState('');
  const monthlyPaymentMetrics = useSelector((state) => state.monthlyPaymentMetrics.monthlyPaymentMetrics);
  const getMonthlyPaymentMetricsLoading = useSelector((state) => state.monthlyPaymentMetrics.getMonthlyPaymentMetricsLoading);
  const dispatch = useDispatch();

  String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  useEffect(() => {
    // Define the previous date
    const previousDate = new Date();
    previousDate.setMonth(new Date().getMonth() -1);
    setPreviousYear(previousDate.getFullYear());
    setPreviousMonth(previousDate.toLocaleString('default', { month: 'long' }));

    const previousDate2 = new Date();
    previousDate2.setMonth(new Date().getMonth() -2);
    setPreviousYear2(previousDate2.getFullYear());
    setPreviousMonth2(previousDate2.toLocaleString('default', { month: 'long' }));

    const previousDate3 = new Date();
    previousDate3.setMonth(new Date().getMonth() -3);
    setPreviousYear3(previousDate3.getFullYear());
    setPreviousMonth3(previousDate3.toLocaleString('default', { month: 'long' }));

    // Get metrics data
    dispatch(getMonthlyPaymentMetricsRequest(
      'current', 
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeMonthMetric(monthType) {
    setActiveMonth(monthType);

    // Get metrics data
    dispatch(getMonthlyPaymentMetricsRequest(
      monthType, 
    ));
  };

  const handler = {
    activeMonth: activeMonth,
    currentYear: currentYear,
    currentMonth: currentMonth,
    previousYear: previousYear,
    previousMonth: previousMonth,
    previousYear2: previousYear2,
    previousMonth2: previousMonth2,
    previousYear3: previousYear3,
    previousMonth3: previousMonth3,
    monthlyPaymentMetrics: monthlyPaymentMetrics,
    getMonthlyPaymentMetricsLoading: getMonthlyPaymentMetricsLoading,
    changeMonthMetric: (monthType) => changeMonthMetric(monthType)
  }

  if (isMobile) {
    return <MonthlyPaymentsMetricsMobile handler={handler} />
  } else {
    return <MonthlyPaymentsMetricsWeb handler={handler} />
  }
}
