import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../styles/common';
import { cinzaKuppi4, preto } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import {
  CancelButton,
  ChoiceButton,
  ChoiceButtonSelected,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
  SelectInput,
} from '../EditResponsibleDataModal/styles';

import closeButtonImg from '../../assets/images/close_button/close_button.png';

import px2vw from '../../utils/responsiveness/px2vw';
import { amountMask } from '../../utils/masks';

import Modal from '../Modal';
import RemoveCreditCardModal from '../RemoveCreditCardModal';

export default function EditSubscriptionDataModal({ handler }) {
  const { updateStoreSubscriptionLoading } = useSelector(
    (state) => state.clients
  );

  const recurring = useSelector((state) => state.clients.recurring);

  useEffect(() => {
    if (!handler.hasPendingPaymentSubscription) {
      handler.setSignatureUpdatePeriod('only_future');
    } else {
      handler.setSignatureUpdatePeriod('');
    }

    const subscriptionIsActive = handler.newSubscriptionList.find((subscription) => subscription.id === handler.subscriptionId)
    if (!subscriptionIsActive) {
      handler.setSubscriptionId(handler.newSubscriptionList[0].id)
    }
  }, []);

  function handleCloseModal() {
    handler.setEditSubscriptionData(false);
    handler.setSubscriptionAmount(amountMask(recurring.amount.toFixed(2)));
    handler.setSubscriptionId(recurring.subscription_id);
  }

  return (
    <Modal
      isVisible={handler.editSubscriptionData}
      width={px2vw(500)}
      height="auto"
    >
      <ModalHeader>
        <ModalTitleText>Alterar dados da Assinatura</ModalTitleText>
        <CloseButton
          onClick={handleCloseModal}
          disabled={updateStoreSubscriptionLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handleChangeSubscriptionSubmit}>
        <Row margtop={px2vw(20)} spaceAround>
          <Col width="auto">
            <Col margbottom={px2vw(15)} width="auto">
              <InputTitle>Meses na Kuppi</InputTitle>
              <InputData
                name="months"
                type="text"
                disabled={true}
                width={px2vw(130)}
                value={
                  handler.monthsWithKuppi > 1
                    ? `${handler.monthsWithKuppi} meses`
                    : handler.monthsWithKuppi === 1
                    ? `${handler.monthsWithKuppi} mês`
                    : `${handler.monthsWithKuppi}`
                }
              />
            </Col>
            <Col width="auto">
              <InputTitle>Valor mensal</InputTitle>
              <InputData
                width={px2vw(130)}
                name="amount"
                type="text"
                maxLength="9"
                placeholder="R$ 200,00"
                value={handler.subscriptionAmount}
                onChange={(e) => {
                  handler.setSubscriptionAmount(amountMask(e.target.value));
                }}
              />
            </Col>
          </Col>
          <Col width="auto">
            <Col margbottom={px2vw(15)} width="auto">
              <InputTitle>Plano do cliente</InputTitle>
              <SelectInput
                padright={px2vw(10)}
                padleft={px2vw(10)}
                height={px2vw(30)}
                width={px2vw(250)}
                name="SubscriptionSelect"
                placeholder="Altere o Plano"
                options={handler.newSubscriptionList}
                value={handler.subscriptionId}
                onChange={(e) => {
                  handler.setSubscriptionId(e.target.value);
                }}
              />
            </Col>
            <Col width="auto">
              {handler.hasPendingPaymentSubscription && (
                <>
                  <Row
                    margtop={px2vw(10)}
                    justifyStart
                    pointer
                    onClick={() =>
                      handler.setSignatureUpdatePeriod('only_current')
                    }
                  >
                    <Row
                      width="auto"
                      height="auto"
                      margright={px2vw(5)}
                      pointer
                    >
                      <ChoiceButton />
                      <ChoiceButtonSelected
                        isChecked={
                          handler.signatureUpdatePeriod === 'only_current'
                        }
                      />
                    </Row>
                    <InputTitle
                      fontweight="normal"
                      cursor="pointer"
                      fontSize={px2vw(12)}
                    >
                      Alterar apenas para a cobrança do mês vigente
                    </InputTitle>
                  </Row>
                  <Row
                    margtop={px2vw(5)}
                    justifyStart
                    pointer
                    onClick={() =>
                      handler.setSignatureUpdatePeriod('current_and_future')
                    }
                  >
                    <Row
                      width="auto"
                      height="auto"
                      margright={px2vw(5)}
                      pointer
                    >
                      <ChoiceButton />
                      <ChoiceButtonSelected
                        isChecked={
                          handler.signatureUpdatePeriod === 'current_and_future'
                        }
                      />
                    </Row>
                    <InputTitle
                      fontweight="normal"
                      cursor="pointer"
                      fontSize={px2vw(12)}
                    >
                      Alterar essa e as futuras cobranças
                    </InputTitle>
                  </Row>
                </>
              )}
              {!handler.hasPendingPaymentSubscription && (
                <>
                  <Row margtop={px2vw(20)} justifyStart pointer>
                    <Row
                      width="auto"
                      height="auto"
                      margright={px2vw(5)}
                      pointer
                    >
                      <ChoiceButton />
                      <ChoiceButtonSelected
                        isChecked={!handler.hasPendingPaymentSubscription}
                      />
                    </Row>
                    <InputTitle
                      fontweight="normal"
                      cursor="pointer"
                      fontSize={px2vw(12)}
                    >
                      Alterar as futuras cobranças
                    </InputTitle>
                  </Row>
                </>
              )}
            </Col>
          </Col>
        </Row>

        {recurring.payment_type === 'credit_card' && (
          <>
            <Col margtop={px2vw(20)} width="auto">
              <InputTitle>Remover cartão de decrédito</InputTitle>
            </Col>
            <Row
              // width={px2vw(450)}
              width="auto"
              margtop={px2vw(5)}
              border={`${px2vw(2)} solid ${cinzaKuppi4}`}
              borderRadius={px2vw(5)}
              pad={px2vw(10)}
            >
              <CancelButton
                width={px2vw(225)}
                type="button"
                onClick={() => handler.setRemoveCreditCard()}
              >
                Remover cartão de crédito
              </CancelButton>
            </Row>
          </>
        )}

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton
              onClick={handleCloseModal}
              disabled={updateStoreSubscriptionLoading}
            >
              Cancelar
            </CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton disabled={updateStoreSubscriptionLoading}>
              {updateStoreSubscriptionLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
      {handler.removeCreditCard && <RemoveCreditCardModal handler={handler} />}
    </Modal>
  );
}
