import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  cinzaKuppi1,
  cinzaKuppi4
} from '../../../styles/colors';

export const CampaignStatsBox = styled.div`
  background-color: ${cinzaKuppi1};
  border-radius: ${px2vw(10)};
  border: none;
  width: ${px2vw(300)};
  height: ${px2vw(380)};
  padding: ${px2vw(15)};
`;

export const ImageBox = styled.div`
  position: relative;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-top-left-radius: ${(props) => props.btlRadius && props.btlRadius};
  border-top-right-radius: ${(props) => props.btrRadius && props.btrRadius};
  border-bottom-left-radius: ${(props) => props.bblRadius && props.bblRadius};
  border-bottom-right-radius: ${(props) => props.bbrRadius && props.bbrRadius};
`;

export const AdsFooter = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: ${px2vw(6)};
  background: ${cinzaKuppi4};
  width: ${px2vw(247)};
  height: ${px2vw(68)};
`;

export const AdsHeader = styled.header`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  padding: ${px2vw(0)} ${px2vw(8)};
  padding-top: ${px2vw(5)};
  width: ${px2vw(247)};
  height: ${px2vw(68)};
`;

export const AdsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: ${(props) => props.margin ? props.margin : `0 ${px2vw(70)} 0 ${px2vw(10)}`};
  background: ${cinzaKuppi1};
  width: ${px2vw(247)};
  height: ${px2vw(380)};
  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const Modal = styled.div`
  position: relative;
  padding: 30px;
  background: ${branco};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;

  width: 750px;
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  min-width: ${(props) => props.minWidth};
`;

export const CloseButton = styled.div`
  border: none;
  background: transparent;
  cursor: pointer;

  i {
    font-size: 40px;
  }
`;
