import produce from 'immer';

const INITIAL_STATE = {
  clientsWithUnlimitedPackage: [],
  perContactAmounts: [],
  massMessageGeneralLoading: false,
  updateMassMessageGeneralAmountLoading: false,
  updateMassMessageGeneralAmountSuccess: false,
};

export default function massMessageGeneral(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@massMessageGeneral/GET_MASS_MESSAGE_GENERAL_REQUEST': {
        draft.massMessageGeneralLoading = true;
        draft.clientsWithUnlimitedPackage = [];
        draft.perContactAmounts = [];
        break;
      }
      case '@massMessageGeneral/GET_MASS_MESSAGE_GENERAL_SUCCESS': {
        draft.massMessageGeneralLoading = false;
        draft.clientsWithUnlimitedPackage = action.payload.massMessageGeneral.clients_with_unlimited_package;
        draft.perContactAmounts = action.payload.massMessageGeneral.per_contact_amounts;
        break;
      }
      case '@massMessageGeneral/GET_MASS_MESSAGE_GENERAL_FAILURE': {
        draft.massMessageGeneralLoading = false;
        draft.clientsWithUnlimitedPackage = [];
        draft.perContactAmounts = [];
        break;
      }
      case '@massMessageGeneral/UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_REQUEST': {
        draft.updateMassMessageGeneralAmountLoading = true;
        draft.updateMassMessageGeneralAmountSuccess = false;
        break;
      }
      case '@massMessageGeneral/UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_SUCCESS': {
        draft.updateMassMessageGeneralAmountLoading = false;
        draft.updateMassMessageGeneralAmountSuccess = true;
        draft.massMessageGeneral = action.payload.massMessageGeneral;
        break;
      }
      case '@massMessageGeneral/UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_FAILURE': {
        draft.updateMassMessageGeneralAmountLoading = false;
        draft.updateMassMessageGeneralAmountSuccess = false;
        break;
      }
      case '@massMessageGeneral/CLEAR_UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_SUCCESS': {
        draft.updateMassMessageGeneralAmountSuccess = false;
        break;
      }
      default:
    }
  });
}
