import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../../../../../styles/common';
import { preto } from '../../../../../../styles/colors';
import { Col, Row } from '../../../../../../styles/grid';
import { CloseButton, ModalTitleText } from '../../../../../../components/Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../../../../../../components/EditResponsibleDataModal/styles';

import closeButtonImg from '../../../../../../assets/images/close_button/close_button.png';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import Modal from '../../../../../../components/Modal';

import { addDomainRequest } from '../../../../../../store/modules/clients/actions';

export default function AddDomainModal({ onClose }) {
  const { addDomainLoading, clientData } = useSelector(
    (state) => state.clients
  );
  const [addDomain, setAddDomain] = useState('');
  const dispatch = useDispatch();

  function handleAddDomain() {
    let newDomain = addDomain;
    if (addDomain.startsWith('www.')) {
      newDomain = addDomain.replace('www.', '')
    }
      
    dispatch(addDomainRequest(clientData?.id, newDomain));
  }

  return (
    <Modal
      isVisible
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={onClose}
          disabled={addDomainLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Adicionar Domínio</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Insira o domínio a ser cadastrado sem o www (para: www.kuppi.com.br, colocar: kuppi.com.br):
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="add"
          type="text"
          placeholder="Insira o domínio a ser cadastrado sem o www"
          width={px2vw(400)}
          value={addDomain}
          onChange={(e) => setAddDomain(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <ConfirmButton
            onClick={() => handleAddDomain()} 
            disabled={addDomainLoading || addDomain.trim().length == 0}
          >
            {addDomainLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : ('Adicionar')}
          </ConfirmButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <CancelButton  
            onClick={onClose}
            disabled={addDomainLoading}
          >
            Voltar
          </CancelButton>
        </Col>
      </Row>
    </Modal>
  );
}
