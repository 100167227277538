import styled from 'styled-components';
import { branco, verdeKuppi1 } from '../../styles/colors';
import px2vw from '../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const growth = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: auto;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  background-color: ${branco};
  width: 100%;
  height: auto;
  margin-top: ${px2vw(10)};
  margin-bottom: ${px2vw(10)};
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  cursor: pointer;
`;

export const TextContent = styled.div`
  width: ${px2vw(150)};
  font-size: 15px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  animation-name: ${growth};
  animation-duration: 0.4s;
  overflow: clip;
  margin-top: ${px2vw(10)};
  margin-left: ${px2vw(20)};
`;

export const Text = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  animation-name: ${growth};

  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}

  ${(props) =>
    props.optionactive &&
    `
    color: ${verdeKuppi1};
   
  `}
`;

export const MenuIconBox = styled.div`
  position: relative;
  width: ${px2vw(23)};
  height: ${px2vw(23)};
  margin-right: ${px2vw(10)};

  ${(props) =>
    props.optionactive &&
    `
  .icon {
      filter: invert(59%) sepia(83%) saturate(389%) hue-rotate(106deg) brightness(93%) contrast(85%);
    }
    `}
`;

export const MenuIconImg = styled.img`
  width: 100%;
  height: 100%;
`;
