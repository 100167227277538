import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../styles/common';
import { preto } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';
import { CloseButton, ModalTitleText } from '../Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';

import closeButtonImg from '../../assets/images/close_button/close_button.png';

import px2vw from '../../utils/responsiveness/px2vw';

import Modal from '../Modal';

import { generateNewPasswordRequest } from '../../store/modules/clients/actions';

export default function GenerateNewPassWordModal({ handler }) {
  const { generateNewPasswordLoading } = useSelector(
    (state) => state.clients
  );
  const [newPasswordTextConfirmation, setNewPasswordTextConfirmation] = useState('');
  const dispatch = useDispatch();

  function handleCloseModal() {
    handler.setGenerateNewPassword(false);
  }


  return (
    <Modal
      isVisible={handler.generateNewPassword}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton
          onClick={handleCloseModal}
          disabled={generateNewPasswordLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Gerar nova senha</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Tem certeza que deseja gerar uma nova senha?
        </InputTitle>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite 'gerar nova senha' para concluir
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="cancel"
          type="text"
          placeholder="gerar nova senha"
          width={px2vw(400)}
          value={newPasswordTextConfirmation}
          onChange={(e) => setNewPasswordTextConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (newPasswordTextConfirmation === 'gerar nova senha') {
                dispatch(generateNewPasswordRequest(handler.advertiserInfoId));
              }
            }} 
            disabled={newPasswordTextConfirmation !== 'gerar nova senha' || generateNewPasswordLoading}
          >
            {generateNewPasswordLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Gerar Nova Senha'
              )}
            
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={handleCloseModal}
            disabled={generateNewPasswordLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
