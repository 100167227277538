import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../Table';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../utils/utils';

const columns = [
  {
    field: 'per_contact_amount',
    title: 'Valor por Contato',
    width: px2vw(170),
    render: ({ per_contact_amount }) => formatCurrencyBRL(per_contact_amount, {}),
  },
  {
    field: 'is_using_package',
    title: 'Pacote Ilimitado',
    width: px2vw(150),
    render: ({ is_using_package }) => is_using_package ? 'Sim' : 'Não',
  },
  {
    field: 'qty',
    title: 'Qtd. Clientes',
    width: px2vw(150),
    render: ({ qty }) => qty,
  },
];

function MassMessageGeneralAmountTable({ data }) {
  return (
    <Table
      data={data}
      columns={columns}
      clientsType="MassMessageGeneralAmount"
      handler={{}}
    />
  );
}

MassMessageGeneralAmountTable.propTypes = {
  data: PropTypes.array,
};

export default MassMessageGeneralAmountTable;
