import axios from 'axios';
import { getURL } from '../utils/utils';

// Create Axios for Global API
export const apiGlobal = axios.create({
  baseURL: getURL('global_api'),
  timeout: 30000,
});

// Create Axios for Admin API
export const apiAdmin = axios.create({
  baseURL: getURL('admin_api'),
  timeout: 60000,
});
