import produce from 'immer';

const INITIAL_STATE = {
  user: null,
  access: {
    roles: [],
    permissions: [],
  },
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOGIN_SUCCESS':
        draft.user = action.payload.user;
        draft.access = {
          roles: action.payload.user.roles,
          permissions: action.payload.user.permissions,
        };
        break;
      case '@auth/LOG_OUT': {
        draft.user = null;
        break;
      }
      default:
    }
  });
}
