import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../Table';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../utils/utils';
import moment from 'moment';

const columns = [
  {
    field: 'order_id',
    title: 'ID do Pedido',
    width: px2vw(150),
    render: ({ order_id }) => order_id
  },
  {
    field: 'advertiser_name',
    title: 'Anunciante',
    width: px2vw(150),
    render: ({ advertiser_name }) => advertiser_name
  },
  {
    field: 'advertiser_id',
    title: 'ID do Anunciante',
    width: px2vw(150),
    render: ({ advertiser_id }) => advertiser_id
  },
  {
    field: 'created_at',
    title: 'Data',
    width: px2vw(150),
    render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY')
  },
  {
    field: 'total_amount',
    title: 'Valor',
    width: px2vw(150),
    render: ({ total_amount }) => formatCurrencyBRL(total_amount, {})
  },
];

function OrdersTable({ data, handler }) {
  return <Table data={data} columns={columns} clientsType='orders' handler={handler}/>;
}

OrdersTable.propTypes = {
  data: PropTypes.array,
};

export default OrdersTable;