import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../styles/common';
import { cinzaKuppi4, preto } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import {
  CancelButton,
  ChoiceButton,
  ChoiceButtonSelected,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
  SelectInput,
} from '../EditResponsibleDataModal/styles';

import closeButtonImg from '../../assets/images/close_button/close_button.png';

import px2vw from '../../utils/responsiveness/px2vw';
import { amountMask } from '../../utils/masks';

import Modal from '../Modal';

export default function AddBoostBalanceModal({ handler }) {
  const { addKuppiBoostBalanceLoading } = useSelector((state) => state.clients);

  const clientData = useSelector((state) => state.clients.clientData);

  function handleCloseModal() {
    handler.setAddBoostBalance(false);
    handler.setAmountAdded('');
    handler.setMgmId(null);
    handler.setBalanceTypeTitle('');
  }

  return (
    <Modal
      isVisible={handler.addBoostBalance}
      width={px2vw(550)}
      height="auto"
      padding={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Adicionar Saldo no Boost</ModalTitleText>
        <CloseButton onClick={handleCloseModal} disabled={false}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handleAddKuppiBoostBalanceSubmit}>
        <Row margtop={px2vw(20)}>
          <Col margbottom={px2vw(15)}>
            <InputTitle>ID do Anunciante</InputTitle>
            <InputData
              name="advertiserId"
              type="text"
              disabled={true}
              width={px2vw(130)}
              value={clientData.id}
            />
          </Col>
          <Col>
            <InputTitle>Nome</InputTitle>
            <InputData
              name="name"
              type="text"
              disabled={true}
              width={px2vw(290)}
              value={clientData.store_data.name}
            />
          </Col>
        </Row>
        <Row margbottom={px2vw(15)}>
          <Col>
            <InputTitle>Tipo de Saldo</InputTitle>
            <SelectInput
              padright={px2vw(10)}
              padleft={px2vw(10)}
              height={px2vw(30)}
              width={px2vw(180)}
              name="bonusSelect"
              placeholder="Selecione o Bônus"
              options={handler.balanceTypeList}
              value={handler.balanceTypeTitle}
              onChange={(e) => {
                handler.setBalanceTypeTitle(e.target.value);
              }}
            />
          </Col>
          <Col
            margleft={
              handler.balanceTypeTitle !== 'Bônus por Indicação'
                ? px2vw(-50)
                : px2vw(35)
            }
          >
            <InputTitle>Valor a ser adicionado</InputTitle>
            <InputData
              width={px2vw(150)}
              name="amount"
              type="text"
              maxLength="9"
              placeholder="R$ 200,00"
              value={handler.amountAdded}
              onChange={(e) => {
                handler.setAmountAdded(amountMask(e.target.value));
              }}
            />
          </Col>
          {handler.balanceTypeTitle === 'Bônus por Indicação' && (
            <Col alignEnd>
              <InputTitle>ID do indicado</InputTitle>
              <InputData
                width={px2vw(100)}
                name="indicatedID"
                type="text"
                maxLength="9"
                placeholder="123"
                value={handler.mgmId}
                onChange={(e) => {
                  handler.setMgmId(e.target.value);
                }}
              />
            </Col>
          )}
        </Row>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton
              onClick={handleCloseModal}
              disabled={addKuppiBoostBalanceLoading}
            >
              Cancelar
            </CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit">
              {addKuppiBoostBalanceLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
}
