import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../../../../../styles/common';
import { preto } from '../../../../../../styles/colors';
import { Col, Row } from '../../../../../../styles/grid';
import { CloseButton, ModalTitleText } from '../../../../../../components/Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../../../../../../components/EditResponsibleDataModal/styles';

import closeButtonImg from '../../../../../../assets/images/close_button/close_button.png';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import Modal from '../../../../../../components/Modal';

import { updateUserPermissionsRequest, clearUpdateUserPermissionsSuccess } from '../../../../../../store/modules/clients/actions';

export default function HandlePermissionModal({ permissionToHandle, onClose }) {
  const { clientData, updateUserPermissionsLoading, updateUserPermissionsSuccess } = useSelector(
    (state) => state.clients
  );
  const [actionTextConfirmation, setActionTextConfirmation] = useState('');
  const actionText = permissionToHandle?.action === 'remove' ? 'Remover permissão' : 'Adicionar permissão';
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateUserPermissionsSuccess) {
      dispatch(clearUpdateUserPermissionsSuccess())
      onClose();
    }
  }, [updateUserPermissionsSuccess])

  return (
    <Modal
      isVisible
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={onClose}
          disabled={updateUserPermissionsLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>{actionText}</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          {`Tem certeza que deseja ${actionText?.toLowerCase().replace(' ', ' a ')}: '${permissionToHandle?.permissionData?.name}' ?`}
        </InputTitle>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite '{actionText?.toLowerCase()}' para confirmar
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="action"
          type="text"
          placeholder={actionText?.toLowerCase()}
          width={px2vw(400)}
          value={actionTextConfirmation}
          onChange={(e) => setActionTextConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (actionTextConfirmation === actionText?.toLowerCase()) {
                dispatch(updateUserPermissionsRequest(permissionToHandle?.action, clientData?.user_id, permissionToHandle?.permissionData?.id))
              }
            }} 
            disabled={actionTextConfirmation !== actionText?.toLowerCase() || updateUserPermissionsLoading}
          >
            {updateUserPermissionsLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : ('Confirmar')}
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={onClose}
            disabled={updateUserPermissionsLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
