import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../styles/common';
import { preto } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';
import { CloseButton, ModalTitleText } from '../Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';

import closeButtonImg from '../../assets/images/close_button/close_button.png';

import px2vw from '../../utils/responsiveness/px2vw';

import Modal from '../Modal';

import { copyCatalogRequest, clearCopyCatalogSuccess } from '../../store/modules/clients/actions';
import { onlyNumbers } from '../../utils/utils';

export default function CopyCatalogModal({ handler }) {
  const { copyCatalogLoading, copyCatalogSuccess } = useSelector(
    (state) => state.clients
  );
  const [copyCatalogTextConfirmation, setCopyCatalogConfirmation] = useState('');
  const [originClientId, setOriginClientId] = useState('0000');
  const dispatch = useDispatch();

  function handleCloseModal() {
    handler.setCopyCatalogModal(false);
  }

  useEffect(() =>  {
    if (copyCatalogSuccess) {
      dispatch(clearCopyCatalogSuccess())
      handleCloseModal();
    }
  }, [copyCatalogSuccess])

  return (
    <Modal
      isVisible={handler.copyCatalogModal}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={handleCloseModal}
          disabled={copyCatalogLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Copiar catálogo de um cliente para outro</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite o ID do cliente de onde o cardápio será copiado (Origem):
        </InputTitle>
      </Row>
      <Row>
        <InputData
          name="id_origem"
          type="text"
          placeholder="123"
          width={px2vw(400)}
          value={originClientId}
          onChange={(e) => setOriginClientId(onlyNumbers(e.target.value))}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <InputTitle fontweight="normal">
          Para copiar o cardápio do cliente: {originClientId} para o cliente: {handler.advertiserId},
          <br />digite 'de {originClientId} para {handler.advertiserId}':
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="confirmCatalog"
          type="text"
          placeholder="de 123 para 456"
          width={px2vw(400)}
          value={copyCatalogTextConfirmation}
          onChange={(e) => setCopyCatalogConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (originClientId !== '0000' && copyCatalogTextConfirmation === `de ${originClientId} para ${handler.advertiserId}`) {
                dispatch(copyCatalogRequest(handler.advertiserId, originClientId));
              }
            }} 
            disabled={originClientId === '0000' || copyCatalogTextConfirmation !== `de ${originClientId} para ${handler.advertiserId}` || copyCatalogLoading}
          >
            {copyCatalogLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Copiar Cardápio'
              )}
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={handleCloseModal}
            disabled={copyCatalogLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
