import produce from 'immer';

const INITIAL_STATE = {
  monthlyPaymentMetrics: {},
  getMonthlyPaymentMetricsLoading: false,
};

export default function monthlyPaymentMetrics(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@monthlyPaymentMetrics/GET_MONTHLY_PAYMENT_METRICS_REQUEST': {
        draft.monthlyPaymentMetrics = {};
        draft.getMonthlyPaymentMetricsLoading = true;
        break;
      }

      case '@monthlyPaymentMetrics/GET_MONTHLY_PAYMENT_METRICS_SUCCESS': {
        draft.monthlyPaymentMetrics = action.payload.monthlyPaymentMetrics;
        draft.getMonthlyPaymentMetricsLoading = false;
        break;
      }

      case '@monthlyPaymentMetrics/GET_MONTHLY_PAYMENT_METRICS_FAILURE': {
        draft.monthlyPaymentMetrics = {};
        draft.getMonthlyPaymentMetricsLoading = false;
        break;
      }

      default:
    }
  });
}
