import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import {
  IconButton,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { Col, Row } from '../../../../../styles/grid';
import { Text, WhiteBox } from '../../styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';
import HandlePermissionModal from './HandlePermissionModal';

export default function TabPermissions() {
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );
  const {
    getUserPermissionsLoading,
  } = useSelector((state) => state.clients);
  const userPermissionsData = useSelector((state) => state.clients.userPermissions);
  const [permissionToHandle, setPermissionToHandle] = useState(null);
  const [handlePermissionModal, setHandlePermissionModal] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Row justifyStart alignStart margtop={px2vw(20)}>
        {/* User Permissions */}
        <WhiteBox margright={px2vw(10)}>
          <Row justifyStart margbottom={px2vw(10)}>
            <Text fontweight="bold" fontsize={px2vw(13)}>
              Permissões que o usuário já possui
            </Text>
          </Row>
          <Col>
            {!getUserPermissionsLoading && userPermissionsData?.user_permissions && userPermissionsData?.user_permissions?.map((userPermission, idx) => {
              return (
                <Row key={`userPermission-${idx}`} justifyStart>
                  <Text fontsize={px2vw(13)} margtop={px2vw(9)}>
                    • {userPermission.name} - {userPermission.description}
                  </Text>
                  {operationalEditAccess && (
                    <IconButton size="medium" onClick={() => {
                      setPermissionToHandle({
                        action: 'remove',
                        permissionData: userPermission
                      });
                      setHandlePermissionModal(true);
                    }}>
                      <DeleteForeverIcon />
                    </IconButton>
                  )}
                </Row>
              );
            })}
            {!getUserPermissionsLoading && userPermissionsData?.user_permissions?.length === 0 && (
              <Text fontsize={px2vw(13)}>
                Cliente não tem permissões
              </Text>
            )}
            {getUserPermissionsLoading && (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            )}
          </Col>
        </WhiteBox>
        {/* Avaiable Permissions */}
        <WhiteBox margleft={px2vw(10)}>
          <Row justifyStart margbottom={px2vw(10)}>
            <Text fontweight="bold" fontsize={px2vw(13)}>
              Permissões disponíveis para adicionar
            </Text>
          </Row>
          <Col>
            {!getUserPermissionsLoading && userPermissionsData?.available_permissions && userPermissionsData?.available_permissions?.map((availablePermission, idx) => {
              return (
                <Row justifyStart>
                  <Text key={`availablePermission-${idx}`} fontsize={px2vw(13)} margright={px2vw(10)} margtop={px2vw(9)}>
                    • {availablePermission.name} - {availablePermission.description}
                  </Text>
                  {operationalEditAccess && (
                    <IconButton size="medium" onClick={() => {
                      setPermissionToHandle({
                        action: 'add',
                        permissionData: availablePermission
                      });
                      setHandlePermissionModal(true);
                    }}>
                      <AddBoxIcon />
                    </IconButton>
                  )}
                </Row>
              )
            })}
            {!getUserPermissionsLoading && userPermissionsData?.available_permissions?.length === 0 && (
              <Text fontsize={px2vw(13)}>
                Cliente não tem permissões para adicionar
              </Text>
            )}
            {getUserPermissionsLoading && (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            )}
          </Col>
        </WhiteBox>
      </Row>
      {handlePermissionModal && 
        <HandlePermissionModal
          permissionToHandle={permissionToHandle}
          onClose={() => setHandlePermissionModal(false)}
        />
      }
    </>
  );
}
