export const login = '/acesso-admin';
export const dashboard = '/admin/dashboard';
export const dataClients = '/admin/clientes/dados';
export const districts = '/admin/clientes/bairros';
export const orders = '/admin/clientes/pedidos';
export const nearClients = '/admin/clientes/encontrar-proximos'
export const massMessageGeneral = '/admin/disparo-em-massa';
export const kuppiBoostOnboarding = '/admin/kuppi-boost-onboarding';
export const monthPaymentsMetrics = '/Fhohtp0LgFKCHjFVztb7jkSwe8ReOCyu4fLlIyUHBgiO1BoLcr54Fch1F3RIbu2mKM';
// export const churn = '/admin/churn';
