import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../styles/common';
import { preto } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';
import { CloseButton, ModalTitleText } from '../Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';

import closeButtonImg from '../../assets/images/close_button/close_button.png';

import px2vw from '../../utils/responsiveness/px2vw';

import Modal from '../Modal';

import { removeCreditCardRequest } from '../../store/modules/clients/actions';

export default function RemoveCreditCardModal({ handler }) {
  const { removeCreditCardLoading } = useSelector(
    (state) => state.clients
  );
  const [newPasswordTextConfirmation, setNewPasswordTextConfirmation] = useState('');
  const dispatch = useDispatch();

  function handleCloseModal() {
    handler.setRemoveCreditCard(false);
  }


  return (
    <Modal
    isVisible={handler.removeCreditCard}
    width={px2vw(500)}
    height="auto"
    pad={px2vw(15)}
    >

      <Row justifyEnd>
        <CloseButton
          onClick={handleCloseModal}
          disabled={removeCreditCardLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Remover Cartão de Crédito</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Tem certeza que deseja remover o cartão de crédito?
        </InputTitle>
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
                dispatch(removeCreditCardRequest(handler.advertiserInfoId));
              }
            }
            disabled={removeCreditCardLoading}
          >
            {removeCreditCardLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Remover Cartão de Crédito'
              )}
            
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={handleCloseModal}
            disabled={removeCreditCardLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}