import styled from 'styled-components';

import { 
  branco,
  cinzaKuppi2,
  cinzaKuppi7,
  verdeKuppi1
} from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const Content = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const LogoKuppi = styled.img`
  height: 100%;
  width: 100%;
  margin-right: ${px2vw(60)};
  margin-top: ${px2vw(20)};
`;

export const MonthNavigationButton = styled.div`
  background-color: ${cinzaKuppi2};
  height: ${(props) => props.isMobile ? px2vw(120) : px2vw(40)};
  width: ${(props) => props.isMobile ? px2vw(320) : px2vw(130)};
  line-height: ${(props) => props.isMobile ? px2vw(120) : px2vw(40)};
  border-radius: ${(props) => props.isMobile ? px2vw(26) : px2vw(8)};
  margin-right: ${(props) => props.isMobile ? px2vw(30) : px2vw(10)};
  cursor: pointer;
  font-size: ${(props) => props.isMobile ? px2vw(38) : px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi7};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => props.isMobile ? px2vw(30) : px2vw(10)};

  ${(props) =>
    props.isActive &&
    `
    background-color: ${verdeKuppi1};
    color: ${branco};
  `};
`;

export const InfoDataBox = styled.div`
  width: auto;
  height: ${px2vw(100)};
  background-color: ${cinzaKuppi2};
  padding: ${(props) => props.isMobile ? px2vw(40) : px2vw(10)};
  margin-right: ${px2vw(20)};
  border-radius: ${px2vw(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    height: ${props.height};
    width: ${props.width};
  `};
`;

export const InfoDataGroupBox = styled.div`
  width: ${(props) => props.isMobile ? '94%' : 'auto'};
  height: auto;
  background-color: ${cinzaKuppi2};
  padding: ${(props) => props.isMobile ? px2vw(30) : px2vw(15)};
  padding-left: ${(props) => props.isMobile ? px2vw(42) : px2vw(21)};
  padding-right: ${(props) => props.padright ? props.padright : px2vw(22)};
  margin-right: ${(props) => props.isMobile ? px2vw(40) : px2vw(20)};
  border-radius: ${(props) => props.isMobile ? px2vw(16) : px2vw(8)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    height: ${props.height};
    width: ${props.width};
  `};

  ${(props) => props.isMobile && `
    margin-left: ${px2vw(40)};
  `};
`;

export const InfoNumberText = styled.div`
  margin-top: ${px2vw(8)};
  font-size: ${(props) => props.isMobile ? px2vw(48) : px2vw(15)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.36px;
  color: ${verdeKuppi1};
`;

export const InfoTypeText = styled.div`
  font-size: ${(props) => props.isMobile ? px2vw(45) : px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(10)};
  margin-right: ${px2vw(5)};
`;

export const InfoNumberTotalText = styled.div`
  margin-top: ${px2vw(8)};
  font-size: ${(props) => props.isMobile ? px2vw(53) : px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.36px;
  color: ${verdeKuppi1};
`;

export const InfoTypeTotalText = styled.div`
  font-size: ${(props) => props.isMobile ? px2vw(50) : px2vw(17)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(10)};
  margin-right: ${px2vw(5)};

  ${(props) => `
    width: ${props.width};
  `};
`;