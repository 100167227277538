import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { useSelector } from 'react-redux';

import moment from 'moment';

import px2vw from '../../../utils/responsiveness/px2vw';
import { screenAmountMask } from '../../../utils/masks';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
} from './styles';
import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TablePerPage from '../../Tables/TablePerPage';
import { Col, Row } from '../../../styles/grid';

export default function PaymentHistoricTable() {
  const { massMessagePaymentsData } = useSelector((state) => state.clients);
  const [rows, setRows] = useState(massMessagePaymentsData);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('id');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(massMessagePaymentsData);
  }, [massMessagePaymentsData]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  const handleGetPaymentStatus = useCallback(
    (status, processorTransactionId) => {
      if (processorTransactionId == 'bonus') {
        return 'Pago (Bônus)';
      } else {
        switch (status) {
          case 'waiting_payment':
            return 'Pendente';
          case 'paid':
            return 'Pago';
          case 'canceled_payment':
            return 'Cancelado';
          case 'refunded':
            return 'Reembolsado';
        }
      }
    },
    [massMessagePaymentsData]
  );

  const handleGetPaymentType = useCallback(
    (paymentType) => {
      switch (paymentType) {
        case 'pix':
          return 'Pix';
        default:
          return paymentType.toUpperCase()
      }
    },
    [massMessagePaymentsData]
  );

  const handleGetPaymentDateColumn = useCallback(
    (status) => {
      switch (status) {
        case 'waiting_payment':
          return 'pix_expiration_at';
        case 'paid':
          return 'paid_at';
        case 'canceled_payment':
          return 'canceled_at';
        case 'refunded':
          return 'refunded_at';
      }
    },
    [massMessagePaymentsData]
  );

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  return (
    <Col padding="0 15px" alignCenter justifyCenter>
      <Row justifyStart padding="0 10px 0 10px">
        <Header
          width={px2vw(140)}
          padding={`15px 0 15px ${px2vw(35)}`}
          onClick={() => handleSort('advertiser_mass_message_campaign_id')}
        >
          <HeaderButton>
            ID Campanha
            <SvgBox sorted={sortedCol === 'advertiser_mass_message_campaign_id'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)}>
          <HeaderButton>
            Data do Status
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('payment_type')}>
          <HeaderButton>
            Tipo Pagamento
            <SvgBox sorted={sortedCol === 'payment_type'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('status')}>
          <HeaderButton>
            Status
            <SvgBox sorted={sortedCol === 'status'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('amount')}>
          <HeaderButton column>
            <div>
              Valor Original
              <SvgBox sorted={sortedCol === 'amount'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </div>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('discount_amount')}>
          <HeaderButton column>
            <div>
              Desconto
              <SvgBox sorted={sortedCol === 'discount_amount'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </div>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('total_amount')}>
          <HeaderButton column>
            <div>
              Valor Total
              <SvgBox sorted={sortedCol === 'total_amount'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </div>
          </HeaderButton>
        </Header>

        <Header width={px2vw(140)} onClick={() => handleSort('refunded_amount')}>
          <HeaderButton column>
            <div>
              Valor Reembolsado
              <SvgBox sorted={sortedCol === 'refunded_amount'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </div>
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((item, index) =>
          structure[currentPage] &&
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 ? (
            <Fragment key={item.id}>
              <TableRow
                alignCenter
                justifyStart
                padding="0 10px 0 10px"
                minheight={px2vw(35)}
              >
                <Content width={px2vw(140)} padding={`0 0 0 ${px2vw(35)}`}>
                  {item.advertiser_mass_message_campaign_id}
                </Content>
                <Content width={px2vw(120)}>
                  {moment(item[handleGetPaymentDateColumn(item.status)]).format('DD/MM/YYYY')}
                </Content>
                <Content width={px2vw(120)}>
                  {handleGetPaymentType(
                    item.payment_type
                  )}
                </Content>
                <Content width={px2vw(120)}>
                  {handleGetPaymentStatus(
                    item.status,
                    item.processor_transaction_id
                  )}
                </Content>
                <Content width={px2vw(120)}>
                  {screenAmountMask(item.amount.toFixed(2))}
                </Content>
                <Content width={px2vw(120)}>
                  {screenAmountMask(item.discount_amount.toFixed(2))}
                </Content>
                <Content width={px2vw(120)}>
                  {screenAmountMask(item.total_amount.toFixed(2))}
                </Content>
                <Content width={px2vw(140)}>
                  {item.refunded_amount ? screenAmountMask(item.refunded_amount.toFixed(2)) : '-'}
                </Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Row padding="15px 0 10px 0">Nenhum pagamento no momento.</Row>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
