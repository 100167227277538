import React from 'react';
import { useSelector } from 'react-redux';
import px2vw from '../../utils/responsiveness/px2vw';
import Modal from '../Modal';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import closeButtonImg from '../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../styles/grid';
import {
  CancelButton,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';
import { preto } from '../../styles/colors';
import { LoadingBox } from '../../styles/common';
import { Spinner } from 'reactstrap';

export default function EditFacebookInstagramIdModal({ handler }) {
  const {
    editFacebookInstagramIdLoading
  } = useSelector((state) => state.clients);

  const kuppiBoostData = useSelector((state) => state.clients.kuppiBoostData);

  function handleCloseModal() {
    handler.setEditFacebookInstagramId(false);
    handler.setFacebookId(kuppiBoostData.facebook_page_id);
    handler.setInstagramId(kuppiBoostData.instagram_page_id);
  }

  return (
    <Modal
      isVisible={handler.editFacebookInstagramId}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Alterar Facebook/ Instagram ID's</ModalTitleText>
        <CloseButton onClick={handleCloseModal} disabled={editFacebookInstagramIdLoading}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handleEditFacebookInstagramIdSubmit}>
        <Col margtop={px2vw(20)}>
          <InputTitle>Id do Facebook</InputTitle>
          <InputData
            name="facebook ID"
            type="text"
            placeholder="Digite aqui o ID do Facebook"
            value={handler.facebookId}
            onChange={(e) => handler.setFacebookId(e.target.value)}
          />
        </Col>
        <Col margtop={px2vw(10)}>
          <InputTitle>Id do Instagram</InputTitle>
          <InputData
            name="instagram ID"
            type="text"
            placeholder="Digite aqui o ID do Instagram"
            value={handler.instagramId}
            onChange={(e) => handler.setInstagramId(e.target.value)}
          />
        </Col>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton onClick={handleCloseModal} disabled={editFacebookInstagramIdLoading}>Cancelar</CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit" disabled={editFacebookInstagramIdLoading}>
              {editFacebookInstagramIdLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
}
