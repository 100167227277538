import styled from 'styled-components';

import { Input, Form, Select } from '@rocketseat/unform';
import px2vw from '../../utils/responsiveness/px2vw';
import {
  branco,
  verdeKuppi1,
  verdeKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  preto,
  preto2,
  vermelhoKuppi1,
} from '../../styles/colors';

export const InputData = styled(Input)`
  padding: ${px2vw(2)} !important;
  border: ${px2vw(2)} solid ${cinzaKuppi4} !important;
  border-radius: ${px2vw(5)} !important;
  font-size: ${px2vw(14)} !important;
  font-family: AvenirNextLTPro;
  font-weight: normal !important;
  letter-spacing: 0.36px !important;
  width: ${px2vw(450)} !important;
  height: ${px2vw(30)} !important;

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: ${px2vw(2)} solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
      width: ${props.width} !important;
      height: ${props.height} !important;
      margin-top: ${props.margtop} !important;
      border: ${props.border} !important;
      border-radius: ${props.borderRadius} !important;
   `};

  ${(props) =>
    props.disabled &&
    `
      background: ${cinzaKuppi4} !important;
      color: ${preto2} !important;
      border: 1px solid ${cinzaKuppi3} !important;
    `};

  ${(props) =>
    props.empty &&
    `
      border: 1px solid ${vermelhoKuppi1} !important;
    `};
`;

export const InputTitle = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: bold;
  font-size: ${(props) => props.fontSize ? props.fontSize : px2vw(14)};

  ${(props) => `
    font-weight: ${props.fontweight};
    cursor: ${props.cursor};
  `}
`;

export const CancelButton = styled.button`
  border: ${px2vw(2.5)} solid ${preto};
  border-radius: ${px2vw(10)};
  background-color: ${branco};
  font-weight: bold;
  height: ${px2vw(30)};
  width: ${px2vw(200)};

  ${(props) => `
    width: ${props.width};
  `}

  ${(props) => props.disabled && `
    border: ${px2vw(2.5)} solid ${cinzaKuppi4};
    border-radius: ${px2vw(10)};
    background-color: ${branco};
    color: ${cinzaKuppi4};
    cursor: not-allowed; 
  `};
`;

export const ConfirmButton = styled.button`
  border: none;
  border-radius: ${px2vw(10)};
  background-color: ${verdeKuppi2};
  font-weight: bold;
  height: ${px2vw(30)};
  width: ${px2vw(200)};
  color: ${branco};

  ${(props) => props.disabled && `
    border: ${px2vw(2.5)} solid ${cinzaKuppi4};
    border-radius: ${px2vw(10)};
    background-color: ${branco};
    color: ${cinzaKuppi4};
    cursor: not-allowed; 
  `};
`;

export const ChoiceButton = styled.div`
  border-radius: ${px2vw(99)};
  border: ${px2vw(2)} solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(16)};
  height: ${px2vw(16)};
  cursor: pointer;
`;

export const ChoiceButtonSelected = styled.div`
  position: absolute;
  background-color: ${verdeKuppi1};
  width: ${px2vw(12)};
  height: ${px2vw(12)};
  margin-top: ${px2vw(2)};
  display: none;
  cursor: pointer;
  border-radius: ${px2vw(99)};

  ${(props) =>
    props.isChecked &&
    `
      display: block;
    `};
`;

export const FormBox = styled(Form)`
  width: auto;
  height: auto;
`;

export const SelectInput = styled(Select)`
  height: ${px2vw(35)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    background-color: ${branco};
    border: 1px solid ${cinzaKuppi4};
    border-radius: ${px2vw(5)}
  `};
`;

export const CheckButton = styled.div`
  border-radius: ${px2vw(4)};
  border: ${px2vw(2)} solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(16)};
  height: ${px2vw(16)};
  cursor: pointer;

  ${(props) => `
    margin-right: ${props.margright};
  `}
`;

export const ImageContainer = styled.div`
  width: ${px2vw(14)};
  height: ${px2vw(14)};
  margin-top: ${px2vw(-5)};
  display: none;
  border-radius: ${px2vw(4)};

  ${(props) =>
    props.isChecked &&
    `
        display: block;
      `};
`;
