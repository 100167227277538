export function loginRequest(email, password) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: { email, password },
  };
}

export function loginSuccess(token, user) {
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { token, user },
  };
}

export function loginFailure() {
  return {
    type: '@auth/LOGIN_FAILURE',
  };
}

export function logout(invalidSession = false) {
  return {
    type: '@auth/LOG_OUT_REQUEST',
    payload: { invalidSession }
  };
}

export function logoutSuccessFailure() {
  return {
    type: '@auth/LOG_OUT',
  };
}

export function recoverPasswordRequest(email) {
  return {
    type: '@auth/RECOVER_PASSWORD_REQUEST',
    payload: { email },
  };
}

export function recoverPasswordSuccess() {
  return {
    type: '@auth/RECOVER_PASSWORD_SUCCESS',
  };
}

export function clearFields() {
  return {
    type: '@auth/CLEAR_FIELDS',
  };
}

