import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'reactstrap';

import { Col, Row } from '../../../../styles/grid';
import { ContentPage, LoadingBox } from '../../../_layouts/styles/common';
import { Title } from '../../styles';
import {
  ActionButton,
  EditButton,
  GoBackButton,
  Tab,
  TabBox,
  TabsBox,
  TabSelected,
  Text,
} from '../styles';

import TabData from './TabData';
import TabSubscription from './TabSubscription';
import TabPixelOwnDomain from './TabPixelOwnDomain';
import TabWhatsapp from './TabWhatsapp';
import TabMassMessage from './TabMassMessage';
import editImg from '../../../../assets/images/clients/edit@2x.png';
import EditStatusDataModal from '../../../../components/EditStatusDataModal';

import {
  clearSuccess,
  getSubscriptionsRequest,
  getSubscriptionsListRequest,
  updateStoreStatusDataRequest,
  getKuppiBoostDataRequest,
  getBoostCampaignsRequest,
  getPixelOwnDomainDataRequest,
  getWhatsappDataRequest,
  getMassMessageClientDataRequest,
  getMassMessageClientCampaignsRequest,
  clearClientRequestErrors,
  clearCacheRequest,
  advertiserAdminLoginRequest,
  getUserPermissionsRequest,
  allowMoreFreePeriodRequest,
} from '../../../../store/modules/clients/actions';

import px2vw from '../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../utils/validations/AccessValidate';
import TabKuppiBoost from './TabKuppiBoost';
import { preto } from '../../../../styles/colors';
import CopyCatalogModal from '../../../../components/CopyCatalogModal';
import TabPermissions from './TabPermissions';

export default function AdvertiserDetails({ handler }) {
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(access, 'admin_operator_edit', 'operational_edit', null, false);
  const clientData = useSelector((state) => state.clients.clientData);

  const {
    updateStoreDataSuccess,
    updateRepresentativeDataSuccess,
    storeStatusSuccess,
    updateStoreSubscriptionSuccess,
    subscriptionsError,
    manualConciliationSuccess,
    kuppiBoostDataError,
    campaignsDataError,
    addKuppiBoostBalanceSuccess,
    whatsappDataError, 
    pixelOwnDomainDataError, 
    massMessageDataError,
    clearCacheLoading,
    advertiserAdminLoginLoading,
    allowMoreFreePeriodLoading,
  } = useSelector((state) => state.clients);

  const [currTabSelected, setCurrTabSelected] = useState('tabData');

  const [editStatusData, setEditStatusData] = useState(false);
  const [blockDate, setBlockDate] = useState('');
  const [inactiveState, setInactiveState] = useState(false);
  const [advertiserId, setAdvertiserId] = useState('');
  const [copyCatalogModal, setCopyCatalogModal] = useState(false);
  
  const [clientName, setClientName] = useState('');

  const dispatch = useDispatch();

  function handleStoreStatusSubmit() {
    if (blockDate === '') {
      toast.error('A data de bloqueio não pode estar em branco!');
    } else if (
      moment(blockDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
      toast.error('A data de bloqueio deve ser maior que a data de hoje!');
    } else {
      dispatch(updateStoreStatusDataRequest(clientData.id, blockDate));
    }
  }

  useEffect(() => {
    if (clientData?.store_data) {
      setBlockDate(
        moment(clientData.store_data.status.date).format('YYYY-MM-DD')
      );
      setInactiveState(clientData.store_data.status.status !== 'Ativo');

      setClientName(clientData.store_data.name);
    }
    setAdvertiserId(clientData.id);
  }, [clientData]);

  useEffect(() => {
    if (
      updateStoreDataSuccess ||
      updateRepresentativeDataSuccess ||
      storeStatusSuccess ||
      updateStoreSubscriptionSuccess ||
      manualConciliationSuccess ||
      addKuppiBoostBalanceSuccess
    ) {
      dispatch(clearSuccess());
    }
  }, [
    updateStoreDataSuccess,
    updateRepresentativeDataSuccess,
    storeStatusSuccess,
    updateStoreSubscriptionSuccess,
    manualConciliationSuccess, 
    addKuppiBoostBalanceSuccess
  ]);

  useEffect(() => {
    if (subscriptionsError || kuppiBoostDataError || campaignsDataError || whatsappDataError || pixelOwnDomainDataError || massMessageDataError) {
      setCurrTabSelected('tabData');
      dispatch(clearClientRequestErrors())
    }
  }, [subscriptionsError, kuppiBoostDataError, campaignsDataError, whatsappDataError, pixelOwnDomainDataError, massMessageDataError]);

  // close status modal when update data success
  useEffect(() => {
    if (storeStatusSuccess) {
      setEditStatusData(false);
    }
  }, [storeStatusSuccess]);

  const AdvertiserDetailsHandler = {
    editStatusData: editStatusData,
    blockDate: blockDate,
    advertiserId: handler.advertiserId,
    setEditStatusData: (val) => setEditStatusData(val),
    setBlockDate: (e) => setBlockDate(e),
    handleStoreStatusSubmit: () => handleStoreStatusSubmit(),
  };

  const CopyCatalogHandler = {
    advertiserId: clientData.id,
    copyCatalogModal: copyCatalogModal,
    setCopyCatalogModal: (val) => setCopyCatalogModal(val)
  }

  return (
    <ContentPage>
      <Row height="auto" alignStart justifyStart>
        <GoBackButton
          onClick={() => {
            handler.setAdvertiserDetails(false);
            handler.setSearchInputValue('');
            handler.setStartDate(null);
            handler.setEndDate(null);
            dispatch(clearSuccess());
          }}
        >
          {'< Voltar'}
        </GoBackButton>
      </Row>
      <Row height="auto" alignStart justifyStart margtop={px2vw(10)}>
        <Title>{`${clientName} (ID: ${clientData.id})`}</Title>
      </Row>
      <Row height="auto" alignCenter justifyStart margtop={px2vw(10)}>
        <Col width="auto">
          <Text fontweight="bold" fontSize={px2vw(10)}>
            Status:{' '}
            <span className={inactiveState ? 'inactive' : ''}>
              {clientData?.store_data.status.text}
            </span>
          </Text>
        </Col>
        {operationalEditAccess && clientData.store_data.status.status === 'Cancelado' && clientData.store_data.status.type === 'Gratuito' && (
          <Col width="auto" margleft={px2vw(10)}>
          <ActionButton
            width={px2vw(100)}
            disabled={allowMoreFreePeriodLoading}
            onClick={() => dispatch(allowMoreFreePeriodRequest(clientData.id))}
          >
            {allowMoreFreePeriodLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Liberar +7 dias'
            )}
          </ActionButton>
        </Col>
        )}
        {operationalEditAccess && clientData.store_data.status.status === 'Bloqueado' && (
          <Col width="auto" margleft={px2vw(10)}>
            <EditButton status onClick={() => setEditStatusData(true)}>
              <img
                src={editImg}
                alt="Edit Icon"
                width={px2vw(125)}
                height={px2vw(125)}
              />
              Editar
            </EditButton>
          </Col>
        )}
        {operationalEditAccess && (
          <Col width="auto" margleft={px2vw(10)}>
            <ActionButton
              width={px2vw(100)}
              disabled={clearCacheLoading}
              onClick={() => dispatch(clearCacheRequest(clientData.id, clientData.store_data.slug))}
            >
              {clearCacheLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Limpar Cache'
              )}
            </ActionButton>
          </Col>
        )}
        {operationalEditAccess && (
          <Col width="auto" margleft={px2vw(10)}>
            <ActionButton
              width={px2vw(130)}
              disabled={advertiserAdminLoginLoading}
              onClick={() => dispatch(advertiserAdminLoginRequest(clientData.id, clientData.user_id))}
            >
              {advertiserAdminLoginLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Acessar Plataforma'
              )}
            </ActionButton>
          </Col>
        )}
        {operationalEditAccess && (
          <Col width="auto" margleft={px2vw(10)}>
            <ActionButton
              width={px2vw(130)}
              onClick={() => setCopyCatalogModal(true)}
            >
              Copiar cárdapio Kuppi
            </ActionButton>
          </Col>
        )}
      </Row>
      <Col height="auto" justifyStart>
        <TabsBox>
          <TabBox>
            <Tab onClick={() => setCurrTabSelected('tabData')}>Dados</Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabData'}
              width="auto"
              margbottom="0"
            />
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                setCurrTabSelected('tabSubscription');
                dispatch(getSubscriptionsRequest(advertiserId));
                dispatch(getSubscriptionsListRequest());
              }}
            >
              Assinatura
            </Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabSubscription'}
              width="auto"
              margbottom="0"
            />
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                setCurrTabSelected('tabKuppiBoost');
                dispatch(getKuppiBoostDataRequest(advertiserId));
                dispatch(getBoostCampaignsRequest(advertiserId));
              }}
            >
              Kuppi Boost
            </Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabKuppiBoost'}
              width="auto"
              margbottom="0"
            />
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                setCurrTabSelected('tabPixelOwnDomain');
                dispatch(getPixelOwnDomainDataRequest(advertiserId));
              }}
            >
              Pixel / Domínio Próprio
            </Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabPixelOwnDomain'}
              width="auto"
              margbottom="0"
            />
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                setCurrTabSelected('tabWhatsapp');
                dispatch(getWhatsappDataRequest(advertiserId));
              }}
            >
              Whatsapp
            </Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabWhatsapp'}
              width="auto"
              margbottom="0"
            />
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                setCurrTabSelected('tabMassMessage');
                dispatch(getMassMessageClientDataRequest(advertiserId));
                dispatch(getMassMessageClientCampaignsRequest(advertiserId));
              }}
            >
              Disparo em Massa
            </Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabMassMessage'}
              width="auto"
              margbottom="0"
            />
          </TabBox>
        </TabsBox>
        <TabsBox padtop="0" margbottom={px2vw(20)}>
          <TabBox>
            <Tab
              onClick={() => {
                setCurrTabSelected('tabPermissions');
                dispatch(getUserPermissionsRequest(clientData.user_id));
              }}
            >
              Permissões
            </Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabPermissions'}
              width="auto"
              margbottom="0"
            />
          </TabBox>
        </TabsBox>
        {currTabSelected === 'tabData' && <TabData />}
        {currTabSelected === 'tabSubscription' && <TabSubscription />}
        {currTabSelected === 'tabKuppiBoost' && <TabKuppiBoost />}
        {currTabSelected === 'tabPixelOwnDomain' && <TabPixelOwnDomain />}
        {currTabSelected === 'tabWhatsapp' && <TabWhatsapp />}
        {currTabSelected === 'tabMassMessage' && <TabMassMessage />}
        {currTabSelected === 'tabPermissions' && <TabPermissions />}
      </Col>
      {editStatusData && (
        <EditStatusDataModal handler={AdvertiserDetailsHandler} />
      )}
      {copyCatalogModal && (
        <CopyCatalogModal handler={CopyCatalogHandler}/>
      )}
    </ContentPage>
  );
}
