import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ButtonSecondary,
} from './styles';
import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';
import { Col, Row } from '../../../styles/grid';

import TablePerPage from '../../Tables/TablePerPage';
import ConfirmCampaignActionModal from './ConfirmCampaignActionModal';

import { clearCampaignActionSuccess } from '../../../store/modules/clients/actions';

export default function AdsTable() {
  const { massMessageData, massMessageCampaignsData, campaignActionSuccess } = useSelector((state) => state.clients);
  const [rows, setRows] = useState(massMessageCampaignsData);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignAction, setCampaignAction] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (campaignActionSuccess) {
      setSelectedCampaign(null);
      setCampaignAction(null);
      dispatch(clearCampaignActionSuccess());
    }
  }, [campaignActionSuccess])

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(massMessageCampaignsData);
  }, [massMessageCampaignsData]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  const handleGetStatsusText = useCallback(
    (status) => {
      switch (status) {
        case 'canceled':
          return 'Cancelado';
        case 'paid':
          return 'Pago';
        case 'pending_payment':
          return 'Pendente pagamento';
        case 'sending':
          return 'Enviando';
        case 'error':
          return 'Erro';
        case 'canceled_payment':
          return 'Pagamento cancelado';
        case 'finished':
          return 'Envio concluído';
      }
    },
    [massMessageCampaignsData]
  );

  function handleOpenCampaignActionConfirmation(campaign, action) {
    setCampaignAction(action);
    setSelectedCampaign(campaign);
  }

  function handleCloseCampaignActionConfirmation() {
    setCampaignAction(null);
    setSelectedCampaign(null);
  }

  return (
    <>
      {/* Campaigns Table */}
      <Col padding="0 15px" alignCenter justifyCenter>
        <Row>
          <Header
            onClick={() =>
              handleSort('id')
            }
            width={px2vw(120)}
          >
            <HeaderButton>
              ID da Campanha
              <SvgBox sorted={sortedCol === 'id'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('file_id')} width={px2vw(110)}>
            <HeaderButton>
              Tem Imagem?
              <SvgBox sorted={sortedCol === 'file_id'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('scheduled_at')} width={px2vw(120)}>
            <HeaderButton>
              Data do Envio
              <SvgBox sorted={sortedCol === 'scheduled_at'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('advertiserMassMessagePayment[0].pix_expiration_at')} width={px2vw(140)}>
            <HeaderButton>
              Expiração Pagamento
              <SvgBox sorted={sortedCol === 'advertiserMassMessagePayment[0].pix_expiration_at'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('status')} width={px2vw(120)}>
            <HeaderButton>
              Status
              <SvgBox sorted={sortedCol === 'status'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('contacts_qty')} width={px2vw(90)}>
            <HeaderButton>
              Qtd Contatos
              <SvgBox sorted={sortedCol === 'contacts_qty'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header width={px2vw(300)} padding="25px 5px" />
        </Row>
        <Row>
          <Hr />
        </Row>
        {structure ? (
          rows.map((item, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={item.id}>
                <TableRow alignCenter justifyCenter cursor="pointer">
                  <Content width={px2vw(120)}>
                   {item.id}
                  </Content>
                  <Content width={px2vw(110)}>
                    {item.file_id ? 'Sim' : 'Não'}
                  </Content>
                  <Content width={px2vw(120)}>
                    {moment(item.scheduled_at).format('DD/MM/YYYY HH:mm')}
                  </Content>
                  <Content width={px2vw(140)}>
                    {item.status == 'pending_payment' && item.advertiserMassMessagePayment[0].pix_expiration_at ? moment(item.advertiserMassMessagePayment[0].pix_expiration_at).format('DD/MM/YYYY HH:mm') : '-'}
                  </Content>
                  <Content width={px2vw(120)}>
                    {handleGetStatsusText(item.status)}
                  </Content>
                  <Content width={px2vw(90)}>
                    {item.contacts_qty}
                  </Content>
                  <Content width={px2vw(300)}>
                    {massMessageData?.segmentations?.length > 0 && (
                      <>
                      {['paid', 'sending', 'error'].includes(item.status) && (
                        <ButtonSecondary
                          height={25}
                          borderRadius={5}
                          fontSize={px2vw(13)}
                          margleft={px2vw(20)}
                          marginRight={px2vw(20)}
                          onClick={() => handleOpenCampaignActionConfirmation(item, 'cancel')}
                        >
                          Cancelar
                        </ButtonSecondary>
                      )}
                      {['paid', 'error'].includes(item.status) && (
                        <ButtonSecondary
                          height={25}
                          borderRadius={5}
                          fontSize={px2vw(13)}
                          marginRight={px2vw(20)}
                          onClick={() => handleOpenCampaignActionConfirmation(item, 'force-start')}
                        >
                          Forçar Inicio
                        </ButtonSecondary>
                      )}
                      </>
                    )}
                  </Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <Row padding="15px 0 10px 0">Nenhuma campanha no momento.</Row>
        )}
        {structure && (
          <Row alignCenter justifyEnd>
            <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
              <TablePerPage response={handlePerPage} length={rows.length} />
              <Row alignCenter justifyCenter width="10%">
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </Row>
              <PagButton
                onClick={() => handleChangePage('prev')}
                disabled={currentPage === firstPage}
                title="Página anterior"
              >
                <PrevIcon disabled={currentPage === firstPage} />
              </PagButton>
              <PagButton
                onClick={() => handleChangePage('next')}
                title="Próxima página"
                disabled={currentPage === lastPage}
              >
                <NextIcon disabled={currentPage === lastPage} />
              </PagButton>
            </Row>
          </Row>
        )}
      </Col>
      {!!selectedCampaign && (
        <ConfirmCampaignActionModal 
          onClose={handleCloseCampaignActionConfirmation}
          action={campaignAction}
          campaignData={selectedCampaign}
        />
      )}
    </>
  );
}
