import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../Table';
import * as tableColumns from './columns';

function KuppiBoostTable({ data, columns }) {
  return <Table data={data} columns={tableColumns[columns]} />;
}

KuppiBoostTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.string,
};

export default KuppiBoostTable;
