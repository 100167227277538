import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiGlobal, apiAdmin } from '../../../services/api';
import history from '../../../services/history';
import { login as loginRoute, dashboard } from '../../../routes/routeMap';

import { loginSuccess, loginFailure, recoverPasswordSuccess, logoutSuccessFailure } from './actions';

export function* login({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(apiGlobal.post, '/auth', {
      email,
      password,
    });

    const { token, user } = response.data;

    // Verify if the user is active
    if (!user.is_active) {
      toast.error('O Usuário não está ativo. Entre em contato com suporte.', {
        autoClose: 6000,
      });
      yield put(loginFailure());
      return;
    }

    const userRoles = [];

    user.roles.forEach((rule) => {
      userRoles.push(rule.slug);
    });

    const userIsAdmin = userRoles.find((role) => {
      return role.includes('administrator') || role.includes('admin_');
    });

    if (!userIsAdmin) {
      toast.error('O Usuário não possui permissões de administrador.');
      yield put(loginFailure());
      return;
    }

    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token.token}`;
    apiAdmin.defaults.headers.common.Authorization = `Bearer ${token.token}`;

    yield put(loginSuccess(token, user));

    history.push(dashboard);
  } catch (err) {
    if (err.response?.status === 403) {
      toast.error('O Usuário não está ativo. Entre em contato com suporte.', {
        autoClose: 6000,
      }); 
    } else {    
      toast.error(`Falha na autenticação. Verifique seus dados.`, {
        autoClose: 6000,
      });
    }

    yield put(loginFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiAdmin.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export function* logout({ payload }) {
  try {
    if (!payload.invalidSession) {
      const user = yield select((state) => state.user.user);

      yield call(
        apiGlobal.delete,
        `/user/${user.id}/logout`
      );
    }

    yield put(logoutSuccessFailure())

    history.push(`${loginRoute}${payload.invalidSession ? '?erro=sessao-invalida' : ''}`);
  } catch (err) {
    yield put(logoutSuccessFailure())

    history.push(loginRoute)
  }
}

export function* recoverPasswordRequest({ payload }) {
  const { email } = payload;

  yield call(apiGlobal.post, '/forgot-password', { email });

  yield put(recoverPasswordSuccess());
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', login),
  takeLatest('@auth/LOG_OUT_REQUEST', logout),
  takeLatest('@auth/RECOVER_PASSWORD_REQUEST', recoverPasswordRequest),
]);
