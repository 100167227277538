import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { preto } from '../../../../styles/colors';
import { Col } from '../../../../styles/grid';
import { ValidateButton } from './styles';
import { LoadingBox } from '../../../../styles/common';

function ActionButtons({
  advertiserInfoId,
  currAdvertiserId,
  buttonId,
  actionToPerform,
  label,
  margBottom = null
}) {
  const { kuppiBoostValidateLoading, currButtonId } = useSelector((state) => state.kuppiBoostValidation);
  const dispatch = useDispatch();

  return (
    <Col width="auto" margbottom={margBottom ? margBottom : '0'}>
      <ValidateButton
        disabled={kuppiBoostValidateLoading}
        onClick={() => dispatch(actionToPerform())}
      >
        {kuppiBoostValidateLoading && buttonId === currButtonId && advertiserInfoId === currAdvertiserId ? (
          <LoadingBox>
            <Spinner
              style={{
                width: '1.2rem',
                height: '1.2rem',
                color: preto,
              }}
            />{' '}
          </LoadingBox>
        ) : (
          `${label}`
        )}
      </ValidateButton>
    </Col>
  );
}

export default ActionButtons;
