import React, { useEffect, useState } from 'react';

import { BiCheckCircle, BiTime } from 'react-icons/bi';
import { FiAlertCircle } from 'react-icons/fi';

import { statusConfig } from './status';

import { Text } from './styles';
import { Row } from '../../../styles/grid';

export default function TableStatus({
  big,
  fontSize,
  status,
  finishAt,
  noColorText,
  ...rest
}) {
  const [InternalStatus, setInternalStatus] = useState(status);

  useEffect(() => {
    const now = new Date();
    const finishDate = new Date(finishAt);
    if (finishDate < now) {
      setInternalStatus('FINISHED');
    } else {
      setInternalStatus(status);
    }
  }, [status]);

  return (
    <Row alignCenter justifyStart {...rest}>
      {/* <Ball situation={InternalStatus} big={big} /> */}
      {InternalStatus === 'APPROVED' && (
        <BiCheckCircle size="16px" color="#2BC48A" />
      )}
      {InternalStatus === 'APPROVING' && <BiTime size="16px" color="#383539" />}
      {InternalStatus === 'REPROVED' && (
        <FiAlertCircle size="16px" color="#FF8181" />
      )}
      {InternalStatus === 'FINISHED' && (
        <BiCheckCircle size="16px" color="#707070" />
      )}

      <Text
        fontSize={fontSize}
        situation={noColorText ? false : InternalStatus}
        big={big}
      >
        {statusConfig[InternalStatus].text}
      </Text>
    </Row>
  );
}
