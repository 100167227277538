import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { LoadingBox } from '../../../styles/common';
import { preto } from '../../../styles/colors';
import { Col, Row } from '../../../styles/grid';
import { CloseButton, ModalTitleText } from '../../../components/Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../../../components/EditResponsibleDataModal/styles';

import closeButtonImg from '../../../assets/images/close_button/close_button.png';

import px2vw from '../../../utils/responsiveness/px2vw';

import Modal from '../../../components/Modal';

import { updateMassMessageGeneralAmountRequest } from '../../../store/modules/massMessageGeneral/actions';
import { onlyNumbersAmount } from '../../../utils/utils';
import { amountMask } from '../../../utils/masks';

export default function ChangeGeneralAmountModal({ currMassMessageAmountData, onClose }) {
  const { updateMassMessageGeneralAmountLoading } = useSelector(
    (state) => state.clients
  );
  const [newAmount, setNewAmount] = useState(amountMask(currMassMessageAmountData?.per_contact_amount?.toFixed(2) || 0));
  const dispatch = useDispatch();

  function handleUpdateAmount() {
    dispatch(updateMassMessageGeneralAmountRequest(onlyNumbersAmount(newAmount)));
  }

  return (
    <Modal
      isVisible
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={onClose}
          disabled={updateMassMessageGeneralAmountLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Alterar Valor do Disparo em Massa</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          {`Insira o novo valor do disparo que afetará: ${currMassMessageAmountData?.qty} clientes`}
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="newAmount"
          type="text"
          maxLength="7"
          placeholder="R$ 0,10"
          width={px2vw(400)}
          value={newAmount}
          onChange={(e) => setNewAmount(amountMask(e.target.value))}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <ConfirmButton
            onClick={() => {
              if (newAmount.trim().length > 0) {
                handleUpdateAmount();                
              } else {
                toast.error('Deve ser informado o novo valor!')
              }
            }} 
            disabled={updateMassMessageGeneralAmountLoading}
          >
            {updateMassMessageGeneralAmountLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : ('Alterar')}
          </ConfirmButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <CancelButton  
            onClick={onClose}
            disabled={updateMassMessageGeneralAmountLoading}
          >
            Voltar
          </CancelButton>
        </Col>
      </Row>
    </Modal>
  );
}
