// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'kuppi-admin',
      storage,
      whitelist: ['auth', 'user', 'reports', 'monthlyPaymentsMetrics', 'clients', 'kuppiBoostValidation'],
    },
    reducers
  );

  return persistedReducer;
};
