/** Mass Message General **/
export function getMassMessageGeneralRequest() {
  return {
    type: '@massMessageGeneral/GET_MASS_MESSAGE_GENERAL_REQUEST',
  };
}

export function getMassMessageGeneralSuccess(massMessageGeneral) {
  return {
    type: '@massMessageGeneral/GET_MASS_MESSAGE_GENERAL_SUCCESS',
    payload: { massMessageGeneral },
  };
}

export function getMassMessageGeneralFailure() {
  return {
    type: '@massMessageGeneral/GET_MASS_MESSAGE_GENERAL_FAILURE',
  };
}

export function updateMassMessageGeneralAmountRequest(newAmount) {
  return {
    type: '@massMessageGeneral/UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_REQUEST',
    payload: { newAmount },
  };
}

export function updateMassMessageGeneralAmountSuccess(massMessageGeneral) {
  return {
    type: '@massMessageGeneral/UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_SUCCESS',
    payload: { massMessageGeneral },
  };
}

export function updateMassMessageGeneralAmountFailure() {
  return {
    type: '@massMessageGeneral/UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_FAILURE',
  };
}

export function clearUpdateMassMessageGeneralAmountSuccess() {
  return {
    type: '@massMessageGeneral/CLEAR_UPDATE_MASS_MESSAGE_GENERAL_AMOUNT_SUCCESS',
  };
}
