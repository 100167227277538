import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { Col, Row } from '../../../../../styles/grid';
import { ActionButton, Hr, Text, WhiteBox } from '../../styles';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';
import {
  dddPhoneMask,
} from '../../../../../utils/masks';
import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';
import { format } from 'date-fns';
import ForceDisconnectionModal from './ForceDisconnectionModal';

import { clearforceWhatsappDisconnectionSuccess } from '../../../../../store/modules/clients/actions';
import { useEffect } from 'react';

export default function TabWhatsapp() {
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );
  const {
    whatsappDataLoading,
    forceWhatsappDisconnectionSuccess
  } = useSelector((state) => state.clients);
  const whatsappData = useSelector((state) => state.clients.whatsappData);
  const [forceDisconnectionModal, setForceDisconnectionModal] = useState(false);
  const dispatch = useDispatch();
  
  function formatWppStatus(status) {
    switch (status) {
      case 'initialized':
        return 'Inicializado';
      case 'connected':
        return 'Conectado';
      case 'disconnected':
        return 'Desconectado';
      default:
        return '-'
    }
  }

  function formatWppPlatform(platform) {
    switch (platform) {
      case 'smba':
        return 'Android Business';
      case 'android':
        return 'Android Normal';
      case 'smbi':
        return 'Iphone Business';
      case 'iphone':
        return 'Iphone Normal';
      default:
        return '-'
    }
  }
  
  useEffect(() => {
    if (forceWhatsappDisconnectionSuccess) {
      setForceDisconnectionModal(false);
      dispatch(clearforceWhatsappDisconnectionSuccess());
    }
  }, [forceWhatsappDisconnectionSuccess])

  return (
    <>
      <Col>
        <Row>
          <Col>
            <WhiteBox>
              {!whatsappDataLoading && whatsappData?.advertiser_info_id && (
                <>
                  <Row spaceBetween margbottom={px2vw(20)}>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Status
                      </Text>
                      <Text>
                        {formatWppStatus(whatsappData?.status)}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Número Whatsapp
                      </Text>
                      <Text>
                        {whatsappData?.phone !== null ? dddPhoneMask(whatsappData?.phone) : '-'}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Plataforma
                      </Text>
                      <Text>
                        {formatWppPlatform(whatsappData?.platform)}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Tem QRCode gerado?
                      </Text>
                      <Text>
                        {whatsappData?.has_qr_code_image ? 'Sim' : 'Não'}
                      </Text>
                    </Col>
                  </Row>
                  <Hr />
                  <Row spaceBetween margtop={px2vw(20)} margbottom={px2vw(20)}>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Conectado em
                      </Text>
                      <Text>
                        {whatsappData?.connected_at !== null
                          ? format(new Date(whatsappData?.connected_at), 'dd/MM/yyyy HH:mm:ss')
                          : '-'}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Desconectado em
                      </Text>
                      <Text>
                        {whatsappData?.disconnected_at !== null
                          ? format(new Date(whatsappData?.disconnected_at), 'dd/MM/yyyy HH:mm:ss')
                          : '-'}
                      </Text>
                    </Col>
                    <Col>
                      {operationalEditAccess && (
                        <ActionButton onClick={() => setForceDisconnectionModal(true)}>
                          Forçar Desconexão
                        </ActionButton>
                      )}
                    </Col>
                  </Row>
                  <Hr />
                  <Row spaceBetween margtop={px2vw(20)}>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Envio de status de pedido automático ativado?
                      </Text>
                      <Text>
                        {whatsappData?.is_send_whatsapp_msg_update_status ? 'Sim' : 'Não'}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Chatbot ativado?
                      </Text>
                      <Text>
                        {whatsappData?.is_using_chatbot ? 'Sim' : 'Não'}
                      </Text>
                    </Col>
                  </Row>
                </>
              )}
              {whatsappDataLoading && (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              )}
            </WhiteBox>
          </Col>
        </Row>
      </Col>
      {forceDisconnectionModal && 
        <ForceDisconnectionModal
          onClose={() => setForceDisconnectionModal(false)}
        />
      }
    </>
  );
}
