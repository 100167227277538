import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import px2vw from '../../utils/responsiveness/px2vw';
import Modal from '../Modal';
import { CloseButton, ModalHeader, ModalTitleText } from '../Modal/styles';
import closeButtonImg from '../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../styles/grid';
import {
  CancelButton,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
} from './styles';
import { cinzaKuppi4, preto } from '../../styles/colors';
import { LoadingBox } from '../../styles/common';
import GenerateNewPassWordModal from '../GenerateNewPassWordModal';
import { dddPhoneMask } from '../../utils/masks';

export default function EditResponsibleDataModal({ handler }) {
  const {
    updateRepresentativeDataLoading,
    recoverPasswordLoading,
  } = useSelector((state) => state.clients);

  const clientData = useSelector((state) => state.clients.clientData);

  function handleCloseModal() {
    handler.setEditResponsibleData(false);
    handler.setRepresentativeEmail(clientData.store_representative_data.email);
    handler.setRepresentativeName(clientData.store_representative_data.name);
    handler.setRepresentativeWhatsapp(dddPhoneMask(clientData.store_representative_data.whatsapp));
  }

  return (
    <Modal
      isVisible={handler.editResponsibleData}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Alterar dados do Responsável</ModalTitleText>
        <CloseButton onClick={handleCloseModal}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handler.handleEditRepresentativeDataSubmit}>
        <Col margtop={px2vw(20)}>
          <InputTitle>E-mail de acesso</InputTitle>
          <InputData
            name="email"
            type="email"
            placeholder="Digite aqui seu e-mail"
            value={handler.representativeEmail}
            onChange={(e) => handler.setRepresentativeEmail(e.target.value)}
          />
        </Col>
        <Col margtop={px2vw(10)}>
          <InputTitle>Nome do Responsável</InputTitle>
          <InputData
            name="name"
            type="text"
            placeholder="Digite aqui seu nome"
            value={handler.representativeName}
            onChange={(e) => handler.setRepresentativeName(e.target.value)}
          />
        </Col>
        <Col margtop={px2vw(10)}>
          <InputTitle>Telefone Whatsapp</InputTitle>
          <InputData
            name="phone"
            type="tel"
            placeholder="Digite aqui seu whatsapp"
            value={handler.representativeWhatsapp}
            onChange={(e) => handler.setRepresentativeWhatsapp(e.target.value)}
          />
        </Col>

        <Row margtop={px2vw(10)} justifyStart>
          <InputTitle>Senha</InputTitle>
        </Row>
        <Row
          width={px2vw(450)}
          margtop={px2vw(5)}
          border={`${px2vw(2)} solid ${cinzaKuppi4}`}
          borderRadius={px2vw(5)}
          pad={px2vw(10)}
        >
          <Col>
            <CancelButton
              width={px2vw(140)}
              type="button"
              onClick={() => handler.setGenerateNewPassword()}
            >
              Gerar nova senha
            </CancelButton>
          </Col>

          <Col margleft={px2vw(20)}>
            <CancelButton
              width={px2vw(270)}
              type="button"
              onClick={() => {
                if (handler.linkForgotPassword !== null) {
                  handler.copyForgotPasswordLink();
                } else {
                  handler.handleSendForgotPasswordLink();
                }
              }}
            >
              {recoverPasswordLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                handler.forgotPasswordButtonText
              )}
            </CancelButton>
          </Col>
        </Row>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton onClick={handleCloseModal}>Cancelar</CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit">
              {updateRepresentativeDataLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
      {handler.generateNewPassword && (
        <GenerateNewPassWordModal handler={handler} />
      )}
    </Modal>
  );
}
