import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import {
  CancelSubscriptionButton,
  EditButton,
  Hr,
  Text,
  WhiteBox,
} from '../../styles';
import { Col, Row } from '../../../../../styles/grid';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';

import editImg from '../../../../../assets/images/clients/edit@2x.png';
import { formatCurrencyBRL } from '../../../../../utils/utils';
import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';
import {
  getMassMessageClientDataRequest,
  clearMassMessageClientSuccess,
} from '../../../../../store/modules/clients/actions';
import MassMessageClientData from '../../../../../components/MassMessageClientData';
import EditMassMessageModal from './EditMassMessageModal';
import AddMassMessageBalanceModal from './AddMassMessageBalanceModal';

export default function TabMassMessage() {
  const { access } = useSelector((state) => state.user);
  const financialAccess = AccessValidate(
    access,
    'admin_financial',
    'financial',
    null,
    false
  );

  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );

  const {
    massMessageDataLoading,
    addMassMessageBalanceSuccess,
    editMassMessageClientSuccess,
  } = useSelector((state) => state.clients);

  const massMessageData = useSelector((state) => state.clients.massMessageData);
  const clientData = useSelector((state) => state.clients.clientData);

  const [addMassMessageBalanceModal, setAddMassMessageBalanceModal] = useState(false);
  const [editMassMessageModal, setEditMassMessageModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (addMassMessageBalanceSuccess) {
      dispatch(clearMassMessageClientSuccess());
      setAddMassMessageBalanceModal(false);
      dispatch(getMassMessageClientDataRequest(clientData.id));
    }
  }, [addMassMessageBalanceSuccess]);

  useEffect(() => {
    if (editMassMessageClientSuccess) {
      dispatch(clearMassMessageClientSuccess());
      setEditMassMessageModal(false);
      dispatch(getMassMessageClientDataRequest(clientData.id));
    }
  }, [editMassMessageClientSuccess]);

  function getSegmentationData(segmentation) {
    if (!massMessageData || !massMessageData?.segmentations) {
      return "-"
    }

    const segmentationData = massMessageData?.segmentations?.filter((seg) => seg?.type_tag === segmentation)

    if (segmentationData?.length === 0) {
      return "0"
    }

    return segmentationData[0]?.count;
  }

  return (
    <>
      {massMessageDataLoading ? (
        <LoadingBox>
          <Spinner
            style={{
              width: '1.2rem',
              height: '1.2rem',
              color: preto,
            }}
          />{' '}
        </LoadingBox>
      ) : (
        <>
          <Col>
            <Row justifyStart alignCenter>
              <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(15)}>
                Dados do Disparo em Massa
              </Text>
              {operationalEditAccess && (
                <Col width="auto" height="auto">
                  <EditButton 
                    onClick={() => setEditMassMessageModal(true)}
                  >
                    <img
                      src={editImg}
                      alt="Edit Icon"
                      width={px2vw(175)}
                      height={px2vw(175)}
                    />
                    Editar
                  </EditButton>
                </Col>
              )}
            </Row>

            <WhiteBox margtop={px2vw(10)}>
              <Row spaceBetween margbottom={px2vw(10)} alignCenter>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Qtd de Campanhas
                  </Text>
                  <Text>{massMessageData?.configs?.total_campaign_qty}</Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Total de Budget (R$)
                  </Text>
                  <Text>{formatCurrencyBRL(massMessageData?.configs?.total_budget, {})}</Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Qtd de Vendas
                  </Text>
                  <Text>{massMessageData?.configs?.total_sales_qty}</Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Total em Vendas (R$)
                  </Text>
                  <Text>{formatCurrencyBRL(massMessageData?.configs?.total_sales_amount, {})}</Text>
                </Col>
              </Row>
              <Hr />
              <Row spaceBetween margtop={px2vw(10)} alignCenter>
                <Col width="auto" margright={px2vw(58)}>
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Pacote Ilimitado?
                  </Text>
                  <Text>{massMessageData?.configs?.is_using_package ? 'Sim' : 'Não'}</Text>
                </Col>
                <Col width="auto" margright={px2vw(37)}>
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Valor por Contato (R$)
                  </Text>
                  <Text>{formatCurrencyBRL(massMessageData?.configs?.per_contact_amount, {})}</Text>
                </Col>
                <Col width="auto">
                  <Text fontweight="bold" fontsize={px2vw(13)}>
                    Saldo Bônus (R$)
                  </Text>
                  <Text>{formatCurrencyBRL(massMessageData?.configs?.balance, {})}</Text>
                </Col>
                <Col width={financialAccess && !massMessageData?.configs?.is_using_package ? "auto" : px2vw(175)}>
                  {financialAccess && !massMessageData?.configs?.is_using_package && (
                    <CancelSubscriptionButton
                      onClick={() => setAddMassMessageBalanceModal(true)}
                    >
                      Adicionar Saldo
                    </CancelSubscriptionButton>
                  )}
                </Col>
              </Row>
            </WhiteBox>
          </Col>
          <Col margtop={px2vw(20)}>
            <Row justifyStart alignCenter>
              <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(15)}>
                Segmentações
              </Text>
            </Row>

            <WhiteBox margtop={px2vw(10)}>
              {massMessageData?.segmentations?.length > 0 ? (
                <Row spaceAround alignCenter>
                  <Col width="auto" title="Tem mais de 3 pedidos e os últimos 3 pedidos foram dentro de 30 dias">
                    <Text fontweight="bold" fontsize={px2vw(13)}>
                      VIPs
                    </Text>
                    <Text>{getSegmentationData("vip")}</Text>
                  </Col>
                  <Col width="auto" title="Realizou mais de um pedido e não é VIP">
                    <Text fontweight="bold" fontsize={px2vw(13)}>
                      Recorrentes
                    </Text>
                    <Text>{getSegmentationData("recurrent")}</Text>
                  </Col>
                  <Col width="auto" title="Tem apenas um pedido e esse pedido está dentro de 30 dias">
                    <Text fontweight="bold" fontsize={px2vw(13)}>
                      Novos Clientes
                    </Text>
                    <Text>{getSegmentationData("new")}</Text>
                  </Col>
                  <Col width="auto" title="Já pediu peloo menos 1 vez e o útimo pedido foi a mais de 30 dias">
                    <Text fontweight="bold" fontsize={px2vw(13)}>
                      Em Risco
                    </Text>
                    <Text>{getSegmentationData("risk")}</Text>
                  </Col>
                  <Col width="auto" title="Contatos do Whatsapp que nunca realizaram pedido">
                    <Text fontweight="bold" fontsize={px2vw(13)}>
                      Potenciais
                    </Text>
                    <Text>{getSegmentationData("potential")}</Text>
                  </Col>
                </Row>
              ) : (
                <Row alignCenter>
                  <Text fontsize={px2vw(13)} margright={px2vw(14)}>
                    Não tem informações de segmentação para exibir (Verifique se o cliente está com o Whatsapp conectado)
                  </Text>
                </Row>
              )}
            </WhiteBox>
          </Col>
          <Col margtop={px2vw(20)} margleft={px2vw(-5)}>
            <MassMessageClientData />
          </Col> 
        </>
      )}
      {addMassMessageBalanceModal && 
        <AddMassMessageBalanceModal 
           onClose={() => setAddMassMessageBalanceModal(false)}
        />
      }
      {editMassMessageModal && (
        <EditMassMessageModal 
          onClose={() => setEditMassMessageModal(false)}
        />
      )}
    </>
  );
}
