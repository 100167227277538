import React from 'react';
import { checkKuppiBoostPermissionsRequest, updateKuppiBoostWhatsappValidateStatusRequest } from '../../../store/modules/kuppiBoostValidation/actions';
import ActionButtons from './ActionButtons';

import { Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';

export const kuppiBoostOnboardingPending = [
  {
    field: 'advertiser_info_id',
    title: 'ID',
    width: px2vw(50),
    render: ({ advertiser_info_id }) => advertiser_info_id,
  },
  {
    field: 'name',
    title: 'Nome',
    width: px2vw(150),
    render: ({ name }) => name,
  },
  {
    field: 'phone_whatsapp_representative',
    title: 'Telefone Responsável',
    width: px2vw(150),
    render: ({ phone_whatsapp_representative }) =>
      phone_whatsapp_representative || '-',
  },
  {
    field: 'status',
    title: 'Status',
    width: px2vw(150),
    render: ({ status }) => status,
  },
  {
    field: 'facebook_page_id',
    title: 'ID página Facebook',
    width: px2vw(200),
    render: ({ facebook_page_id }) => facebook_page_id || '-',
  },
  {
    field: 'instagram_page_id',
    title: 'ID página Instagram',
    width: px2vw(200),
    render: ({ instagram_page_id }) => instagram_page_id || '-',
  },
  {
    title: 'Ação',
    hasTooltip: true,
    width: px2vw(100),  
    render: (item, currAdvertiserId) => (
      <ActionButtons
        label="Validar"
        buttonId="btValidate"
        advertiserInfoId={item.advertiser_info_id}
        currAdvertiserId={currAdvertiserId}
        actionToPerform={() => checkKuppiBoostPermissionsRequest(item.advertiser_info_id)}
      />
    ),
  },
];

export const kuppiBoostWhatsappPending = [
  {
    field: 'advertiser_info_id',
    title: 'ID',
    width: px2vw(50),
    render: ({ advertiser_info_id }) => advertiser_info_id,
  },
  {
    field: 'name',
    title: 'Nome',
    width: px2vw(150),
    render: ({ name }) => name,
  },
  {
    field: 'phone_whatsapp_representative',
    title: 'Telefone Responsável',
    width: px2vw(150),
    render: ({ phone_whatsapp_representative }) => phone_whatsapp_representative || '-',
  },
  {
    field: 'whatsapp_fb_linked_status',
    title: 'Status',
    width: px2vw(150),
    render: ({ whatsapp_fb_linked_status }) => whatsapp_fb_linked_status,
  },
  {
    field: 'facebook_page_id',
    title: 'ID página Facebook',
    width: px2vw(200),
    render: ({ facebook_page_id }) => facebook_page_id || '-',
  },
  {
    field: 'instagram_page_id',
    title: 'ID página Instagram',
    width: px2vw(200),
    render: ({ instagram_page_id }) => instagram_page_id || '-',
  },
  {
    title: 'Ação',
    hasTooltip: true,
    width: px2vw(140),
    render: (item, currAdvertiserId) => (
      <Col alignCenter>
        <ActionButtons
          label="Associado"
          advertiserInfoId={item.advertiser_info_id}
          currAdvertiserId={currAdvertiserId}
          buttonId="btAssociated"
          actionToPerform={() => 
            updateKuppiBoostWhatsappValidateStatusRequest({
              advertiser_info_id: item.advertiser_info_id,
              whatsapp_fb_linked_status: "ok"
            })
          }
          margBottom={px2vw(5)}
        />
        <ActionButtons
          label="Não Associado"
          advertiserInfoId={item.advertiser_info_id}
          currAdvertiserId={currAdvertiserId}
          buttonId="btNotAssociated"
          actionToPerform={() => 
            updateKuppiBoostWhatsappValidateStatusRequest({
              advertiser_info_id: item.advertiser_info_id,
              whatsapp_fb_linked_status: "not_linked"
            })
          }
        />
      </Col>
    ),
  },
];
