/* *** Kuppi Boost Onboarding Pending *** */

export function getKuppiBoostOnboardingPendingRequest() {
  return {
    type: '@kuppiBoostValidation/KUPPI_BOOST_ONBOARDING_PENDING_REQUEST',
  };
}

export function formatKuppiBoostOnboardingPending(data) {
  return {
    type: '@kuppiBoostValidation/FORMAT_KUPPI_BOOST_ONBOARDING_PENDING',
    payload: { data },
  };
}

export function getKuppiBoostOnboardingPendingResponse(data) {
  return {
    type: '@kuppiBoostValidation/KUPPI_BOOST_ONBOARDING_PENDING_RESPONSE',
    payload: { data },
  };
}

export function checkKuppiBoostPermissionsRequest(advertiserInfoId) {
  return {
    type: '@kuppiBoostValidation/CHECK_KUPPI_BOOST_PERMISSIONS_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function checkKuppiBoostPermissionsResponse() {
  return {
    type: '@kuppiBoostValidation/CHECK_KUPPI_BOOST_PERMISSIONS_RESPONSE',
  };
}

/* *** Kuppi Boost Whatsapp Validation Pending *** */

export function getKuppiBoostWhatsappPendingRequest() {
  return {
    type: '@kuppiBoostValidation/KUPPI_BOOST_WHATSAPP_PENDING_REQUEST',
  };
}

export function formatKuppiBoostWhatsappPending(data) {
  return {
    type: '@kuppiBoostValidation/FORMAT_KUPPI_BOOST_WHATSAPP_PENDING',
    payload: { data },
  };
}

export function getKuppiBoostWhatsappPendingResponse(data) {
  return {
    type: '@kuppiBoostValidation/KUPPI_BOOST_WHATSAPP_PENDING_RESPONSE',
    payload: { data },
  };
}

export function updateKuppiBoostWhatsappValidateStatusRequest(data) {
  return {
    type: '@kuppiBoostValidation/UPDATE_KUPPI_BOOST_WHATSAPP_VALIDATE_STATUS_REQUEST',
    payload: { data },
  }
}

export function updateKuppiBoostWhatsappValidateStatusResponse() {
  return {
    type: '@kuppiBoostValidation/UPDATE_KUPPI_BOOST_WHATSAPP_VALIDATE_STATUS_RESPONSE',
  }
}

/* *** Others *** */

export function requestFailure() {
  return {
    type: '@kuppiBoostValidation/REQUEST_FAILURE',
  };
}
