import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  kuppiBoostValidateLoading: false,
  currButtonId: null,
  kuppiBoostOnboardingPending: [],
  kuppiBoostWhatsappPending: []
};

export default function kuppiBoostValidation(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@kuppiBoostValidation/KUPPI_BOOST_ONBOARDING_PENDING_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@kuppiBoostValidation/KUPPI_BOOST_ONBOARDING_PENDING_RESPONSE': {
        draft.loading = false;
        draft.kuppiBoostOnboardingPending = action.payload.data;
        break;
      }
      case '@kuppiBoostValidation/CHECK_KUPPI_BOOST_PERMISSIONS_REQUEST': {
        draft.kuppiBoostValidateLoading = true;
        draft.currButtonId = 'btValidate';
        break;
      }
      case '@kuppiBoostValidation/CHECK_KUPPI_BOOST_PERMISSIONS_RESPONSE': {
        draft.kuppiBoostValidateLoading = false;
        draft.currButtonId = null;
        break;
      }
      case '@kuppiBoostValidation/KUPPI_BOOST_WHATSAPP_PENDING_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@kuppiBoostValidation/KUPPI_BOOST_WHATSAPP_PENDING_RESPONSE': {
        draft.loading = false;
        draft.kuppiBoostWhatsappPending = action.payload.data;
        break;
      }
      case '@kuppiBoostValidation/UPDATE_KUPPI_BOOST_WHATSAPP_VALIDATE_STATUS_REQUEST': {
        draft.kuppiBoostValidateLoading = true;
        draft.currButtonId = action.payload.data.whatsapp_fb_linked_status === 'not_linked' ? 'btNotAssociated' : 'btAssociated';
        break;
      }
      case '@kuppiBoostValidation/UPDATE_KUPPI_BOOST_WHATSAPP_VALIDATE_STATUS_RESPONSE': {
        draft.kuppiBoostValidateLoading = false;
        draft.currButtonId = null;
        break;
      }
      case '@kuppiBoostValidation/REQUEST_FAILURE': {
        draft.loading = false;
        draft.kuppiBoostValidateLoading = false;
        draft.currButtonId = null;
        break;
      }
      default:
    }
  });
}
