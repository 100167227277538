import React from 'react';

import px2vw from '../../utils/responsiveness/px2vw';

import { ContentPage } from '../_layouts/styles/common';

export default function Dashboard() {
  return (
    <ContentPage>
      <div dangerouslySetInnerHTML={{__html: `<iframe width="${px2vw(20000)}" height="${px2vw(11000)}" src="https://app.powerbi.com/view?r=eyJrIjoiOTQ2NjhhMjUtZTQyZC00OTU2LTk4MDItMTllOTU5ZjYwMWRiIiwidCI6ImJkZmU3NTJlLWZiZGItNDQ2Yi04NmE5LTI5YzFmYTgwNGFiZCJ9" frameborder="0" allowFullScreen="true"></iframe>`}} />
    </ContentPage>
  );
}
