import React, { useState, useEffect } from 'react';
import { Icon } from 'react-materialize';
import { Row, Col } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';
import { Title } from '../styles';
import { ContentPage } from '../../_layouts/styles/common';
import {
  WhiteBox,
  SearchButton,
  SearchInput,
  InputTitle,
  SelectInput,
  ErrorText,
} from './styles';

import { LoadingBox } from '../../../styles/common';
import { Spinner } from 'reactstrap';
import { preto } from '../../../styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSuccess,
  districtClearSuccess,
  getLocalitiesRequest,
  getNearClientsRequest,
} from '../../../store/modules/clients/actions';
import { statesOptions } from '../../../utils/commonObjects';
import AdvertiserCard from '../../../components/AdvertiserCard';

export default function NearClients() {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [radius, setRadius] = useState('');
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [newStateSelected, setNewStateSelected] = useState(null);
  const [newCitySelected, setNewCitySelected] = useState(null);
  const [newCitySelectedObj, setNewCitySelectedObj] = useState(null);
  const [localitiesSelect, setLocalitiesSelect] = useState([]);
  const [totalPerLine, setTotalPerLine] = useState([]);

  const {
    localitiesSuccess,
    localitiesLoading,
    nearClientsLoading,
  } = useSelector((state) => state.clients);

  const nearClients = useSelector((state) => state.clients.nearClients);
  const localities = useSelector((state) => state.clients.localities);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSuccess());
  }, []);

  useEffect(() => {
    if (nearClients?.length > 0) {
      // Separate the cards into 3 columns of 3 cards
      setTotalPerLine(
        nearClients.nearClients.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / 3);

          if (!resultArray[chunkIndex]) {
            // eslint-disable-next-line no-param-reassign
            resultArray[chunkIndex] = [];
          }

          resultArray[chunkIndex].push(item);

          return resultArray;
        }, [])
      );
    }
  }, [nearClients]);

  useEffect(() => {
    if (useCurrentLocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },

        function (error) {
          console.log('Error Code = ' + error.code + ' - ' + error.message);
        }
      );
      setUseCurrentLocation(false);
    }
  }, [useCurrentLocation]);

  // Get localities data (state, city)
  useEffect(() => {
    if (newStateSelected !== null) {
      dispatch(getLocalitiesRequest(newStateSelected));
    }
  }, [newStateSelected]);

  // Set localities at select input
  useEffect(() => {
    if (localitiesSuccess) {
      dispatch(districtClearSuccess());
      localitiesSelect.length = 0;
      localitiesSelect.push({
        id: '',
        title: 'Selecione a cidade',
      });
      localities.map((locality, idx) =>
        localitiesSelect.push({
          id: idx,
          title: `${locality.name}`,
        })
      );
    }
  }, [localitiesSuccess]);

  return (
    <ContentPage>
      <Row height="auto" alignStart justifyStart>
        <Title>Clientes Próximos</Title>
      </Row>

      <WhiteBox justifyStart height="auto" margtop={px2vw(30)}>
        <Row height="auto" spaceBetween alignCenter>
          <Title boxTitle width={px2vw(300)}>
            Buscar
          </Title>
        </Row>
        <Row margtop={px2vw(20)} justifyStart spaceBetween>
          <Col width="auto" height="auto">
            <InputTitle>Estado</InputTitle>
            <SelectInput
              padright={px2vw(10)}
              padleft={px2vw(10)}
              height={px2vw(30)}
              width={px2vw(200)}
              border={`2px solid ${preto}`}
              name="stateSelect"
              placeholder="Selecione o Estado"
              options={statesOptions}
              value={newStateSelected}
              onChange={(t) => {
                // Set the current state selected
                setNewStateSelected(t.target.value);
                // Clear the localities select
                localitiesSelect.length = 0;
                localitiesSelect.push({
                  id: '',
                  title: 'Selecione a cidade',
                });
                setNewCitySelected(null);
                // Clear the current locality selected
                setNewCitySelectedObj(null);
                // Get all localities from the current state
                dispatch(getLocalitiesRequest(t.target.value));
              }}
              disabled={localitiesLoading}
            />
          </Col>

          <Col width="auto" height="auto">
            <InputTitle>Cidade</InputTitle>
            <SelectInput
              padright={px2vw(10)}
              padleft={px2vw(10)}
              height={px2vw(30)}
              width={px2vw(200)}
              border={`2px solid ${preto}`}
              name="citySelect"
              placeholder={
                localitiesSelect.length === 1 &&
                localitiesSelect[0].title === 'Selecione a cidade'
                  ? 'Sem cidades disponíveis'
                  : 'Selecione a cidade'
              }
              options={localitiesSelect}
              onChange={(t) => {
                // Set the current city selected
                setNewCitySelected(t.target.value);
                // Set the current locality selected
                setNewCitySelectedObj(
                  t.target.value !== '' ? localities[t.target.value] : null
                );
              }}
              disabled={
                localitiesLoading ||
                (localitiesSelect.length === 1 &&
                  localitiesSelect[0].title === 'Selecione a cidade')
              }
            />
          </Col>

          <Col width="auto" height="auto">
            <InputTitle>Latitude</InputTitle>
            <SearchInput
              name="Latitude"
              type="text"
              placeholder="Latitude"
              border={`2px solid ${preto}`}
              borderRadius={px2vw(5)}
              height={px2vw(30)}
              width={px2vw(160)}
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              disabled
            />
          </Col>

          <Col width="auto" height="auto">
            <InputTitle>Longitude</InputTitle>
            <SearchInput
              name="Longitude"
              type="text"
              placeholder="Longitude"
              border={`2px solid ${preto}`}
              borderRadius={px2vw(5)}
              height={px2vw(30)}
              width={px2vw(160)}
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              disabled
            />
          </Col>

          <Col width="auto" height="auto">
            <InputTitle>Raio</InputTitle>
            <SearchInput
              name="Radius"
              type="number"
              placeholder="Km"
              border={`2px solid ${preto}`}
              borderRadius={px2vw(5)}
              height={px2vw(30)}
              width={px2vw(50)}
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
            />
          </Col>

          <Col width="auto" height="auto" margtop={px2vw(24)}>
            <SearchButton
              nearClients
              onClick={() => {
                if (
                  newStateSelected !== null &&
                  newCitySelected !== null &&
                  latitude !== '' &&
                  longitude !== '' &&
                  radius !== ''
                ) {
                  dispatch(
                    getNearClientsRequest(
                      newStateSelected,
                      newCitySelectedObj.name,
                      latitude,
                      longitude,
                      Number(radius)
                    )
                  );
                } else {
                  setUseCurrentLocation(true);
                }
              }}
            >
              {newStateSelected !== null &&
              newCitySelected !== null &&
              latitude !== '' &&
              longitude !== '' &&
              radius !== ''
                ? 'Buscar Clientes'
                : 'Buscar Lat. e Long.'}
            </SearchButton>
          </Col>
        </Row>

        {nearClientsLoading ? (
          <Col alignCenter margtop={px2vw(50)} margbottom={px2vw(50)}>
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.2rem',
                  height: '1.2rem',
                  color: preto,
                }}
              />
            </LoadingBox>
          </Col>
        ) : nearClients.length > 0 ? (
          totalPerLine.map((clients, idx) => {
            return (
              <Row key={idx} justifyCenter spaceBetween margtop={px2vw(30)}>
                {clients.map((client, clientIdx) => {
                  return (
                    <Col key={clientIdx}>
                      <AdvertiserCard advertiserData={client} />
                    </Col>
                  );
                })}
              </Row>
            );
          })
        ) : (
          <Col height="20vh" margtop={px2vw(40)}>
            <Row alignCenter justifyCenter>
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>Nenhum cliente próximo para ser exibido.</ErrorText>
            </Row>
          </Col>
        )}
      </WhiteBox>
    </ContentPage>
  );
}
