import styled from 'styled-components';

import { branco, cinzaKuppi2 } from '../../../../styles/colors';
import { Button } from '../../../../styles/common';
import px2vw from '../../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  z-index: 2;
  position: relative;
  bottom: 15px;
  transition: all 0.2s ease-in-out;
  left: 0px;
  width: 80px;
`;

export const Select = styled.div`
  background: ${branco};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 2px;
  margin-left: 10px;

  .options {
    transition: all 0.2s ease-in-out;

    ${(props) =>
      !props.selectedAll
        ? `
          opacity: 0;
          height: 0;
          padding: 0;
      `
        : `
          height: 100%;
          opacity: 1;
      `}
    :hover {
      background: ${cinzaKuppi2} !important;
    }
  }
`;

export const Text = styled.div``;

export const Content = styled(Button)`
  padding: 6px 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: ${px2vw(12)};
`;

export const IconBox = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: auto;
  bottom: 3px;

  i {
    transition: all 0.2s ease-in-out;
    ${(props) => props.selectedAll && `transform: rotate(180deg);`}
  }
`;
