import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { LoadingBox } from '../../../../../../styles/common';
import { preto } from '../../../../../../styles/colors';
import { Col, Row } from '../../../../../../styles/grid';
import { CloseButton, ModalHeader, ModalTitleText } from '../../../../../../components/Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  FormBox,
  InputData,
  InputTitle,
  SelectInput,
} from '../../../../../../components/EditResponsibleDataModal/styles';

import closeButtonImg from '../../../../../../assets/images/close_button/close_button.png';

import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { amountMask } from '../../../../../../utils/masks';

import Modal from '../../../../../../components/Modal';
import { onlyNumbersAmount } from '../../../../../../utils/utils';
import { addMassMessageClientBalanceRequest } from '../../../../../../store/modules/clients/actions';

export default function AddMassMessageBalanceModal({ onClose }) {
  const { addMassMessageBalanceLoading, clientData } = useSelector((state) => state.clients);
  const [balanceType, setBalanceType] = useState('');
  const [amountAdded, setAmountAdded] = useState('');
  const balanceTypeList = [
    { id: 'mgm_bonus', title: 'Bônus por Indicação' },
    { id: 'recovery_bonus', title: 'Bônus por Recuperação' },
    { id: 'credit_bonus', title: 'Bônus por Crédito' },
  ];
  const dispatch = useDispatch();

  function handleAddMassMessageBalanceSubmit() {
    if (amountAdded.length < 3) {
      toast.error('Por gentileza inserir um valor válido! Exemplo: (5.00)');
    } else {
      const amount = onlyNumbersAmount(amountAdded);

      if (balanceType === '') {
        toast.error(
          'Por gentileza selecionar o tipo de saldo a ser adicionado!'
        );
      } else if (parseFloat(amount) < parseFloat(0.01)) {
        toast.error('Por gentileza inserir um valor maior que R$ 0,00!');
      } else {
        dispatch(addMassMessageClientBalanceRequest(clientData.id, amount, balanceType));
      }
    }
  }

  return (
    <Modal
      isVisible={true}
      width={px2vw(550)}
      height="auto"
      padding={px2vw(15)}
    >
      <ModalHeader>
        <ModalTitleText>Adicionar Saldo no Disparo em Massa</ModalTitleText>
        <CloseButton onClick={onClose} disabled={false}>
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </ModalHeader>

      <FormBox onSubmit={handleAddMassMessageBalanceSubmit}>
        <Row margtop={px2vw(20)}>
          <Col margbottom={px2vw(15)}>
            <InputTitle>ID do Anunciante</InputTitle>
            <InputData
              name="advertiserId"
              type="text"
              disabled={true}
              width={px2vw(130)}
              value={clientData.id}
            />
          </Col>
          <Col>
            <InputTitle>Nome</InputTitle>
            <InputData
              name="name"
              type="text"
              disabled={true}
              width={px2vw(290)}
              value={clientData.store_data.name}
            />
          </Col>
        </Row>
        <Row margbottom={px2vw(15)}>
          <Col>
            <InputTitle>Tipo de Saldo</InputTitle>
            <SelectInput
              padright={px2vw(10)}
              padleft={px2vw(10)}
              height={px2vw(30)}
              width={px2vw(190)}
              name="bonusSelect"
              placeholder="Selecione o Bônus"
              options={balanceTypeList}
              value={balanceType}
              onChange={(e) => {
                setBalanceType(e.target.value);
              }}
            />
          </Col>
          <Col
            margleft={px2vw(-60)}
          >
            <InputTitle>Valor a ser adicionado</InputTitle>
            <InputData
              width={px2vw(150)}
              name="amount"
              type="text"
              maxLength="9"
              placeholder="R$ 200,00"
              value={amountAdded}
              onChange={(e) => {
                setAmountAdded(amountMask(e.target.value));
              }}
            />
          </Col>
        </Row>

        <Row margtop={px2vw(20)}>
          <Col alignEnd>
            <CancelButton
              onClick={onClose}
              disabled={addMassMessageBalanceLoading}
            >
              Cancelar
            </CancelButton>
          </Col>
          <Col margleft={px2vw(20)}>
            <ConfirmButton type="submit">
              {addMassMessageBalanceLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
}
