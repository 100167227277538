import styled from 'styled-components';
import { cinzaKuppi1 } from '../../../styles/colors';
import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: ${cinzaKuppi1};
  padding: ${px2vw(50)};
  border-bottom-left-radius: 60px;
  border-top-left-radius: 60px;
`;

export const Content = styled.div``;
