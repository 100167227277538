import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { Row } from '../../styles/grid';
import { ContentDefault, SwitchTableButton } from '../../styles/common';
import { Title, Field } from './styles';
import { ContentPage } from '../_layouts/styles/common';

import {
  getKuppiBoostOnboardingPendingRequest,
  getKuppiBoostWhatsappPendingRequest,
} from '../../store/modules/kuppiBoostValidation/actions';

import KuppiBoostTable from '../../components/Tables/KuppiBoostValidation';

export default function KuppiBoostValidation() {
  const dispatch = useDispatch();
  const [currentTableActive, setCurrentTableActive] = useState(
    'kuppiBoostOnboardingPending'
  );

  const dispatchableActions = {
    kuppiBoostOnboardingPending: () => dispatch(getKuppiBoostOnboardingPendingRequest()),
    kuppiBoostWhatsappPending: () => dispatch(getKuppiBoostWhatsappPendingRequest()),
  };

  const kuppiBoostValidation = useSelector((state) => state.kuppiBoostValidation);
  const { loading } = kuppiBoostValidation;

  const toggleContent = (newContent) => {
    setCurrentTableActive(newContent);

    if (dispatchableActions[newContent]) {
      dispatchableActions[newContent]();
    }
  };

  useEffect(() => {
    dispatchableActions['kuppiBoostOnboardingPending']();
  }, [])

  return (
    <ContentPage>   
      <Row height="auto" alignStart justifyStart>
        <Title>Kuppi Boost Validação</Title>
      </Row>
      <Row height="auto" padding="20px" />
      <ContentDefault alignStart justifyStart groupTop>
        <Field>
          <SwitchTableButton
            onClick={() => toggleContent('kuppiBoostOnboardingPending')}
            selected={currentTableActive === 'kuppiBoostOnboardingPending'}
          >
            Pendente Aprovação/Validação Onboarding
            <div className="selected" />
          </SwitchTableButton>
        </Field>
        <Field>
          <SwitchTableButton
            onClick={() => toggleContent('kuppiBoostWhatsappPending')}
            selected={currentTableActive === 'kuppiBoostWhatsappPending'}
          >
            Pendente Validação Whatsapp
            <div className="selected" />
          </SwitchTableButton>
        </Field>
      </ContentDefault>
      <ContentDefault alignCenter justifyCenter groupBottom>
        {!loading && (
          <KuppiBoostTable data={kuppiBoostValidation[currentTableActive]} columns={currentTableActive} />
        )}
        {loading && (
          <Row alignCenter JustifyCenter padding="40px">
            <Spinner
              style={{ width: '3rem', height: '3rem', color: '#2BC48A' }}
            />
          </Row>
        )}
      </ContentDefault>
    </ContentPage>
  );
}
