import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  recoverPasswordLoading: false,
  recoverPasswordSuccess: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOGIN_REQUEST':
        draft.loading = true;
        break;

      case '@auth/LOGIN_SUCCESS':
        draft.token = action.payload.token.token;
        draft.signed = true;
        draft.loading = false;
        break;

      case '@auth/LOGIN_FAILURE':
        draft.loading = false;
        break;

      case '@auth/LOG_OUT':
        draft.token = null;
        draft.signed = false;
        break;

      case '@auth/RECOVER_PASSWORD_REQUEST': {
        draft.recoverPasswordLoading = true;
        draft.recoverPasswordSuccess = false;
        break;
      }
      case '@auth/RECOVER_PASSWORD_SUCCESS': {
        draft.recoverPasswordLoading = false;
        draft.recoverPasswordSuccess = true;
        break;
      }
      case '@auth/CLEAR_FIELDS': {
        draft.recoverPasswordLoading = false;
        draft.recoverPasswordSuccess = false;
        break;
      }
      default:
    }
  });
}
