import React from 'react';
import { Spinner } from 'reactstrap';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../utils/utils';
import { Col, Row } from '../../../styles/grid';
import { preto } from '../../../styles/colors';

import { 
  Content, 
  LogoKuppi,
  MonthNavigationButton,
  InfoDataBox,
  InfoDataGroupBox,
  InfoNumberText,
  InfoTypeText,
  InfoNumberTotalText,
  InfoTypeTotalText
} from '../styles';

import {
  businessMetrics,
  businessMRRMetric,
  businessMassMessagesMetric,
  businessKuppiBoostMetric,
  businessRevenueMetric,
  businessMetricsLbl,
  beOvercome,
  beOvercomeLbl,
  dueDateBlockedNotCanceled,
  dueDateCanceled,
  dueDateNotBlocked,
  dueToday,
  dueTodayLbl,
  newClientsPendingPayment,
  newClientsPendingPaymentLbl,
  newSalesAnnualPlans,
  newSalesKuppido10,
  newSalesKuppido20,
  newSalesKuppido30,
  newSalesKuppido40,
  newSalesMonthlyPlans,
  newSalesQuarterPlans,
  newSalesSemestralPlans,
  newSalesStarterPlans,
  newSalesTrimestralPlans,
  payingAnnualPlans,
  payingMonthlyPlans,
  payingQuarterPlans,
  payingSemestralPlans,
  payingStarterPlans,
  payingTrimestralPlans,
  pendingRenewPayingClients,
  pendingRenewPayingClientsLbl,
  totalDueDateNotPaid,
  totalDueDateNotPaidLbl,
  totalNewSales,
  totalNewSalesLbl,
  totalPayingActivePlans,
  totalPayingActivePlansLbl,
  totalPayingClients,
  totalPayingClientsLbl,
  totalPossiblePayingClients,
  totalPossiblePayingClientsLbl,
  cacheUpdate,
  businessTotalOrdersMetric,
  businessGMVMetric
} from '../constants';
import { format } from 'date-fns';

export default function MonthlyPaymentsMetricsMobile({ handler }) {
  const metricsContent = (
    <Content>
      <Col height="auto" margtop={px2vw(30)} margbottom={px2vw(1000)}>
        {/* Side Bar */}
        <Row justifyCenter alignCenter>
          <Row justifyCenter height={px2vw(190)} width={px2vw(500)}>
            <LogoKuppi
              src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
            />
          </Row>
          <Col width="auto" margleft={px2vw(20)}>
            <MonthNavigationButton isMobile isActive={handler.activeMonth === 'previous3'} onClick={() => handler.changeMonthMetric('previous3')}>
              {`${handler.previousMonth3.substring(0, 3).capitalize()}/${handler.previousYear3}`}
            </MonthNavigationButton>
            <MonthNavigationButton isMobile isActive={handler.activeMonth === 'previous2'} onClick={() => handler.changeMonthMetric('previous2')}>
              {`${handler.previousMonth2.substring(0, 3).capitalize()}/${handler.previousYear2}`}
            </MonthNavigationButton>
          </Col>
          <Col width="auto">
            <MonthNavigationButton isMobile isActive={handler.activeMonth === 'previous1'} onClick={() => handler.changeMonthMetric('previous1')}>
              {`${handler.previousMonth.substring(0, 3).capitalize()}/${handler.previousYear}`}
            </MonthNavigationButton>
            <MonthNavigationButton isMobile isActive={handler.activeMonth === 'current'} onClick={() => handler.changeMonthMetric('current')}>
              {`${handler.currentMonth.substring(0, 3).capitalize()}/${handler.currentYear}`}
            </MonthNavigationButton>
          </Col>
        </Row>
        <Col margtop={px2vw(80)}>
          <Row justifyStart margleft={px2vw(80)}>
            <InfoTypeText isMobile>{cacheUpdate}</InfoTypeText>
            {handler.getMonthlyPaymentMetricsLoading ? (
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
              />
            ) : (
              <InfoNumberText isMobile>
                {(handler.monthlyPaymentMetrics.last_cache_date_time && format(new Date(handler.monthlyPaymentMetrics.last_cache_date_time), 'dd/MM/yyyy HH:mm')) || '-'}
              </InfoNumberText>
            )}
          </Row>

          <InfoDataGroupBox
            isMobile 
            title={businessMetricsLbl}
          >
            <Row spaceBetween>
              <InfoTypeTotalText isMobile>{businessMetrics}</InfoTypeTotalText>
            </Row>
            <Row spaceBetween>
              <InfoTypeText isMobile>{businessMRRMetric}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.business_metrics && formatCurrencyBRL(handler.monthlyPaymentMetrics.business_metrics.mrr, {})) || '-'}
                </InfoNumberText>
              )}
            </Row>
            <Row spaceBetween>
              <InfoTypeText isMobile>{businessMassMessagesMetric}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.business_metrics && formatCurrencyBRL(handler.monthlyPaymentMetrics.business_metrics.mass_message, {})) || '-'}
                </InfoNumberText>
              )}
            </Row>
            <Row spaceBetween>
              <InfoTypeText isMobile>{businessKuppiBoostMetric}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.business_metrics && formatCurrencyBRL(handler.monthlyPaymentMetrics.business_metrics.kuppi_boost, {})) || '-'}
                </InfoNumberText>
              )}
            </Row>
            <Row spaceBetween>
              <InfoTypeText isMobile>{businessRevenueMetric}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.business_metrics && formatCurrencyBRL(handler.monthlyPaymentMetrics.business_metrics.revenue, {})) || '-'}
                </InfoNumberText>
              )}
            </Row>
            <Row spaceBetween>
              <InfoTypeText isMobile>{businessGMVMetric}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.business_metrics && formatCurrencyBRL(handler.monthlyPaymentMetrics.business_metrics.gmv, {})) || '-'}
                </InfoNumberText>
              )}
            </Row>
            <Row spaceBetween>
              <InfoTypeText isMobile>{businessTotalOrdersMetric}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.business_metrics && handler.monthlyPaymentMetrics.business_metrics.total_orders) || '-'}
                </InfoNumberText>
              )}
            </Row>
          </InfoDataGroupBox>
        </Col>
        <Col margtop={px2vw(20)}>
          {/* Total of Paying Advertisers Metrics */}
          <InfoDataGroupBox
            isMobile 
            title={totalPayingClientsLbl}
          >           
            <Row spaceBetween>
              <InfoTypeTotalText isMobile>{totalPayingClients}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberTotalText isMobile>
                  {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_payments) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
          </InfoDataGroupBox>
          {/* Possible Number of Paying Advertisers Metrics */}
          <InfoDataGroupBox 
            isMobile  
            margtop={px2vw(20)}
            title={totalPossiblePayingClientsLbl}
          >
            <Row spaceBetween>
              <InfoTypeTotalText isMobile>{totalPossiblePayingClients}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberTotalText isMobile>
                  {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_possible_paying_advertisers) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
          </InfoDataGroupBox>
          {/* Pending Renew */}
          {/* Recurrings renews until end of month */}
          <InfoDataGroupBox 
            margtop={px2vw(20)} 
            isMobile 
            title={pendingRenewPayingClientsLbl}
          >
            <Row spaceBetween>
              <InfoTypeTotalText isMobile>{pendingRenewPayingClients}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberTotalText isMobile>
                  {(handler.monthlyPaymentMetrics.renew_metrics && handler.monthlyPaymentMetrics.renew_metrics.recurrings_renew_count) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
          </InfoDataGroupBox>
          {/* New Advertisers With Pending Payment */}
          <InfoDataGroupBox 
            margtop={px2vw(20)} 
            isMobile 
            title={newClientsPendingPaymentLbl}
          >
            <Row spaceBetween>
              <InfoTypeTotalText isMobile>{newClientsPendingPayment}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberTotalText isMobile>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.new_advertisers_pending_payment_count) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
          </InfoDataGroupBox>
        </Col>
        <Col margtop={px2vw(20)}>
          {/* Due date Metrics */}
          <InfoDataGroupBox 
            isMobile 
            title={totalDueDateNotPaidLbl}
          >
            {/* Total Due date */}
            <Row height="auto" spaceBetween>
              <InfoTypeTotalText isMobile>{totalDueDateNotPaid}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberTotalText isMobile>
                  {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_due_dates) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
            {/* Due date and not blocked */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{dueDateNotBlocked}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_date_not_blocked_not_canceled_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Blocked and not canceled */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{dueDateBlockedNotCanceled}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_date_blocked_not_canceled_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Canceled */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{dueDateCanceled}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_date_canceled_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
          </InfoDataGroupBox>
          <Row margtop={px2vw(20)} margleft={px2vw(10)}> 
            {/* Due date today */}
            <InfoDataBox
              isMobile
              width={px2vw(631)}
              height={px2vw(150)}
              title={dueTodayLbl}
            >
              <Row height="auto" spaceBetween>
                <InfoTypeTotalText isMobile>{dueToday}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText isMobile>
                    {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_today_count) || '-'}
                  </InfoNumberTotalText>
                )}
              </Row>
            </InfoDataBox>
            {/* Will Due date */}
            <InfoDataBox 
              isMobile
              width={px2vw(631)} 
              height={px2vw(150)}
              title={beOvercomeLbl}
            >
              <Row height="auto" spaceBetween>
                <InfoTypeTotalText isMobile>{beOvercome}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText isMobile>
                    {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_pending_payment_count) || '-'}
                  </InfoNumberTotalText>
                )}
              </Row>
            </InfoDataBox>
          </Row>
        </Col>
        {/* Renew Payment && New Sales Metrics */}
        <Row margtop={px2vw(20)}>
          {/* Renew Payment Metrics */}
          <InfoDataGroupBox
            title={totalPayingActivePlansLbl}
            isMobile 
          >
            {/* Total Payments Renew*/}
            <Row height="auto" spaceBetween>
              <InfoTypeTotalText isMobile>{totalPayingActivePlans}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    color: preto,
                    marginTop: `${px2vw(10)}` 
                  }}
                />
              ) : (
                <InfoNumberTotalText isMobile>
                  {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_renew_payments) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
            {/* Starter Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{payingStarterPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_starter_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Monthly Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{payingMonthlyPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_month_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Trimestral Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{payingTrimestralPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_trimestral_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Quarter Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{payingQuarterPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_quarter_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Semestral Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{payingSemestralPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_semestral_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Annual Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="98.6%">
              <InfoTypeText isMobile>{payingAnnualPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText isMobile>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_annual_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
          </InfoDataGroupBox> 
        </Row>
        <Row margtop={px2vw(20)}>
          {/* New Sales Metrics */}
          <InfoDataGroupBox 
            title={totalNewSalesLbl}
            isMobile 
          >
            {/* Total New Sales Payments */}
            <Row height="auto" spaceBetween>
              <InfoTypeTotalText isMobile>{totalNewSales}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    color: preto,
                    marginTop: `${px2vw(10)}` 
                  }}
                />
              ) : (
                <InfoNumberTotalText isMobile>
                  {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_new_sales) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
            {/* New Sales Per Plan */}
            <Col spaceBetween>
              {/* Starter Payments */}
              <Row margleft={px2vw(5)} spaceBetween>
                <InfoTypeText isMobile>{newSalesStarterPlans}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_starter_new_sales_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* Monthly Payments */}
              <Row margleft={px2vw(5)} spaceBetween>
                <InfoTypeText isMobile>{newSalesMonthlyPlans}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_month_new_sales_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* Trimestral Payments */}
              <Row margleft={px2vw(5)} spaceBetween>
                <InfoTypeText isMobile>{newSalesTrimestralPlans}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_trimestral_new_sales_count) || '-'}
                  </InfoNumberText>
                )}      
              </Row>
              {/* Quarter Payments */}
              <Row margleft={px2vw(5)} spaceBetween>
                <InfoTypeText isMobile>{newSalesQuarterPlans}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_quarter_new_sales_count) || '-'}
                  </InfoNumberText>
                )}      
              </Row>
              {/* Semestral Payments */}
              <Row margleft={px2vw(5)} spaceBetween>
                <InfoTypeText isMobile>{newSalesSemestralPlans}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_semestral_new_sales_count) || '-'}
                  </InfoNumberText>
                )}      
              </Row>
              {/* Annual Payments */}
              <Row margleft={px2vw(5)} spaceBetween>
                <InfoTypeText isMobile>{newSalesAnnualPlans}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_annual_new_sales_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
            </Col>        
            {/* New Sales Per Kuppido Type */}
            <Col spaceBetween>
              {/* New Sales Kuppidos 1.0 - External */}
              <Row spaceBetween>
                <InfoTypeText isMobile>{newSalesKuppido10}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_10_new_sales_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* New Sales Kuppidos 2.0 */}
              <Row spaceBetween>
                <InfoTypeText isMobile>{newSalesKuppido20}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_20_new_sales_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* New Sales Kuppidos 3.0 */}
              <Row spaceBetween>
                <InfoTypeText isMobile>{newSalesKuppido30}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_30_new_sales_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* New Sales Kuppidos 4.0 */}
              <Row spaceBetween>
                <InfoTypeText isMobile>{newSalesKuppido40}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                      marginTop: `${px2vw(14)}` 
                    }}
                  />
                ) : (
                  <InfoNumberText isMobile>
                    {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_40_new_sales_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
            </Col>  
          </InfoDataGroupBox>
        </Row>
      </Col>
    </Content>
  );

  return metricsContent;
}
