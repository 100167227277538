/* *** Kuppido *** */

export function getKuppidoReports(filter) {
  return {
    type: '@reports/GET_KUPPIDO_REPORTS',
    payload: { filter },
  };
}

export function setKuppidoReports(data) {
  return {
    type: '@reports/SET_KUPPIDO_REPORTS',
    payload: { data },
  };
}

export function formatInitialProspecting(data) {
  return {
    type: '@reports/FORMAT_INITIAL_PROPSPECTING',
    payload: { data },
  };
}

export function formatPreImplementation(data) {
  return {
    type: '@reports/FORMAT_PRE_IMPLEMENTATION',
    payload: { data },
  };
}

export function formatConversion(data) {
  return {
    type: '@reports/FORMAT_CONVERSION',
    payload: { data },
  };
}

export function formatEngagement(data) {
  return {
    type: '@reports/FORMAT_ENGAGEMENT',
    payload: { data },
  };
}

export function setFormatInitialProspecting(data) {
  return {
    type: '@reports/SET_FORMAT_INITIAL_PROPSPECTING',
    payload: { data },
  };
}

export function setFormatConversion(data) {
  return {
    type: '@reports/SET_FORMAT_CONVERSION',
    payload: { data },
  };
}

export function setFormatEngagement(data) {
  return {
    type: '@reports/SET_FORMAT_ENGAGEMENT',
    payload: { data },
  };
}

/* *** Onboarding And Activation *** */

export function getOnboardingAndActivation() {
  return {
    type: '@reports/GET_ONBOARDING_AND_ACTIVATION',
  };
}

export function setOnboardingAndActivation(data) {
  return {
    type: '@reports/SET_ONBOARDING_AND_ACTIVATION',
    payload: { data },
  };
}

/* *** Pre Implementation *** */

export function getPreImplementation() {
  return {
    type: '@reports/GET_PRE_IMPLEMENTATION',
  };
}

export function setPreImplementation(data) {
  return {
    type: '@reports/SET_PRE_IMPLEMENTATION',
    payload: { data },
  };
}

export function formatOnboardingAndActivation(data) {
  return {
    type: '@reports/FORMAT_ONBOARDING_AND_ACTIVATION',
    payload: { data },
  };
}

/* *** Menu And Coupons *** */

export function getMenuAndCoupons() {
  return {
    type: '@reports/GET_MENU_AND_COUPONS',
  };
}

export function setMenuAndCoupons(data) {
  return {
    type: '@reports/SET_MENU_AND_COUPONS',
    payload: { data },
  };
}

export function formatMenuAndCoupons(data) {
  return {
    type: '@reports/FORMAT_MENU_AND_COUPONS',
    payload: { data },
  };
}

/* *** Orders *** */

export function getOrders() {
  return {
    type: '@reports/GET_ORDERS',
  };
}

export function setOrders(data) {
  return {
    type: '@reports/SET_ORDERS',
    payload: { data },
  };
}

/* *** Kuppi Boost Onboarding Pending *** */

export function getKuppiBoostOnboardingPending() {
  return {
    type: '@reports/GET_KUPPI_BOOST_ONBOARDING_PENDING',
  };
}

export function formatKuppiBoostOnboardingPending(data) {
  return {
    type: '@reports/FORMAT_KUPPI_BOOST_ONBOARDING_PENDING',
    payload: { data },
  };
}

export function setKuppiBoostOnboardingPending(data) {
  return {
    type: '@reports/SET_KUPPI_BOOST_ONBOARDING_PENDING',
    payload: { data },
  };
}

export function checkKuppiBoostPermissionsRequest(advertiserInfoId) {
  return {
    type: '@reports/CHECK_KUPPI_BOOST_PERMISSIONS_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function checkKuppiBoostPermissionsResponse() {
  return {
    type: '@reports/CHECK_KUPPI_BOOST_PERMISSIONS_RESPONSE',
  };
}

/* *** Subscriptions *** */

export function formatSubscriptions(data) {
  return {
    type: '@reports/FORMAT_SUBSCRIPTIONS',
    payload: { data },
  };
}

export function getSubscriptions() {
  return {
    type: '@reports/GET_SUBSCRIPTIONS',
  };
}

export function setSubscriptions(data) {
  return {
    type: '@reports/SET_SUBSCRIPTIONS',
    payload: { data },
  };
}

/* *** Kuppido Subscriptions *** */

export function formatKuppidoSubscriptions(data) {
  return {
    type: '@reports/FORMAT_KUPPIDO_SUBSCRIPTIONS',
    payload: { data },
  };
}

export function getKuppidoSubscriptions() {
  return {
    type: '@reports/GET_KUPPIDO_SUBSCRIPTIONS',
  };
}

export function setKuppidoSubscriptions(data) {
  return {
    type: '@reports/SET_KUPPIDO_SUBSCRIPTIONS',
    payload: { data },
  };
}

export function formatOrders(data) {
  return {
    type: '@reports/FORMAT_ORDERS',
    payload: { data },
  };
}

/* *** Others *** */

export function requestFailure() {
  return {
    type: '@reports/REQUEST_FAILURE',
  };
}

export function endAction() {
  return {
    type: '@reports/END_ACTION',
  };
}
