import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import Modal from '../../../Modal';
import { CloseButton, ModalTitleText } from '../../../Modal/styles';
import closeButtonImg from '../../../../assets/images/close_button/close_button.png';
import { Col, Row } from '../../../../styles/grid';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle, 
} from '../../../EditResponsibleDataModal/styles';
import { preto } from '../../../../styles/colors';
import { LoadingBox } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';

import { campaignActionRequest } from '../../../../store/modules/clients/actions';

export default function ConfirmCampaignActionModal({ onClose, action, campaignData }) {
  const { campaignActionLoading, clientData } = useSelector(
    (state) => state.clients
  );
  const [textConfirmation, setTextConfirmation] = useState('');
  const dispatch = useDispatch();
  
  function getModalTitleText() {
    switch (action) {
      case 'cancel':
        return 'Cancelar campanha'
      case 'force-start':
        return 'Forçar inicio da campanha'
    }
  }

  function getModalQuestionText() {
    switch (action) {
      case 'cancel':
        return `Tem certeza que deseja cancelar campanha #${campaignData.id}?`
      case 'force-start':
        return `Tem certeza que deseja forçar inicio da campanha #${campaignData.id}?`
    }
  }

  function getActionText() {
    switch (action) {
      case 'cancel':
        return 'cancelar'
      case 'force-start':
        return 'forçar'
    }
  }

  return (
    <Modal
      isVisible
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={onClose}
          disabled={campaignActionLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>{getModalTitleText()}</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          {getModalQuestionText()}
        </InputTitle>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite '{getActionText()}' para confirmar
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="action"
          type="text"
          placeholder={getActionText()}
          width={px2vw(400)}
          value={textConfirmation}
          onChange={(e) => setTextConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (textConfirmation === getActionText()) {
                dispatch(campaignActionRequest(clientData?.id, campaignData?.id, action))               
              }
            }} 
            disabled={textConfirmation !== getActionText() || campaignActionLoading}
          >
            {campaignActionLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : ('Confirmar')}
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={onClose}
            disabled={campaignActionLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
