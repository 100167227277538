import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import {
  IconButton,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { Col, Row } from '../../../../../styles/grid';
import { ActionButton, Text, WhiteBox } from '../../styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';
import RemovePixelModal from './RemovePixelModal';
import AddPixelModal from './AddPixelModal';
import AddDomainModal from './AddDomainModal';
import RemoveDomainModal from './RemoveDomainModal';

import { clearUpdatePixelsListSuccess, clearAddDomainSuccess, linkWebMenuDomainRequest, clearRemoveDomainSuccess } from '../../../../../store/modules/clients/actions';

import { ConfirmButton } from '../../../../../components/EditResponsibleDataModal/styles';

export default function TabPixelOwnDomain() {
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );
  const {
    pixelOwnDomainDataLoading,
    updatePixelsListSuccess,
    addDomainSuccess,
    linkWebMenuDomainLoading,
    removeDomainSuccess,
    clientData,
  } = useSelector((state) => state.clients);
  const pixelOwnDomainData = useSelector((state) => state.clients.pixelOwnDomainData);
  const kuppiPixelID = '1609621289422319';
  const [removePixel, setRemovePixel] = useState(null);
  const [removePixelModal, setRemovePixelModal] = useState(false);
  const [addPixelModal, setAddPixelModal] = useState(false);
  const [removeDomainModal, setRemoveDomainModal] = useState(false);
  const [addDomainModal, setAddDomainModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updatePixelsListSuccess) {
      setRemovePixelModal(false);
      setAddPixelModal(false);
      dispatch(clearUpdatePixelsListSuccess());
    }
  }, [updatePixelsListSuccess])

  useEffect(() => {
    if (addDomainSuccess || removeDomainSuccess) {
      setRemoveDomainModal(false);
      setAddDomainModal(false);
      dispatch(clearAddDomainSuccess());
      dispatch(clearRemoveDomainSuccess());
    }
  }, [addDomainSuccess, removeDomainSuccess])

  return (
    <>
      <Col>
        <Row justifyStart alignCenter>
          <Text fontweight="bold" fontsize={px2vw(13)}>
            {`Pixel ID: ${kuppiPixelID} é da Kuppi e aparece em todos os cardápios`}
          </Text>
        </Row>

        <Row justifyStart alignStart margtop={px2vw(20)}>
          {/* Pixel */}
          <WhiteBox margright={px2vw(10)}>
            <Row justifyStart margbottom={px2vw(10)}>
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Pixel
              </Text>
            </Row>
            <Col>
              {!pixelOwnDomainDataLoading && pixelOwnDomainData?.facebook_pixels && pixelOwnDomainData?.facebook_pixels?.map((pixelId, idx) => {
                return (
                  <Row justifyStart>
                    <Text key={`pixelId-${idx}`} fontsize={px2vw(13)} margright={px2vw(10)} margtop={px2vw(9)}>
                      {`• ${pixelId}`}
                    </Text>
                    {operationalEditAccess && (
                      <IconButton size="medium" onClick={() => {
                        setRemovePixel({
                          idx: idx,
                          pixel: pixelId
                        });
                        setRemovePixelModal(true);
                      }}>
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                  </Row>
                )
              })}
              {!pixelOwnDomainDataLoading && !pixelOwnDomainData?.facebook_pixels && (
                <Text fontsize={px2vw(13)}>
                  Cliente não tem pixel cadastrado
                </Text>
              )}
              {!pixelOwnDomainDataLoading && operationalEditAccess && (
                <Row margtop={px2vw(20)}>
                  <ActionButton onClick={() => setAddPixelModal(true)}>
                    Adicionar Pixel
                  </ActionButton>
                </Row>
              )}
              {pixelOwnDomainDataLoading && (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              )}
            </Col>
          </WhiteBox>
          {/* Own Domain */}
          <WhiteBox margleft={px2vw(10)}>
            <Row justifyStart margbottom={px2vw(10)}>
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Domínio Próprio
              </Text>
            </Row>
            <Col>
              {!pixelOwnDomainDataLoading && pixelOwnDomainData?.domains && (
                <>
                  <Row justifyStart>
                    <Col width="auto" alignStart margright={px2vw(100)}>
                      <Row key={`domain`} justifyStart>
                        <Text fontsize={px2vw(13)} margtop={px2vw(11)}>
                        •
                        </Text>
                        <Text fontsize={px2vw(13)} margtop={px2vw(9)} margleft={px2vw(5)}
                          onClick={() => window.open(`https://${pixelOwnDomainData?.domains[0]}`, '_blank')}
                          slug
                        >
                          {pixelOwnDomainData?.domains[0]}
                        </Text>
                      </Row>
                    </Col>
                    {operationalEditAccess && (
                      <IconButton size="medium" onClick={() => setRemoveDomainModal(true)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    )}  
                  </Row>
                  {pixelOwnDomainData?.certificate_status === 'PENDING_VALIDATION' && pixelOwnDomainData?.domain_dns && (
                    <Col margtop={px2vw(20)}>
                      <Row justifyStart margbottom={px2vw(10)}>
                        <Text fontweight="bold" fontsize={px2vw(13)}>
                          Para validação do domínio a lista de DNS abaixo deve ser cadastrada:
                        </Text>
                      </Row>
                      {pixelOwnDomainData?.domain_dns?.map((dns, idx) => {
                        return (
                          <Row key={`dns-${idx}`} justifyStart>
                            <Text fontsize={px2vw(13)} margtop={px2vw(11)}>
                            •
                            </Text>
                            <Text fontsize={px2vw(13)} margtop={px2vw(9)} margleft={px2vw(5)}>
                              {dns}
                            </Text>
                          </Row>
                        );
                      })}
                    </Col>
                  )}
                  {pixelOwnDomainData?.certificate_status === 'ISSUED' && (
                    <Col margtop={px2vw(20)}>
                      <Row justifyStart margbottom={px2vw(10)}>
                        <Text fontweight="bold" fontsize={px2vw(13)}>
                          DNS validado com sucesso. Clique no botão abaixo para linkar o Cárdapio Kuppi ao Domínio do Cliente:
                        </Text>
                      </Row>
                      <ConfirmButton
                        onClick={() => dispatch(linkWebMenuDomainRequest(clientData?.id))} 
                        disabled={linkWebMenuDomainLoading}
                      >
                        {linkWebMenuDomainLoading ? (
                            <LoadingBox>
                              <Spinner
                                style={{
                                  width: '1.2rem',
                                  height: '1.2rem',
                                  color: preto,
                                }}
                              />{' '}
                            </LoadingBox>
                          ) : ('Linkar cardápio ao domínio')}
                      </ConfirmButton>
                    </Col>
                  )}
                  {pixelOwnDomainData?.certificate_status === 'ACTIVE' && (
                    <Row justifyStart margtop={px2vw(20)}>
                      <Text fontweight="bold" fontsize={px2vw(13)}>
                        Domínio próprio ativo e linkado ao cardápio. Acesse pelos links acima.
                      </Text>
                    </Row>
                  )}
                  {pixelOwnDomainData?.certificate_status === 'EXPIRED' && (
                    <Row justifyStart margtop={px2vw(20)}>
                      <Text fontweight="bold" fontsize={px2vw(13)} color="red">
                        {clientData?.store_data?.status?.status === 'Cancelado' && 'Cliente cancelado, domínio pode ser removido da Kuppi.'}
                        {clientData?.store_data?.status?.status === 'Ativo' && 'Ocorreu um erro na validação do certificado, verifique se o cliente ainda possui o domínio próprio. Caso não, pode removê-lo da Kuppi.'}
                      </Text>
                    </Row>
                  )}
                  {!['PENDING_VALIDATION', 'ISSUED', 'ACTIVE', 'EXPIRED'].includes(pixelOwnDomainData?.certificate_status) && (
                    <Row justifyStart margtop={px2vw(20)}>
                      <Text fontweight="bold" fontsize={px2vw(13)} color="red">
                        Status da validação inválida ({pixelOwnDomainData?.certificate_status}). Entre em contato com o suporte.
                      </Text>
                    </Row>
                  )}
                </>
              )}
              {!pixelOwnDomainDataLoading && !pixelOwnDomainData?.domains && (
                <>
                  <Text fontsize={px2vw(13)}>
                    Cliente não tem domínio próprio cadastrado
                  </Text>
                  {operationalEditAccess && (
                    <Row margtop={px2vw(20)}>
                      <ActionButton onClick={() => setAddDomainModal(true)}>
                        Adicionar Domínio
                      </ActionButton>
                    </Row>
                  )}
                </>
              )}            
              {pixelOwnDomainDataLoading && (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              )}
            </Col>
          </WhiteBox>
        </Row>
      </Col>
      {removePixelModal && 
        <RemovePixelModal
          removePixel={removePixel}
          onClose={() => setRemovePixelModal(false)}
        />
      }
      {addPixelModal && 
        <AddPixelModal
          onClose={() => setAddPixelModal(false)}
        />
      }
      {removeDomainModal && 
        <RemoveDomainModal
          onClose={() => setRemoveDomainModal(false)}
        />
      }
      {addDomainModal && 
        <AddDomainModal 
          onClose={() => setAddDomainModal(false)}
        />
      }


    </>
  );
}
